import React from 'react';

import { useFormatMessage } from 'hooks';

const Home = () => (
  <>
    <section className="hero">
      <div className="hero-body">
        <h1 className="title">Error</h1>
      </div>
    </section>

    <section className="section is-main-section">
      You are not authorized to access this page.
    </section>
  </>
);

export default Home;
