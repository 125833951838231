import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useFormatMessage } from 'hooks';
import { InputNumber } from 'antd';
import moment from 'moment';
import { DatePicker } from 'antd';
import axios from 'axios';
const { RangePicker } = DatePicker;

const PromotionForm = (props) => {
  const {
    register,
    control,
    handleSubmit,
    errors,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: props.promo,
  });

  const errorDate = useFormatMessage('Promotions.errorDate');
  const errorTitle = useFormatMessage('Promotions.errorTitle');
  const type = useFormatMessage('Promotions.type');
  const errorTerms = useFormatMessage('Promotions.errorTerms');
  const dateRange = useFormatMessage('Promotions.dateRange');
  const description = useFormatMessage('Promotions.description');
  const title = useFormatMessage('Promotions.title');
  const submit = useFormatMessage('Promotions.submit');
  const firstOrder = useFormatMessage('Promotions.firstOrder');
  const percentage = useFormatMessage('Promotions.percentage');
  const price = useFormatMessage('Promotions.price');
  const value = useFormatMessage('Promotions.value');
  const errorValue = useFormatMessage('Promotions.errorValue');
  const termsAndConditions = useFormatMessage('Promotions.termsAndConditions');
  const descriptionPlaceholder = useFormatMessage(
    'Promotions.descriptionPlaceholder'
  );
  const titlePlaceholder = useFormatMessage('Promotions.titlePlaceholder');
  const enable = useFormatMessage('Promotions.enable');
  const [loadingTerms, setLoadingTerms] = useState(false);
  const selectedType = watch('discount_type');
  const firstOrderOnly = watch('first_order_only');

  useEffect(() => {
    const fetchTerms = async () => {
      setLoadingTerms(true);
      const response = await axios.get(props.promo.terms_and_conditions);
      if (response.data) {
        setValue('terms_and_conditions', response.data);
      }
      setLoadingTerms(false);
    };
    if (Object.keys(props.promo).length > 0) {
      fetchTerms(props.promo);
    }
    const promo = { ...props.promo };
    delete promo.terms_and_conditions;
    reset({
      ...promo,
      date_range: [
        moment(promo.date_active_from_posix),
        moment(promo.date_active_to_posix),
      ],
    });
  }, [props.promo.id]);

  const disableRange = (current) => {
    let res;
    if (current.isBefore(moment())) {
      return true;
    }
    for (const promo of props.currentPromos) {
      if (!promo.is_active) {
        res = false;
      } else if (promo.id === props.promo.id) {
        res = false;
      } else if (promo.first_order_only !== firstOrderOnly) {
        res = false;
      } else {
        res = current.isBetween(
          moment(promo.date_active_from_posix),
          moment(promo.date_active_to_posix)
        );
      }
    }
    return res;
  };

  const wordLimit = (inp, limit) => {
    var val = inp.value;
    var words = val.split(/\s+/);
    var legal = '';
    for (let i = 0; i < words.length; i++) {
      if (i < limit) {
        legal += words[i] + ' ';
      }
      if (i >= limit) {
        inp.value = legal.trim();
      }
    }
  };

  const getDateFormatString = () => {
    const formatObj = new Intl.DateTimeFormat().formatToParts(new Date());

    return formatObj
      .map((obj) => {
        switch (obj.type) {
          case 'day':
            return 'DD';
          case 'month':
            return 'MM';
          case 'year':
            return 'YYYY';
          default:
            return obj.value;
        }
      })
      .join('');
  };

  const dateFormat = getDateFormatString();

  return (
    <div>
      <div className={`modal ${props.isActive ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={props.closeModal}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{props.title}</p>
            <div className="field" style={{ marginRight: '10px' }}>
              <button
                className={`button ${props.isLoading && 'is-loading'}`}
                onClick={handleSubmit(props.onSubmit)}
              >
                {submit}
              </button>
            </div>
            <button
              className="delete"
              aria-label="close"
              onClick={props.closeModal}
            ></button>
          </header>
          <section className="modal-card-body">
            <form onSubmit={handleSubmit(props.onSubmit)}>
              <div className="field">
              <div style={{display:'flex', justifyContent:'space-between'}}>
                <label className="label">{title}</label>
                 <div className={`help ${watch('title')?.length>50 && 'is-danger'}`}>{watch('title')?.length}/50</div>
                 </div>
                <div className="control">
                
                  <input
                    className={`input ${errors.title ? 'is-danger' : ''}`}
                    ref={register({ required: true, maxLength: 50 })}
                    name="title"
                    placeholder={titlePlaceholder}
                  />
                </div>
                
                <div className="help is-danger">
                  {errors.title && errorTitle}
                </div>
              </div>
              <div className="field">
                <label className="label">{description}</label>
                <div className="control">
                  <input
                    className={`input ${errors.description ? 'is-danger' : ''}`}
                    ref={register()}
                    name="description"
                    placeholder={descriptionPlaceholder}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">{type}</label>
                <div className="control">
                  <div className="select">
                    <select
                      ref={register({ required: true })}
                      name="discount_type"
                    >
                      <option value="Percentage">{percentage}</option>
                      <option value="Price">{price}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label">{value}</label>
                <div className="control">
                  <Controller
                    rules={{
                      required: true,
                    }}
                    control={control}
                    name="discount_value"
                    render={({ value, onChange }) => (
                      <InputNumber
                        step={selectedType === 'Percentage' ? 1 : 0.5}
                        type="number"
                        precision={selectedType === 'Percentage' ? 0 : 1}
                        className={`input ${errors.value ? 'is-danger' : ''}`}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </div>
                <div className="help is-danger">
                  {errors.value && errorValue}
                </div>
              </div>
              <div className="field">
                <label className="label">{dateRange}</label>
                <div className="control">
                  <Controller
                    name="date_range"
                    control={control}
                    rules={{ required: true }}
                    render={({ onChange, value }) => {
                      return (
                        <RangePicker
                          value={value}
                          onChange={onChange}
                          format={dateFormat}
                          disabledDate={disableRange}
                        />
                      );
                    }}
                  />
                </div>
                <div className="help is-danger">
                  {errors.date_range && errorDate}
                </div>
              </div>
              <div className="field">
                <label className="label">{termsAndConditions}</label>
                <div className={`control  ${loadingTerms && 'is-loading'}`}>
                  <textarea
                    className="textarea"
                    placeholder={useFormatMessage('Loyalty.placeholderTnCs')}
                    rows="5"
                    name="terms_and_conditions"
                    ref={register({ required: true })}
                  />
                </div>
                <div className="help is-danger">
                  {errors.terms_and_conditions && errorTerms}
                </div>
              </div>

              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field is-narrow">
                    <div className="control">
                      <label className="blue-checkbox">
                        <input
                          type="checkbox"
                          name="first_order_only"
                          ref={register()}
                          defaultChecked={props.promo.first_order_only}
                        />
                        <div style={{ display: 'flex' }}>
                          <div className="checkmark" />
                          <span style={{ paddingLeft: '8px' }}>
                            {firstOrder}
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <label>
                        <input
                          id={props.promo.id || 'new'}
                          type="checkbox"
                          name="is_active"
                          ref={register()}
                          className="switch is-rounded"
                        />
                        <label htmlFor={props.promo.id || 'new'} />
                        <span>{enable}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className={`button ${
                  props.isLoading ? 'is-loading' : ''
                } is-pulled-right`}
                onClick={handleSubmit(props.onSubmit)}
              >
                {submit}
              </button>
            </form>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PromotionForm;
