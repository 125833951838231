import React, { useEffect } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import Login from 'pages/Login';
import Home from 'pages/Home';
import Users from 'pages/Users';
import Profile from 'pages/Profile';
import ResetPassword from 'pages/ResetPassword';
import NotFound from 'pages/NotFound';
import User from 'pages/User';
import paths from './paths';
import PrivateRoute from './PrivateRoute';
import Menu from 'pages/Menu';
import CompletedOrders from 'pages/OrderManagement/completedorders';
import PendingOrders from 'pages/OrderManagement/pendingorders';
import DeclinedOrders from 'pages/OrderManagement/declinedorders';
import Restaurant from 'pages/Restaurant';
import Promotions from 'pages/promotions';
import Unauthorized from 'pages/Unauthorized';
import Analytics from 'pages/Analytics';
import HeydoorPayments from 'pages/Accounting';
import { shallowEqual, useSelector } from 'react-redux';
import firebase from 'firebase.js';
import Rewards from 'pages/rewards';
import Loyalty from 'pages/Loyalty';
import RedeemList from 'pages/rewards/redeemlist';
import Redeem from 'pages/rewards/redeem';

const region = process.env.REACT_APP_REGION || 'asia-east2';

const RouterComponent = () => {
  const { user } = useSelector(
    ({ auth }) => ({ user: auth.userData }),
    shallowEqual
  );

  const setSignInTime = async () => {
    if (user.role === 'Staff') {
      const notify = firebase
        .app()
        .functions(region)
        .httpsCallable('httpsNotifyLogin');
      await notify({
        userIds: user.restaurants[0].userIds,
        user: user.name,
        restaurantId: user.restaurants[0].id,
      });
    }
  };

  useEffect(() => {
    if (user && user.id) {
      let signIn = sessionStorage.getItem('signIn');
      if (signIn === null) {
        sessionStorage.setItem('signIn', user.id);
        setSignInTime();
      }
    }
  }, [user]);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={[paths.LOGIN, paths.ACTION]} component={Login} />
        <Route exact path={paths.RESET_PASSWORD} component={ResetPassword} />
        <PrivateRoute
          path={paths.REWARDS}
          roles={['Rewards']}
          component={Rewards}
        />
        <PrivateRoute
          path={paths.REDEEM}
          roles={['Rewards']}
          component={Redeem}
        />
        <PrivateRoute
          path={paths.REDEEMLIST}
          roles={['Rewards']}
          component={RedeemList}
        />
        <PrivateRoute
          path={paths.ADD_USER}
          roles={['Owner', 'Manager']}
          component={User}
        />
        <PrivateRoute
          path={paths.MODIFY_USER}
          roles={['Owner', 'Manager']}
          component={User}
        />
        <PrivateRoute
          path={paths.USERS}
          roles={['Owner', 'Manager']}
          component={Users}
        />
        <PrivateRoute
          path={paths.MENU}
          roles={['Owner', 'Manager', 'Staff']}
          component={Menu}
          requiresRestaurant={true}
        />
        <PrivateRoute
          path={paths.RESTAURANT}
          roles={['Owner']}
          component={Restaurant}
        />
        <PrivateRoute
          path={paths.PENDING_ORDERS}
          roles={['Owner', 'Manager', 'Staff']}
          component={PendingOrders}
          requiresRestaurant={true}
        />
        <PrivateRoute
          path={paths.COMPLETED_ORDERS}
          component={CompletedOrders}
          roles={['Owner', 'Manager']}
          requiresRestaurant={true}
        />
        <PrivateRoute
          path={paths.DECLINED_ORDERS}
          roles={['Owner', 'Manager']}
          component={DeclinedOrders}
          requiresRestaurant={true}
        />
        <PrivateRoute
          path={paths.PROMOTIONS}
          roles={['Owner', 'Manager']}
          component={Promotions}
          requiresRestaurant={true}
        />
        <PrivateRoute
          path={paths.HEYDOOR_PAYMENTS}
          roles={['Owner', 'Manager']}
          component={HeydoorPayments}
          requiresRestaurant={true}
        />
        <PrivateRoute
          path={paths.LOYALTY}
          roles={['Owner', 'Manager']}
          component={Loyalty}
          requiresRestaurant={true}
        />
        <PrivateRoute
          path={paths.ANALYTICS}
          roles={['Owner', 'Manager']}
          component={Analytics}
          requiresRestaurant={true}
        />
        <PrivateRoute
          path={paths.PROFILE}
          roles={['Owner', 'Manager', 'Staff', 'Rewards']}
          component={Profile}
        />
        <PrivateRoute
          path={paths.UNAUTHORIZED}
          roles={['Owner', 'Manager', 'Staff']}
          component={Unauthorized}
        />
        <PrivateRoute
          path={paths.ROOT}
          roles={['Owner', 'Manager', 'Staff']}
          component={Home}
        />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterComponent;
