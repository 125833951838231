import firebase from 'firebase.js';
const Geohash = require('@geonet/geohash');

export const restaurantFormSubmit = async (data, restaurant, file) => {
  const days_abbr = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

  let newCats = [];
  newCats.push(data.categoriesFoodStyle[0]);

  //Add first item from countries as long as it's not 'None'
  if(data.categoriesCountry.length > 0 && data.categoriesCountry[0] !== 'None') {
    newCats.push(data.categoriesCountry[0]);
    data.categoriesCountry.shift();
  }

  //Append all others after first items from each list
  data.categoriesFoodStyle.shift();

  newCats = newCats.concat(data.categoriesFoodStyle.concat(data.categoriesCountry));

  let newData = {
    ...restaurant,
    ...data,
    categories: newCats,
  };

  if (data.is_24_hrs) {
    newData.working_hours = Array(7)
      .fill(0)
      .map((_, index) => ({
        dat: days_abbr[index],
        start_time: 0,
        end_time: 0,
      }));
  } else {
    //format working hours from string to double in format 05h15 -> 5.25
    newData.working_hours = data.working_hours.map((hours, index) => {
      if (hours.start_time === null || hours.end_time === null) {
        return {
          start_time: null,
          end_time: null,
          day: days_abbr[index],
        };
      } else {
        let start_tmp = hours.start_time.split(':');
        let end_tmp = hours.end_time.split(':');

        return {
          start_time: parseFloat(start_tmp[0]) + parseFloat(start_tmp[1]) / 60,
          end_time: parseFloat(end_tmp[0]) + parseFloat(end_tmp[1]) / 60,
          day: days_abbr[index],
          closed: (typeof hours.closed === 'undefined' || hours.closed === null) ? false: hours.closed,
        };
      }
    });
  }

  delete newData.categoriesCountry;
  delete newData.categoriesFoodStyle;

  //When data was fetched, geopoint was converted to js object.
  //we need to reconvert it to firestore geopoint
  if (data.location) {
    newData.location = {
      lat: data.location.lat,
      lng: data.location.lng,
      geopoint: new firebase.firestore.GeoPoint(
        parseFloat(data.location.lat),
        parseFloat(data.location.lng)
      ),
      geohash: Geohash.encode(data.location.lng, data.location.lat, 9),
    };
  } else {
    newData.location = {
      ...newData.location,
      geopoint: new firebase.firestore.GeoPoint(
        parseFloat(newData.location.lat),
        parseFloat(newData.location.lng)
      ),
    };
  }

  if (Object.keys(restaurant).length < 4) {
    let ref = firebase.firestore().collection('restaurants').doc();
    newData.id = ref.id;
    newData.time_joined_posix = new Date().valueOf();
  }

  if (file) {
    let storageRef = firebase.storage()
      .ref()
      .child('restaurants')
      .child('menu_images')
      .child(newData.id);

    let task = await storageRef.put(file, {
      contentType: file.type,
      customMetadata: { name: file.name },
    });

    let imagePath = await task.ref.getDownloadURL();

    newData.image = imagePath;
  }

  await firebase.firestore()
    .collection('restaurants')
    .doc(newData.id)
    .set(newData, { merge: true });

  return newData;
};
