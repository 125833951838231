import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useFormatMessage } from 'hooks';
import { RestaurantForm } from '../../components/Restaurant/restaurant_form';
import { useDispatch } from 'react-redux';
import { updateUserData } from 'state/actions/auth';
// import { updateRestaurantsList } from 'state/actions/restaurant';
import firebase from 'firebase.js';
import { restaurantFormSubmit } from './restaurant_form_submit.js';
import { restaurantCountries, restaurantFoodStyles } from 'utils';

const RestaurantDetails = (props) => {
  const days = [
    useFormatMessage('Restaurant.Sunday'),
    useFormatMessage('Restaurant.Monday'),
    useFormatMessage('Restaurant.Tuesday'),
    useFormatMessage('Restaurant.Wednesday'),
    useFormatMessage('Restaurant.Thursday'),
    useFormatMessage('Restaurant.Friday'),
    useFormatMessage('Restaurant.Saturday'),
  ];
  const businessName = useFormatMessage('Restaurant.businessName');
  const ABN = useFormatMessage('Restaurant.ABN');
  const address = useFormatMessage('Restaurant.address');
  const restaurantContact = useFormatMessage('Restaurant.restaurantContact');
  const ownerName = useFormatMessage('Restaurant.ownerName');
  const contactOfOwner = useFormatMessage('Restaurant.contactOfOwner');
  const timezone = useFormatMessage('Restaurant.timezone');
  const coordinates = useFormatMessage('Restaurant.coordinates');
  const plInsurance = useFormatMessage('Restaurant.plInsurance');
  const bankAccount = useFormatMessage('Restaurant.bankAccount');
  const nonTradingDay = useFormatMessage('Restaurant.nonTradingDay');
  const openingHours = useFormatMessage('Restaurant.tradingHours');
  const restaurantName = useFormatMessage('Restaurant.restaurantName');
  const editRestaurant = useFormatMessage('Restaurant.editRestaurant');
  const email = useFormatMessage('Restaurant.email');
  const dietaries = useFormatMessage('Restaurant.dietary');
  const foodStyle = useFormatMessage('Restaurant.foodStyles');
  const cuisineType = useFormatMessage('Restaurant.countryCategories');

  const setRestaurantCloseMsg = useFormatMessage(
    'Restaurant.setRestaurantCloseMsg'
  );
  const edit = useFormatMessage('Restaurant.edit');
  const dispatch = useDispatch();
  const [workingHours, setWorkingHours] = useState(
    props.restaurant.working_hours
  );
  const [selectedFile, setSelectedFile] = useState();
  const [showModal, setShowModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(props.isExpanded);
  const [isActive, setIsActive] = useState(!props.restaurant.is_active);

  const onRestaurantFormSubmit = async (data) => {
    setShowModal(!showModal);

    let restaurant = await restaurantFormSubmit(
      data,
      props.restaurant,
      selectedFile
    );
    if (props.user.isAdmin) {
      props.onSearchedEdit(restaurant);
    } else {
      dispatch(updateUserData());
    }
  };

  const onHoursChange = async (index) => {
    let newArray = [...workingHours];
    newArray[index].closed = !newArray[index].closed;

    setWorkingHours(newArray);

    let restaurant = {
      ...props.restaurant,
      working_hours: workingHours,
      location: {
        ...props.restaurant.location,
        geopoint: new firebase.firestore.GeoPoint(
          props.restaurant.location.geopoint.latitude,
          props.restaurant.location.geopoint.longitude
        ),
      },
    };

    await firebase.firestore()
      .collection('restaurants')
      .doc(props.restaurant.id)
      .set(restaurant);

    if (props.user.isAdmin) {
      if (props.onEdit) {
        props.onEdit(restaurant);
      }
    } else {
      dispatch(updateUserData());
    }

    //After edit assign edited restaurant back to same so UI will be updated properly
    props.onSearchedEdit(restaurant);
  };

  const setRestaurantActive = async () => {
    setIsActive(!isActive);
    let restaurant = {
      ...props.restaurant,
      location: {
        ...props.restaurant.location,
        geopoint: new firebase.firestore.GeoPoint(
          props.restaurant.location.geopoint.latitude,
          props.restaurant.location.geopoint.longitude
        ),
      },
      is_active: isActive,
    };
    await firebase.firestore()
      .collection('restaurants')
      .doc(props.restaurant.id)
      .set(restaurant);
    if (props.user.isAdmin) {
      if (props.onEdit) {
        props.onEdit(restaurant);
      }
    } else {
      dispatch(updateUserData());
    }

    props.onSearchedEdit(restaurant);
  };

  useEffect(() => {
    setWorkingHours(props.restaurant.working_hours);
  }, [props.restaurant]);

  const containerStyle = () => ({
    border: 'solid',
    borderRadius: '30px',
    margin: '20px',
    padding: '10px',
    textAlign: 'center',
    fontWeight: '600',
  });

  const buttonStyle = () => ({
    cursor: 'pointer',
    borderRadius: '30px',
    borderColor: '#CEAD29',
    color: 'white',
    display: 'inline',
    backgroundColor: '#CEAD29',
    padding: '5px 25px 5px 25px',
  });

  return (
    <>
      <section style={containerStyle()}>
        <div
          onClick={() => setIsExpanded(!isExpanded)}
          style={{ cursor: 'pointer' }}
        >
          <h1 className="subtitle">
            {props.restaurant.name}

            <span className="icon" style={{ float: 'right' }}>
              <i
                className={`mdi ${isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'
                  }`}
              />
            </span>
          </h1>
        </div>

        <div
          className="container"
          style={{
            display: `${isExpanded ? 'block' : 'none'}`,
          }}
        >
          <div className="has-text-right" style={{ padding: '30px' }}>
            <div style={buttonStyle()} onClick={() => setShowModal(!showModal)}>
              {edit}
            </div>
          </div>
          <div className="columns">
            <div className="column is-half">
              <table style={{ margin: '20px', textAlign: 'left' }}>
                <tbody>
                  <tr>
                    <td>{businessName}</td>
                    <td>: {props.restaurant.business_name}</td>
                  </tr>
                  <tr>
                    <td>{ABN}</td>
                    <td>: {props.restaurant.abn}</td>
                  </tr>
                  <tr>
                    <td>{restaurantName}</td>
                    <td>: {props.restaurant.name}</td>
                  </tr>
                  <tr>
                    <td>{address}</td>
                    <td>
                      : {props.restaurant.address.building_number}{' '}
                      {props.restaurant.address.street_name}
                      <br /> {props.restaurant.address.city}
                      <br />{' '}
                      {props.restaurant.address.postcode &&
                        props.restaurant.address.postcode}{' '}
                      {props.restaurant.address.state && (
                        <>
                          {props.restaurant.address.state} <br />
                        </>
                      )}
                      {props.restaurant.address.suburb && (
                        <>
                          {props.restaurant.address.suburb} <br />
                        </>
                      )}
                      {props.restaurant.address.Country}
                    </td>
                  </tr>
                  <tr>
                    <td>{restaurantContact}</td>
                    <td>: {props.restaurant.contact_phone}</td>
                  </tr>
                  <tr>
                    <td>{ownerName}</td>
                    <td>: {props.restaurant.owner_name}</td>
                  </tr>
                  <tr>
                    <td>{contactOfOwner}</td>
                    <td>: {props.restaurant.owner_contact}</td>
                  </tr>
                  <tr>
                    <td>{email}</td>
                    <td>: {props.restaurant.email}</td>
                  </tr>
                  <tr>
                    <td>{timezone}</td>
                    <td>: {props.restaurant?.timezone}</td>
                  </tr>
                  {props.user.isAdmin && (
                    <tr>
                      <td>{coordinates}</td>
                      <td>
                        : {props.restaurant.location.lat},{' '}
                        {props.restaurant.location.lng}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>{plInsurance}</td>
                    <td>: {props.restaurant.pl_insurance}</td>
                  </tr>
                  <tr>
                    <td>{bankAccount}</td>

                    <td>: {props.restaurant.bank_account}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="column is-half"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <table style={{ margin: '15px', textAlign: 'left' }}>
                <tbody>
                  <tr>
                    <td style={{ paddingTop: '5px' }}>{openingHours}</td>
                    <td>
                      <table
                        style={{
                          borderSpacing: '5px 5px',
                          borderCollapse: 'separate',
                        }}
                      >
                        <tbody>
                          {workingHours.map((hours, index) => (
                            <tr key={hours.day}>
                              <td>{days[index]}</td>
                              {isActive ? (
                                <td>Temp closure</td>
                              ) : (hours.closed ? <td>{nonTradingDay}</td> :  (
                                <td>
                                  {moment
                                    .utc(
                                      moment
                                        .duration(hours.start_time, 'h')
                                        .asMilliseconds()
                                    )
                                    .format('HH:mm')}
                                  -
                                  {moment
                                    .utc(
                                      moment
                                        .duration(hours.end_time, 'h')
                                        .asMilliseconds()
                                    )
                                    .format('HH:mm')}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <table
                        style={{
                          borderSpacing: '5px 5px',
                          borderCollapse: 'separate',
                        }}
                      >
                        <tbody>
                          {workingHours.map((hours, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <input
                                    id={`switchRoundedDanger${index + props.restaurant.id
                                      }`}
                                    type="checkbox"
                                    name={`switchRoundedDanger${index + props.restaurant.id
                                      }`}
                                    className="switch is-rounded"
                                    checked={isActive === true ? false : !hours.closed}
                                    value={isActive === true ? false : !hours.closed}
                                    defaultChecked={isActive === true ? false : !hours.closed}
                                    onChange={() => onHoursChange(index)}
                                    disabled={isActive}
                                  />
                                  <label
                                    htmlFor={`switchRoundedDanger${index + props.restaurant.id
                                      }`}
                                  ></label>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>{setRestaurantCloseMsg}</td>
                    <td style={{ paddingLeft: '5px' }}>
                      <input
                        id={props.restaurant.id}
                        type="checkbox"
                        name={props.restaurant.id}
                        className="switch is-rounded"
                        value={isActive}
                        defaultChecked={isActive}
                        onChange={setRestaurantActive}
                      />
                      <label htmlFor={props.restaurant.id} />
                    </td>
                  </tr>
                  <tr style={{ height: '20px', width: '10px' }} />
                  <tr>
                    <td>{dietaries}</td>
                    <td>: {props.restaurant.dietary?.join(', ')}</td>
                  </tr>
                  <tr>
                    <td>{cuisineType}</td>
                    <td>
                      :{' '}
                      {props.restaurant.categories
                        ?.filter((el) => restaurantCountries.includes(el))
                        ?.join(', ')}
                    </td>
                  </tr>

                  <tr>
                    <td>{foodStyle}</td>
                    <td>
                      :{' '}
                      {props.restaurant.categories
                        ?.filter((el) => restaurantFoodStyles.includes(el))
                        ?.join(', ')}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style={{ flexGrow: 1 }}></div>
              <div className="has-text-right" style={{ padding: '30px' }}>
                <div
                  style={buttonStyle()}
                  onClick={() => setShowModal(!showModal)}
                >
                  {edit}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <RestaurantForm
        isActive={showModal}
        setSelectedFile={setSelectedFile}
        onSubmit={onRestaurantFormSubmit}
        title={editRestaurant}
        restaurant={props.restaurant}
        closeModal={() => setShowModal(false)}
        isAdmin={props.user.isAdmin}
      />
    </>
  );
};

export default RestaurantDetails;
