import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError } from 'utils';
import firebase from 'firebase.js';
import { checkUserData, AUTH_UPDATE_USER_DATA } from './auth';

export const USERS_FETCH_DATA_INIT = createAction('USERS_FETCH_DATA_INIT');
export const USERS_FETCH_DATA_SUCCESS = createAction(
  'USERS_FETCH_DATA_SUCCESS'
);
export const USERS_FETCH_DATA_FAIL = createAction('USERS_FETCH_DATA_FAIL');

export const USERS_DELETE_USER_INIT = createAction('USERS_DELETE_USER_INIT');
export const USERS_DELETE_USER_SUCCESS = createAction(
  'USERS_DELETE_USER_SUCCESS'
);
export const USERS_DELETE_USER_FAIL = createAction('USERS_DELETE_USER_FAIL');

export const USERS_CLEAR_DATA = createAction('USERS_CLEAR_DATA');

export const USERS_CREATE_USER_INIT = createAction('USERS_CREATE_USER_INIT');
export const USERS_CREATE_USER_SUCCESS = createAction(
  'USERS_CREATE_USER_SUCCESS'
);
export const USERS_CREATE_USER_FAIL = createAction('USERS_CREATE_USER_FAIL');

export const USERS_MODIFY_USER_INIT = createAction('USERS_MODIFY_USER_INIT');
export const USERS_MODIFY_USER_SUCCESS = createAction(
  'USERS_MODIFY_USER_SUCCESS'
);
export const USERS_MODIFY_USER_FAIL = createAction('USERS_MODIFY_USER_FAIL');

export const USERS_CLEAN_UP = createAction('USERS_CLEAN_UP');

export const USERS_CLEAR_DATA_LOGOUT = createAction('USERS_CLEAR_DATA_LOGOUT');

export const fetchUsers = (restaurantId) => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());

    dispatch(USERS_FETCH_DATA_INIT());

    const { id } = getState().auth.userData;

    let users = {};
    let userDocs = [];

    try {
      if (restaurantId !== undefined) {
        let restaurant = await firebase
          .firestore()
          .collection('restaurants')
          .doc(restaurantId)
          .get();

        for (
          let i = 0;
          i < Math.ceil(restaurant.data().userIds.length / 10);
          i++
        ) {
          const uids = restaurant.data().userIds.slice(0 + i * 10, 10 + 10 * i);

          const snap = await firebase
            .firestore()
            .collection('users')
            .where('uId', 'in', uids)
            .get();

          userDocs = userDocs.concat(snap.docs);
        }
      } else {
        const snap = await firebase.firestore().collection('users').get();
        userDocs = snap.docs;
      }

      //let tmp = userStr.docs.map(doc => {doc.data()});
      users = convertArrayToObject(userDocs, 'id');
      // users = (
      //   await firebase
      //     .database()
      //     .ref('users')
      //     .once('value')
      // ).val();
    } catch (error) {
      console.log(error);
      toastr.error('', error);
      return dispatch(USERS_FETCH_DATA_FAIL({ error }));
    }

    const usersData = users
      ? Object.entries(users).map(([key, value]) => ({
          id: key,
          ...value,
        }))
      : [];

    return dispatch(
      USERS_FETCH_DATA_SUCCESS({
        users: usersData.filter((user) => user.id !== id),
      })
    );
  };
};

const deleteLogo = (oldLogo) => {
  if (!oldLogo.includes('firebasestorage')) {
    return null;
  }
  const logoPath = oldLogo.split('users%2F').pop().split('?alt=media').shift();
  return firebase.storage().ref(`users/${logoPath}`).delete();
};

const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item.data(),
    };
  }, initialValue);
};

export const deleteUser = (id) => {
  return async (dispatch, getState) => {
    dispatch(USERS_DELETE_USER_INIT());
    const { locale } = getState().preferences;
    const { logoUrl } = getState()
      .users.data.filter((user) => user.id === id)
      .pop();

    const deleteLogoTask = logoUrl ? deleteLogo(logoUrl) : null;

    const deleteUserTask = firebase
      .firestore()
      .collection('users')
      .doc(id)
      .delete();
    // const deleteUserTask = firebase
    //   .database()
    //   .ref(`users/${id}`)
    //   .remove();

    try {
      await Promise.all([deleteUserTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        USERS_DELETE_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The user was deleted.');
    return dispatch(USERS_DELETE_USER_SUCCESS({ id }));
  };
};

export const clearUsersData = () => {
  return (dispatch) => {
    dispatch(USERS_CLEAR_DATA());
  };
};

export const clearUsersDataLogout = () => {
  return (dispatch) => {
    dispatch(USERS_CLEAR_DATA_LOGOUT());
  };
};

const uploadLogo = (uid, file) => {
  const storageRef = firebase.storage().ref();

  const fileExtension = file.name.split('.').pop();

  const fileName = `${uid}.${fileExtension}`;

  return storageRef.child(`users/${fileName}`).put(file);
};

const getLogoUrl = (uid, file) => {
  const fileExtension = file.name.split('.').pop();

  const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

  return `${bucketUrl}/o/users%2F${uid}_200x200.${fileExtension}?alt=media`;
};

export const createUser = ({
  name,
  email,
  location,
  file,
  role,
  isDisabled,
  createdAt,
  restaurants,
  isAdmin,
}) => {
  return async (dispatch, getState) => {
    dispatch(USERS_CREATE_USER_INIT());
    const { locale } = getState().preferences;

    let response;
    let uid;

    const customerAccount = await firebase
      .firestore()
      .collection('customers')
      .where('email', '==', email)
      .limit(1)
      .get();

    if (customerAccount.docs.length === 0) {
      try {
        const createUserAuth = firebase
          .functions()
          .httpsCallable('httpsCreateUser');

        response = await createUserAuth({ email, isAdmin, role, name });
      } catch (error) {
        const errorMessage = firebaseError(error.message, locale);
        toastr.error('', errorMessage);
        return dispatch(
          USERS_CREATE_USER_FAIL({
            error: errorMessage,
          })
        );
      }

      uid = response.data.uid;
    } else {
      uid = customerAccount.docs[0].id;
    }

    let uploadLogoTask = null;
    let logoUrl = null;
    if (file) {
      logoUrl = getLogoUrl(uid, file);
      uploadLogoTask = uploadLogo(uid, file);
    }

    let userData = {
      name,
      email,
      location,
      logoUrl,
      createdAt,
      isAdmin,
      isDisabled,
      uId: uid,
    };

    if (!isAdmin) {
      userData.role = role;
      if (role !== 'Rewards') {
        for (var i = 0; i < restaurants.length; i++) {
          await firebase
            .firestore()
            .collection('restaurants')
            .doc(restaurants[i].id)
            .update({ userIds: firebase.firestore.FieldValue.arrayUnion(uid) });
        }
        // userData.restaurantIds = restaurants.map((el) => el.id);
      }
    }

    const createUserDbTask = firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .set(userData);

    try {
      await Promise.all([uploadLogoTask, createUserDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        USERS_CREATE_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'User created successfully');

    return dispatch(USERS_CREATE_USER_SUCCESS({ user: uid }));
  };
};

export const modifyUser = ({
  name,
  location,
  isAdmin,
  file,
  createdAt,
  id,
  role,
  restaurants,
  isEditing,
  isDisabled,
  isProfile,
}) => {
  return async (dispatch, getState) => {
    dispatch(USERS_MODIFY_USER_INIT());
    const { locale } = getState().preferences;
    const { logoUrl } = isProfile
      ? getState().auth.userData
      : getState()
          .users.data.filter((user) => user.id === id)
          .pop();

    let deleteLogoTask;
    let uploadLogoTask;
    let newLogoUrl = null;
    if (file) {
      newLogoUrl = getLogoUrl(id, file);
      deleteLogoTask = logoUrl && deleteLogo(logoUrl);
      uploadLogoTask = uploadLogo(id, file);
    }

    const userData = {
      name,
      location,
      createdAt,
      isAdmin,
      isDisabled,
      logoUrl: logoUrl || newLogoUrl,
    };

    if (!isAdmin && restaurants) {
      userData.role = role;
      if (role !== 'Rewards') {
        let restaurantIds = restaurants.map((e) => e.id);
        if (role === 'Manager' || role === 'Staff') {
          restaurantIds = [restaurantIds[0]];
        }
        let linkedRestaurants = await firebase
          .firestore()
          .collection('restaurants')
          .where('userIds', 'array-contains', id)
          .get();

        for (var i = 0; i < linkedRestaurants.docs.length; i++) {
          if (!restaurantIds.includes(linkedRestaurants.docs[i].id)) {
            //unlink
            linkedRestaurants.docs[i].ref.update({
              userIds: firebase.firestore.FieldValue.arrayRemove(id),
            });
          }
        }

        let newRestaurants = restaurantIds.filter(
          (restaurantId) =>
            !linkedRestaurants.docs.map((el) => el.id).includes(restaurantId)
        );

        // userData.restaurantIds = restaurantIds;

        for (var i = 0; i < newRestaurants.length; i++) {
          firebase
            .firestore()
            .collection('restaurants')
            .doc(newRestaurants[i])
            .update({
              userIds: firebase.firestore.FieldValue.arrayUnion(id),
            });
        }
      }
    }

    const updateUserDbTask = await firebase
      .firestore()
      .collection('users')
      .doc(id)
      .update(userData);

    const { uid } = firebase.auth().currentUser;

    if (id === uid) {
      dispatch(AUTH_UPDATE_USER_DATA({ ...userData, id }));
    }

    try {
      await Promise.all([deleteLogoTask, uploadLogoTask, updateUserDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        USERS_MODIFY_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    if (isProfile) {
      toastr.success('', 'Profile updated successfully');
    } else if (isEditing) {
      toastr.success('', 'User updated successfully');
    }

    return dispatch(USERS_MODIFY_USER_SUCCESS({ user: { ...userData, id } }));
  };
};

export const usersCleanUp = () => (dispatch) => dispatch(USERS_CLEAN_UP());
