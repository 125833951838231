import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { useFormatMessage } from 'hooks';
import UserForm from 'components/UserForm';
import { modifyUser } from 'state/actions/users';
import ChangePassword from './ChangePassword';

const Profile = () => {
  const { userData } = useSelector(
    (state) => ({
      userData: state.auth.userData,
    }),
    shallowEqual
  );

  return (
    <>
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <h1
              className="title has-text-centered pl-3"
              style={{ marginTop: '30px' }}
            >
              {useFormatMessage('Profile.profile')}
            </h1>
          </div>
        </div>
      </nav>

      <section className="section is-main-section">
        <UserForm isEditing isProfile userData={userData} action={modifyUser} />
        <ChangePassword />
      </section>
    </>
  );
};

export default Profile;
