import React from 'react';

const GlanceCard = (props) => {
  const getFontSize = (length) => {
    let fontSize = 80;
    fontSize = fontSize - length * 4;
    if (fontSize < 40) {
      fontSize = 40;
    }
    return fontSize;
  };
  return (
    <div style={{ height: '10rem' }}>
      <div className="card">
        <div className="card-content">
          <h1
            style={{
              fontSize: getFontSize(props.value?.length || 0),
              fontFamily: 'SegoeSc',
              margin: 'auto',
              textAlign: 'center',
              overflow: 'hidden',
              overflowWrap: 'break-word',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              display: '-webkit-box',
            }}
          >
            {props.value}
          </h1>
        </div>
      </div>
      <p className="has-text-centered">{props.label}</p>
    </div>
  );
};

export default GlanceCard;
