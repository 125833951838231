import React, { useState, useEffect } from 'react';
import { TimePicker } from 'antd';

export const CustomTimePicker = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
      let time =
        props.value.hour().toString().padStart(2, '0') +
        ':' +
        props.value.minutes().toString().padStart(2, '0');
      props.onChange(time);
    }
  }, []);
  return (
    <TimePicker
      open={open}
      disabled={props.disabled}
      onOpenChange={() => setOpen(!open)}
      format="HH:mm"
      defaultValue={props.value}
      minuteStep={15}
      showNow={false}
      value={value}
      onChange={(e) => {
        if (e !== null) {
          let time =
            e.hour().toString().padStart(2, '0') +
            ':' +
            e.minutes().toString().padStart(2, '0');
          setValue(e);
          props.onChange(time);
        } else {
          setValue('');
          props.onChange(null);
        }
      }}
    />
  );
};
