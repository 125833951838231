import React from 'react';
import { useFormatMessage } from 'hooks';
import { Popover } from 'antd';
import { Multiselect } from 'multiselect-react-dropdown';
import { v4 } from 'uuid';
const FoodItem = (props) => {
  const status = useFormatMessage('Menu.status');
  const available = useFormatMessage('Menu.available');
  const unavailable = useFormatMessage('Menu.unavailable');
  const soldOut = useFormatMessage('Menu.soldOut');
  const popoverContent = (status, id) => {
    return (
      <div className="popover-content">
        <p
          style={{
            color: `${status === 'Available' ? '#cead29' : ''}`,
          }}
          onClick={() => props.handleStatusChange('Available', id)}
        >
          {available}
        </p>
        <p
          style={{
            color: `${status === 'Item Not Available' ? '#cead29' : ''}`,
          }}
          onClick={() => props.handleStatusChange('Item Not Available', id)}
        >
          {unavailable}
        </p>
        <p
          style={{
            color: `${status === 'Sold Out' ? '#cead29' : ''}`,
          }}
          onClick={() => props.handleStatusChange('Sold Out', id)}
        >
          {soldOut}
        </p>
      </div>
    );
  };

  const onChanged = (list, item) => {
    const index = props.menu.Categories.findIndex(
      (el) => el.Name === item.name
    );
    const newMenu = { ...props.menu };
    if (list.includes(item)) {
      newMenu.Categories[index].Foods.push(props.item);
    } else {
      const foodIndex = props.menu.Categories[index].Foods.findIndex(
        (el) => el.id === props.item.id
      );
      newMenu.Categories[index].Foods.splice(foodIndex, 1);
    }
    props.setMenu(newMenu);
  };

  return (
    <div
      className="grid-item"
      style={{
        cursor: `${props.user.role === 'Staff' ? 'default' : 'grab'}`,
      }}
    >
      <div className="grid-item-content">
        <div>
          <span className="icon">
            <i className="mdi mdi-drag-vertical md-48" />
          </span>
        </div>
        <div>
          <p style={{ fontWeight: 'bold' }}>{props.item.Name}</p>
          <p>Price: {props.item.Price.toFixed(2)}</p>
          <p>
            Status:{' '}
            <span style={{ fontWeight: 'bold' }}>{props.item.Status}</span>
            <span>
              <Popover
                content={popoverContent(props.item.Status, props.item.id)}
                title={status}
                trigger="click"
                placement="leftBottom"
              >
                <span
                  className="icon"
                  style={{ cursor: 'pointer', transform: 'scale(1.5)' }}
                >
                  <i className="mdi mdi-chevron-down md-48" />
                </span>
              </Popover>
            </span>
          </p>
          <div className="is-hidden-tablet">
            <Multiselect
              id={`select${props.item.Name}${v4()}`}
              placeholder="Categories"
              displayValue="name"
              onSelect={onChanged}
              onRemove={onChanged}
              selectedValues={
                props.menu &&
                props.menu.Categories &&
                props.menu.Categories.filter(
                  (el) =>
                    el.Foods.findIndex((food) => food.id === props.item.id) !==
                    -1
                ).map((e, index) => ({ name: e.Name, id: e.Name + index }))
              }
              hidePlaceholder={true}
              options={
                props.menu &&
                props.menu.Categories &&
                props.menu.Categories.map((e, index) => ({
                  name: e.Name,
                  id: e.Name + index,
                }))
              }
              closeIcon="cancel"
              style={{
                searchBox: { marginRight: '8px' },
                chips: { backgroundColor: '#CEAD29', borderRadius: '20px' },
                option: { marginBottom: '0px' },
              }}
              closeOnSelect={false}
            />
          </div>
          {props.user.role !== 'Staff' && (
            <a
              onClick={() => {
                props.setSelectedFoodItemId(props.item.id);
                props.setShowFoodItemForm(!props.showFoodItemForm);
              }}
            >
              Edit
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default FoodItem;
