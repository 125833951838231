import React, { useState, useEffect } from 'react';
import { PieChart } from 'bizcharts';
import { useFormatMessage } from 'hooks';
import moment from 'moment';

const FoodPieChart = (props) => {
  const [data, setData] = useState([]);
  const [dateRange, setDateRange] = useState('Day');
  const day = useFormatMessage('Analytics.day');
  const month = useFormatMessage('Analytics.month');
  const week = useFormatMessage('Analytics.week');
  const title = useFormatMessage('Analytics.soldItems');

  const processData = (rawData) => {
    let processedData = [];
    let docs;
    let dates = [];

    rawData.sort( (a, b)=> {
      return a.id - b.id;
    });

    switch (dateRange) {
      case 'Day':
        var startOfWeek = moment().startOf('isoWeek').toDate();
        var endOfWeek = moment().endOf('isoWeek').toDate();
        docs = rawData.filter(
          (el) => el.id >= startOfWeek && el.id <= endOfWeek
        );

        docs.forEach((doc) => {
          if (doc.data()['foods']) {
            Object.entries(doc.data()['foods']).forEach((el) => {
              let index = processedData.findIndex(
                (item) => item.name === el[0]
              );

              if (index === -1) {
                processedData.push({ name: el[0], value: el[1] });
              } else {
                processedData[index].value += el[1];
              }
            });
          }
        });

        for (let i = 0; i < processedData.length; i++) {
          processedData[i].value = Math.round(
            processedData[i].value / docs.length
          );
        }
        break;
      case 'Week':
        var startOfMonth = moment().utc().startOf('month').toDate();
        var endOfMonth = moment().utc().endOf('month').toDate();

        docs = rawData.filter(
          (el) => el.id >= startOfMonth && el.id < endOfMonth
        );

        docs.forEach((doc) => {
          let date = moment
            .unix(doc.id / 1000)
            .utc()
            .startOf('isoWeek')
            .valueOf();
          if (doc.data()['foods']) {
            if (!dates.includes(date)) {
              dates.push(date);
            }
            Object.entries(doc.data()['foods']).forEach((el) => {
              let index = processedData.findIndex(
                (item) => item.name === el[0]
              );

              if (index === -1) {
                processedData.push({ name: el[0], value: el[1] });
              } else {
                processedData[index].value += el[1];
              }
            });
          }
        });

        for (let i = 0; i < processedData.length; i++) {
          processedData[i].value = Math.round(
            processedData[i].value / dates.length
          );
        }

        break;

      case 'Month':
        rawData.forEach((doc) => {
          let date = moment
            .unix(doc.id / 1000)
            .utc()
            .startOf('month')
            .valueOf();
          if (doc.data()['foods']) {
            if (!dates.includes(date)) {
              dates.push(date);
            }
            Object.entries(doc.data()['foods']).forEach((el) => {
              let index = processedData.findIndex(
                (item) => item.name === el[0]
              );

              if (index === -1) {
                processedData.push({ name: el[0], value: el[1] });
              } else {
                processedData[index].value += el[1];
              }
            });
          }
        });

        for (let i = 0; i < processedData.length; i++) {
          processedData[i].value = Math.round(
            processedData[i].value / dates.length
          );
        }

        break;
    }
    setData(processedData);
  };

  useEffect(() => {
    processData(props.data);
  }, [props.data, dateRange]);

  const dateRangeStyle = (value) => ({
    color: value === dateRange ? '#CEAD29' : 'black',
  });

  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">{title}</p>
        <div className="card-header-icon field is-grouped is-grouped-right">
          <p className="control">
            <a
              style={dateRangeStyle('Day')}
              onClick={() => setDateRange('Day')}
            >
              {day}
            </a>
          </p>
          <p className="control">
            <a
              style={dateRangeStyle('Week')}
              onClick={() => setDateRange('Week')}
            >
              {week}
            </a>
          </p>
          <p className="control">
            <a
              style={dateRangeStyle('Month')}
              onClick={() => setDateRange('Month')}
            >
              {month}
            </a>
          </p>
        </div>
      </header>
      <div className="card-content">
        <PieChart
          height={250}
          data={data}
          radius={0.8}
          angleField="value"
          colorField="name"
          label={{
            visible: true,
            type: 'outer',
            offset: 20,
            content: (data) => {
              return `${data.name}: ${data.value}`;
            },
          }}
        />
      </div>
    </div>
  );
};

export default FoodPieChart;
