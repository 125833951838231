import OrderCard from '../../components/Orders/ordercard';
import firebase from 'firebase.js';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import paths from 'pages/Router/paths';
import notification from '../../assets/notification.mp3';
import useSound from 'use-sound';
import { usePrevious } from 'hooks';

const PendingOrders = (props) => {
  const [orders, setOrders] = useState([]);
  const [play] = useSound(notification);
  const previous = usePrevious(orders);

  useEffect(() => {
    const unsubscribe = firebase.firestore()
      .collection('orders')
      .where('restaurant_id', '==', props.restaurant.id)
      .where('current_status', 'in', [
        'New',
        'Preparing',
        'FoodIsReady',
        'Accepted',
      ])
      .onSnapshot(function (querySnapshot) {
        let tmp = [];
        querySnapshot.forEach((doc) => {
          tmp.push(doc.data());
        });
        tmp.sort(function (b, a) {
          return a.created_at_posix - b.created_at_posix;
        });

        setOrders(tmp);
      });
    return () => unsubscribe();
  }, [props.restaurant]);

  useEffect(() => {
    if (previous) {
      if (orders.length > previous.length) {
        play();
      }
    }
  }, [previous]);

  const renderOrderCards = () => {
    return orders.map((order) => (
      <div
        key={order.id}
        style={{
          flex: '0 1 220px',
          paddingTop: '20px',
          marginLeft: '20px',
        }}
      >
        <OrderCard order={order} restaurant={props.restaurant} />
      </div>
    ));
  };

  const redirect = props.restaurant.pos_provider &&
    props.restaurant.pos_provider !== 'None' && props.restaurant.pos_provider !== 'Provided a Tablet' && <Redirect path={paths.ROOT} />;

  return (
    <div className="container">
      {redirect}
      <div
        style={{
          paddingTop: '24px',
          display: 'flex',
          flexFlow: 'row wrap',
        }}
      >
        {renderOrderCards()}
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth, restaurants }) => ({
  restaurant:
    auth.userData.isAdmin !== null
      ? auth.userData.isAdmin
        ? restaurants.data[auth.userData.selectedRestaurant]
        : auth.userData.restaurants[auth.userData.selectedRestaurant]
      : {},
});

export default connect(mapStateToProps)(PendingOrders);
