import React from 'react';
import CoffeeToGo from '../../assets/coffee-to-go.png';
import OneFree from '../../assets/one-free.png';
import { useFormatMessage } from 'hooks';

const PreviewCard = ({ restaurant, selectedAmount }) => {
  return (
    <div
      style={{
        border: '1px solid black',
        borderRadius: '20px',
        padding: '8px 40px 8px 40px',
        textAlign: 'center',
      }}
    >
      <div style={{ borderBottom: '1px solid grey' }}>
        <p style={{ color: '#FFCA28', fontSize: '20px' }}>{restaurant.name}</p>
      </div>
      <p style={{ fontSize: '13px', fontWeight: 'bold' }}>
        {useFormatMessage('Loyalty.cardSubtitle')}
      </p>
      <p style={{ fontWeight: 'bold' }}>
        Buy {selectedAmount}, Get the {selectedAmount + 1}th one Free...
      </p>
      <div
        className="is-flex is-flex-wrap-wrap"
        style={{
          maxWidth: `${
            selectedAmount <= 5
              ? (selectedAmount + 1) * 48
              : Math.ceil((selectedAmount + 1) / 2) * 48
          }px`,
        }}
      >
        {Array(
          selectedAmount <= 5 ? (selectedAmount + 1) * 3 : selectedAmount + 1
        )
          .fill(false)
          .map((e, index) => (
            <div
              key={index}
              style={{
                border:
                  index !== 0 && index % (selectedAmount + 1) !== selectedAmount
                    ? '1px solid black'
                    : '',
                borderRadius: '50%',
                height: '40px',
                width: '40px',
                margin: '4px',
                backgroundImage: `url(${
                  index === 0
                    ? CoffeeToGo
                    : index % (selectedAmount + 1) === selectedAmount
                    ? OneFree
                    : ''
                })`,
                filter: index === 0 ? 'grayscale(100%)' : '',

                backgroundSize: 'contain',
              }}
            ></div>
          ))}
      </div>
      <p
        style={{
          fontSize: '10px',
          fontWeight: 'bold',
          textAlign: 'left',
        }}
      >
        {useFormatMessage('Loyalty.finePrint')}
      </p>
    </div>
  );
};

export default PreviewCard;
