import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useFormatMessage, useDebounce } from 'hooks';
import firebase from 'firebase.js';
import { CustomModal } from 'components/Modal';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

const RedeemList = (props) => {
  const [redeemed, setRedeemed] = useState([]);
  const [page, setPage] = useState({ previous: 0, current: 0 });
  const [last, setLast] = useState();
  const [showModal, setShowModal] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [search, setSearch] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    const search = async () => {
      setLoadingSearch(true);

      let query = firebase.firestore()
        .collection('rewards_history')
        .limit(9)
        .orderBy('voucher_code')
        .startAt(debouncedSearch)
        .endAt(debouncedSearch + '~');

      if (!props.user.isAdmin) {
        query = query.where('reward_user_id', '==', '');
      }

      const snap = await query.get();
      const redeemed = snap.docs.map((el) => el.data());
      redeemed.sort((b, a) => a.activated_at_posix - b.activated_at_posix);
      setRedeemed(redeemed);
      setLoadingSearch(false);
    };

    if (debouncedSearch === '') {
      setPage({ previous: 0, current: 0 });
      setRedeemed([]);
    } else {
      search();
    }
  }, [debouncedSearch]);

  const loadDocs = async () => {
    let query = firebase.firestore()
      .collection('rewards_history')
      .limit(10)
      .orderBy('activated_at_posix', 'desc');

    if (!props.user.isAdmin) {
      query = query.where('reward_user_id', '==', props.user.id);
    }
    if (page.current > 0) {
      if (page.current > page.previous) {
        query = query.startAfter(last);
      }
    }

    if (dateRange.length > 0) {
      query = query
        .where('activated_at_posix', '>=', dateRange[0].valueOf())
        .where('activated_at_posix', '<=', dateRange[1].valueOf());
    }

    const snapshot = await query.get();
    if (!snapshot.empty && page.current >= page.previous) {
      let newRedeemed = redeemed.concat(
        snapshot.docs.map((e) => ({ ...e.data(), id: e.id }))
      );

      setRedeemed(newRedeemed);
      setLast(newRedeemed.slice(-1)[0].activated_at_posix);
    }
  };

  useEffect(() => {
    loadDocs();
  }, [page]);

  const renderRedeemedTable = () => {
    let rows = redeemed
      .slice(page.current * 10, 10 + page.current * 10)
      .map((voucher) => {
        const date = new Date(voucher.activated_at_posix);
        return (
          <tr key={voucher.voucher_code}>
            <td>
              {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
            </td>
            <td>{voucher.customer_name}</td>
            <td>Code #{voucher.voucher_code}</td>
            <td>{voucher.reward_offer}</td>
          </tr>
        );
      });

    if (rows.length < 10) {
      rows = rows.concat(
        Array.from(Array(10 - rows.length), (_, i) => (
          <tr key={i}>
            {Array(4)
              .fill(null)
              .map((e, index) => (
                <td key={index} className="td-not-collapsible" />
              ))}
          </tr>
        ))
      );
    }
    return rows;
  };

  return (
    <>
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <h1
              className="title has-text-centered pl-3"
              style={{ marginTop: '30px' }}
            >
              {useFormatMessage('RedeemList.redeemList')}
            </h1>
          </div>
        </div>
      </nav>
      <section className="section">
        <div className="container">
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-evenly',
            }}
          >
            <div>
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <button
                        className="button"
                        onClick={() => setShowModal(!showModal)}
                      >
                        {useFormatMessage('RedeemList.dateRangeFilter')}
                      </button>
                    </div>
                  </div>
                  <div className="field has-addons">
                    <div
                      className={`control is-expanded ${
                        loadingSearch && 'is-loading'
                      }`}
                    >
                      <input
                        className="input"
                        value={search}
                        placeholder={useFormatMessage(
                          'RedeemList.searchByReference'
                        )}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <div className="control">
                      <button className="button" onClick={() => setSearch('')}>
                        <span className="icon">
                          <i className="mdi mdi-close" />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <table
                className="table is-hoverable"
                style={{ tableLayout: 'fixed' }}
              >
                <tbody>{renderRedeemedTable()}</tbody>
                <tfoot>
                  <tr>
                    <td>
                      <a
                        className="pagination-previous"
                        disabled={page.current === 0}
                        onClick={() =>
                          setPage({
                            previous: page.current,
                            current: page.current - 1,
                          })
                        }
                      >
                        {useFormatMessage('RedeemList.previousPage')}
                      </a>
                      <a className="pagination-link" aria-label="Current Page">
                        {page.current + 1}
                      </a>
                      <a
                        className="pagination-next"
                        disabled={redeemed.slice(page.current * 10).length < 10}
                        onClick={() => {
                          setPage({
                            previous: page.current,
                            current: page.current + 1,
                          });
                        }}
                      >
                        {useFormatMessage('RedeemList.nextPage')}
                      </a>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>

        <CustomModal
          isActive={showModal}
          closeModal={() => setShowModal(!showModal)}
          title="Set date range"
        >
          <div className="level">
            <div className="level-item has-text-centered">
              <RangePicker value={dateRange} onChange={setDateRange} />
            </div>
          </div>
          <div className="level">
            <div className="level-item has-text-centered">
              <button
                className="button"
                onClick={() => {
                  setShowModal(!showModal);
                  setDateRange([]);
                  setRedeemed([]);
                  setPage({ previous: 0, current: 0 });
                }}
              >
                {useFormatMessage('RedeemList.clear')}
              </button>
              <div style={{ width: '20px' }}></div>
              <button
                className="button"
                onClick={() => {
                  setShowModal(!showModal);
                  setRedeemed([]);
                  setPage({ previous: 0, current: 0 });
                }}
              >
                {useFormatMessage('RedeemList.submit')}
              </button>
            </div>
          </div>
        </CustomModal>
      </section>
    </>
  );
};
const mapStateToProps = ({ auth }) => ({
  user: auth.userData,
});

export default connect(mapStateToProps)(RedeemList);
