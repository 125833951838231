import axios from 'axios';
import ConfirmationModal from 'components/ConfirmationModal';
import PromotionForm from 'components/Promotions/promotionform';
import firebase from 'firebase.js';
import { useFormatMessage } from 'hooks';
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { v4 } from 'uuid';
import './promotions.css';


const region = process.env.REACT_APP_REGION || 'asia-east2';
const projectId = process.env.REACT_APP_FIRE_BASE_PROJECT_ID || 'heydoor-a090d';

const Promotions = (props) => {
  const pageTitle = useFormatMessage('Promotions.promotions');
  const [showModal, setShowModal] = useState(false);
  const addPromotion = useFormatMessage('Promotions.addPromotion');
  const disabled = useFormatMessage('Promotions.disabled');
  const editPromotion = useFormatMessage('Promotions.editPromotion');
  const expired = useFormatMessage('Promotions.expired');
  const currentPromos = useFormatMessage('Promotions.currentPromos');
  const expiredPromos = useFormatMessage('Promotions.expiredPromos');
  const deleteLabel = useFormatMessage('Promotions.delete');
  const startedOn = useFormatMessage('Promotions.startedOn');
  const expiredOn = useFormatMessage('Promotions.expiredOn');
  const firstOrder = useFormatMessage('Promotions.firstOrder');
  const type = useFormatMessage('Promotions.type');
  const value = useFormatMessage('Promotions.value');
  const title = useFormatMessage('Promotions.title');
  const description = useFormatMessage('Promotions.description');
  const active = useFormatMessage('Promotions.active');
  const startingOn = useFormatMessage('Promotions.startingOn');
  const [promos, setPromos] = useState({ current: [], expired: [] });
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [expanded, setExpanded] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState({
    promo: {},
    isPopupActive: false,
  });

  const flexContainerStyle = () => ({
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 0,
    // justifyContent: 'space-evenly',
  });

  const promoStyle = () => ({
    width: '300px',
    padding: '10px',
    margin: '10px',
    fontSize: '16px',
    borderStyle: 'solid',
    borderWidth: 'thin',
    borderColor: '#cac8c8',
    borderRadius: '20px',
    overflow: 'auto',
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    data.date_range[0].hour(0).minutes(0);
    data.date_range[1].hour(23).minutes(59);

    let promo = {
      ...data,
      date_active_from_posix: data.date_range[0].valueOf(),
      date_active_to_posix: data.date_range[1].valueOf(),
    };

    delete promo.date_range;

    let meta = {};
    if (selectedIndex == null) {
      promo.id = v4();
    } else {
      try {
        meta = (
          await firebase.storage()
            .ref(`restaurants/promotions/TnCs/${promo.id}`)
            .getMetadata()
        ).customMetadata;
      } catch (e) { }
      promo.id = promos.current[selectedIndex].id;
    }

    meta[props.user.id] = 'true';

    const storageRef = firebase.storage()
      .ref()
      .child('restaurants/promotions/TnCs')
      .child(promo.id);

    var blob = new Blob([promo.terms_and_conditions], { type: 'text/plain' });

    const task = await storageRef.put(blob, {
      contentType: 'text/plain',
      customMetadata: meta,
    });

    const path = await task.ref.getDownloadURL();

    promo.terms_and_conditions = path;

    let newPromos = [...promos.current, ...promos.expired];

    if (selectedIndex == null) {
      newPromos.push(promo);
    } else {
      newPromos.splice(selectedIndex, 1, promo);
    }

    await firebase.firestore()
      .collection('menu')
      .doc(props.restaurant.id)
      .set({ Promotions: newPromos }, { merge: true });

    try {
      await axios.post(
        `https://${region}-${projectId}.cloudfunctions.net/httpsRestaurantPromo`,
        { restaurantId: props.restaurant.id },

        {
          headers: {
            authorization: firebase.auth().currentUser.getIdToken(),
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (e) {
      console.log(e);
    }

    setShowModal(!showModal);
    setIsLoading(false);
  };

  const toggleExpanded = (id) => {
    if (expanded.includes(id)) {
      setExpanded(expanded.filter((el) => el !== id));
    } else {
      setExpanded([...expanded, id]);
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.firestore()
      .collection('menu')
      .doc(props.restaurant.id)
      .onSnapshot(function (query) {
        if (query.exists && query.data().Promotions) {
          let promos = [...query.data().Promotions];
          promos.sort(
            (b, a) => a.date_active_to_posix - b.date_active_to_posix
          );
          const date = new Date().valueOf();
          const current = [];
          const expired = [];
          promos.forEach((promo) => {
            if (promo.date_active_to_posix > date) {
              current.push(promo);
            } else {
              if (!promo.hidden) {
                expired.push(promo);
              }
            }
          });

          setPromos({ current, expired });
        } else {
          setPromos({ current: [], expired: [] });
        }
        setInitialLoading(false);
      });

    return () => unsubscribe();
  }, [props.restaurant]);

  const deletePromo = async (promo) => {
    const newExpiredPromos = [...promos.expired];
    const index = newExpiredPromos.findIndex((el) => el.id === promo.id);

    if (index > -1) {
      newExpiredPromos[index] = { ...promo, hidden: true };

      await firebase.firestore()
        .collection('menu')
        .doc(props.restaurant.id)
        .update({ Promotions: [...promos.current, ...newExpiredPromos] });
    }

    setConfirmDelete({ promo: {}, isPopupActive: false });
  };

  const renderStatus = (item) => {
    if (!item.is_active) {
      return disabled;
    }
    if (item.date_active_to_posix < Date.now()) {
      return expired;
    } else if (item.date_active_from_posix > Date.now()) {
      return (
        startingOn +
        ' ' +
        new Date(item.date_active_from_posix).toLocaleDateString()
      );
    } else {
      return active;
    }
  };

  return (
    <>
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <h1 className="title has-text-centered pl-3">{pageTitle}</h1>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <div style={{ float: 'right', margin: '30px' }}>
              <button
                className="button is-warning"
                onClick={() => {
                  setSelectedIndex(null);
                  setShowModal(!showModal);
                }}
              >
                {useFormatMessage('Promotions.addPromo')}
              </button>
            </div>
          </div>
        </div>
      </nav>
      {initialLoading ? (
        <ClipLoader
          css={`
            display: block;
            margin: auto;
            margin-top: 100px;
            margin-bottom: 100px;
          `}
        />
      ) : (
        <>
          <h2 className="subtitle pl-3">{currentPromos}</h2>
          <section className="section" style={flexContainerStyle()}>
            {promos.current.map((item, index) => {
              let exp_date = new Date(item.date_active_to_posix);
              return (
                <div
                  className="card"
                  key={item.date_active_from_posix}
                  style={promoStyle()}
                >
                  <div>
                    <p
                      style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    >
                      {item.title}
                    </p>
                    <p>{'Exp: ' + exp_date.toLocaleDateString()}</p>
                    <p>
                      {renderStatus(item)}{' '}
                      <span
                        onClick={() => {
                          setSelectedIndex(index);
                          setShowModal(!showModal);
                        }}
                        style={{
                          cursor: 'pointer',
                          float: 'right',
                          borderRadius: '20px',
                          padding: '0px 12px 0px 12px',
                        }}
                        className="icon"
                      >
                        <i className={`mdi mdi-pencil`} />
                      </span>
                    </p>
                  </div>
                </div>
              );
            })}
            {Array(3 - (promos.current.length % 3))
              .fill('')
              .map((e, index) => (
                <div key={index} style={{ flexBasis: '250px' }}></div>
              ))}
          </section>
          <h2 className="subtitle pl-3">{expiredPromos}</h2>
          <section>
            <div style={{ overflowX: 'scroll' }}>
              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    <th>{title}</th>
                    <th>{startedOn}</th>
                    <th>{expiredOn}</th>
                    <th>{deleteLabel}</th>
                    <th />
                  </tr>
                </thead>
                {promos.expired.map((el) => (
                  <tr
                    onClick={() => toggleExpanded(el.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    <td>
                      {el.title}{' '}
                      <div
                        className={`collapsible ${expanded.includes(el.id) ? 'active' : ''
                          }`}
                      >
                        <p>
                          {type}: {el.discount_type} - {value}:{' '}
                          {el.discount_value}
                        </p>
                        <p>
                          {description}: {el.description}
                        </p>

                        <p>
                          {firstOrder}:{' '}
                          <span>
                            <i
                              className={
                                el.first_order_only
                                  ? 'mdi mdi-check'
                                  : 'mdi mdi-close'
                              }
                            />
                          </span>
                        </p>
                      </div>
                    </td>
                    <td>
                      {new Date(el.date_active_from_posix).toLocaleDateString()}
                    </td>
                    <td>
                      {new Date(el.date_active_to_posix).toLocaleDateString()}
                    </td>
                    <td>
                      <i
                        onClick={() =>
                          setConfirmDelete({ promo: el, isPopupActive: true })
                        }
                        className="mdi mdi-delete"
                        style={{ cursor: 'pointer' }}
                      />
                    </td>
                    <td>
                      <div
                        className={`rotating-box ${expanded.includes(el.id) ? 'rotate' : ''
                          }`}
                      >
                        <i className="mdi mdi-arrow-up" />
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </section>
        </>
      )}
      <ConfirmationModal
        isActive={confirmDelete.isPopupActive}
        isLoading={false}
        title="Delete"
        body="Are you sure you want to delete this promotion from your records ?"
        confirmButtonMessage="Confirm"
        onConfirmation={() => deletePromo(confirmDelete.promo)}
        cancelButtonMessage="Cancel"
        onCancel={() => setConfirmDelete({ promo: {}, isPopupActive: false })}
      />
      <PromotionForm
        promo={selectedIndex !== null ? promos.current[selectedIndex] : {}}
        currentPromos={promos.current}
        onSubmit={onSubmit}
        isLoading={isLoading}
        closeModal={() => setShowModal(!showModal)}
        isActive={showModal}
        title={selectedIndex !== null ? editPromotion : addPromotion}
      />
    </>
  );
};

const mapStateToProps = ({ auth, restaurants }) => {
  var x =
  {
  restaurant:
    auth.userData.isAdmin !== null
      ? auth.userData.isAdmin
        ? restaurants.data[auth.userData.selectedRestaurant]
        : auth.userData.restaurants[auth.userData.selectedRestaurant]
      : {},
  user: auth.userData,
};

return x;
}

export default connect(mapStateToProps)(Promotions);
