import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';

import UserForm from 'components/UserForm';
import { createUser, modifyUser } from 'state/actions/users';
import paths from 'pages/Router/paths';
import firebase from 'firebase.js';
import { useFormatMessage } from 'hooks';

const User = () => {
  const { id } = useParams();

  const { success } = useSelector(
    (state) => ({
      success: state.users.success,
    }),
    shallowEqual
  );

  const [user, setUser] = useState({
    name: '',
    email: '',
    location: '',
    role: 'Staff',
    isAdmin: false,
    isDisabled: false,
    restaurants: [{ name: '' }],
    file: null,
    createdAt: new Date().toDateString(),
  });

  useEffect(() => {
    const fetchUserData = async () => {
      let user = await firebase.firestore().collection('users').doc(id).get();

      let logins;

      if (user.data().role === 'Staff') {
        logins = await firebase
          .firestore()
          .collection('login_history')
          .doc(id)
          .get();
      }
      let restaurantDocs = await firebase
        .firestore()
        .collection('restaurants')
        .where('userIds', 'array-contains', id)
        .get();

      let restaurants = restaurantDocs.docs.map((e) => e.data());

      const response = {
        ...user.data(),
        login_history: logins
          ? logins.exists
            ? logins.data().timestamps
            : []
          : [],
        restaurants: [...restaurants],
      };

      return response;
    };

    if (id) {
      fetchUserData()
        .then((userData) => {
          setUser({
            ...userData,
            createdAt: userData.createdAt,
            id,
            isAdmin: userData.isAdmin,
          });
        })
        .catch((e) => {
          console.log(e);
          setUser({ error: true });
        });
    }
  }, [id]);

  const isEditing = !!id;

  const userForm =
    !user.name && id ? (
      <ClipLoader
        css={`
          display: block;
          margin: auto;
          margin-top: 100px;
          margin-bottom: 100px;
        `}
      />
    ) : (
      <UserForm
        isEditing={isEditing}
        userData={user}
        action={isEditing ? modifyUser : createUser}
      />
    );

  const redirect = (user.error || success) && <Redirect to={paths.USERS} />;

  const editUserMessage = useFormatMessage('User.editUser');

  const newUserMessage = useFormatMessage('User.editUser');

  return (
    <>
      {redirect}
      <nav className="level" style={{ marginTop: '30px' }}>
        <div className="level-left">
          <div className="level-item">
            <h1 className="title has-text-centered pl-3">
              {isEditing ? editUserMessage : newUserMessage}
            </h1>
          </div>
        </div>
      </nav>

      <section className="section is-main-section">{userForm}</section>
    </>
  );
};

export default User;
