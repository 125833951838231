import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ReduxToastr from 'react-redux-toastr';
import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';

import LanguageWrapper from 'components/LanguageWrapper';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { configureStore } from './state/store';

import Router from './pages/Router';
import * as serviceWorker from './serviceWorker';

import './assets/css/main.css';
import './index.scss';
import './styles/customStyles.css';

const { store, persistor } = configureStore({});

const App = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

  if (!isLatestVersion) {
    console.log('Not the latest version, Will empty cache');
    emptyCacheStorage();
  }
  return (
    <Provider store={store}>
      <LanguageWrapper>
        <PersistGate persistor={persistor}>
          <ReduxToastr
            newestOnTop={false}
            preventDuplicates
            position="bottom-right"
            getState={(state) => state.toastr}
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
          />
          <Router />
        </PersistGate>
      </LanguageWrapper>
    </Provider>
  );
};

ReactDOM.render(
  <ClearCacheProvider duration={500}>
    <App />
  </ClearCacheProvider>,
  document.getElementById('root')
);

serviceWorker.register();
