import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useFormatMessage } from 'hooks';
import { toastr } from 'react-redux-toastr';
import firebase from 'firebase.js';
import PreviewCard from 'components/Loyalty/preview_card';
import LoyaltyForm from 'components/Loyalty/form';
import { updateActiveRestaurant } from 'state/actions/restaurant';
import { updateUserData } from 'state/actions/auth';
import axios from 'axios';

export const Loyalty = ({ restaurant, isAdmin }) => {
  const errorFileRequired = useFormatMessage('Loyalty.errorFileRequired');
  const updateSuccess = useFormatMessage('Loyalty.updateSuccess');
  const [selectedAmount, setSelectedAmount] = useState(4);
  const [showForm, setShowForm] = useState(
    restaurant.has_loyalty_card !== undefined
  );
  const [termsLoading, setLoadingTerms] = useState(false);
  const [drinksLoyaltyEnabled, setDrinksLoyaltyEnabled] = useState(
    restaurant.has_loyalty_card || true
  );

  const [isLoading, setIsLoading] = useState(false);

  const [termsAndConditions, setTermsAndConditions] = useState();
  const dispatch = useDispatch();

  const save = async () => {
    if (termsAndConditions !== '') {
      setIsLoading(true);

      const storageRef = firebase.storage()
        .ref()
        .child('restaurants/loyalty_cards/TnCs')
        .child(restaurant.id);

      var blob = new Blob([termsAndConditions], { type: 'text/plain' });

      const task = await storageRef.put(blob, {
        contentType: 'text/plain',
      });

      const path = await task.ref.getDownloadURL();

      const loyalty_card_definition = {
        free_position: selectedAmount + 1,
        loyalty_card_type: 'Drinks',
        terms_and_conditions: path,
      };

      firebase.firestore().collection('restaurants').doc(restaurant.id).update({
        loyalty_card_definition,
        has_loyalty_card: drinksLoyaltyEnabled,
      });

      if (isAdmin) {
        dispatch(
          updateActiveRestaurant({
            ...restaurant,
            loyalty_card_definition,
            has_loyalty_card: drinksLoyaltyEnabled,
          })
        );
      } else {
        dispatch(updateUserData());
      }

      setIsLoading(false);

      toastr.success('', updateSuccess);
    } else {
      toastr.warning('', errorFileRequired);
    }
  };

  const fetchTerms = async () => {
    setLoadingTerms(true);
    const response = await axios.get(
      restaurant.loyalty_card_definition.terms_and_conditions
    );
    if (response.data) {
      setTermsAndConditions(response.data);
      setLoadingTerms(false);
    }
  };

  useEffect(() => {
    setShowForm(restaurant.has_loyalty_card !== undefined);
    setDrinksLoyaltyEnabled(restaurant.has_loyalty_card);
    setTermsAndConditions('');
    setIsLoading(false);
    if (restaurant.loyalty_card_definition) {
      setSelectedAmount(restaurant.loyalty_card_definition.free_position - 1);
      fetchTerms();
    } else {
      setSelectedAmount(4);
    }
  }, [restaurant]);

  return (
    <>
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <h1 className="title has-text-centered pl-3">
              {useFormatMessage('Loyalty.loyalty')}
            </h1>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <div style={{ float: 'right', margin: '30px' }}>
              <button
                className="button is-warning"
                disabled={showForm}
                onClick={() => setShowForm(true)}
              >
                {useFormatMessage('Loyalty.addLoyalty')}
              </button>
            </div>
          </div>
        </div>
      </nav>
      {showForm && (
        <section className="section" style={{ paddingTop: 0 }}>
          <div className="is-flex is-flex-wrap-wrap is-align-items-flex-start">
            <LoyaltyForm
              setSelectedAmount={setSelectedAmount}
              selectedAmount={selectedAmount}
              restaurant={restaurant}
              save={save}
              termsLoading={termsLoading}
              isLoading={isLoading}
              termsAndConditions={termsAndConditions}
              setTermsAndConditions={setTermsAndConditions}
              drinksLoyaltyEnabled={drinksLoyaltyEnabled}
              setDrinksLoyaltyEnabled={setDrinksLoyaltyEnabled}
            />
            <PreviewCard
              restaurant={restaurant}
              selectedAmount={selectedAmount}
            />
          </div>
        </section>
      )}
    </>
  );
};

const mapStateToProps = ({ auth, restaurants }) => ({
  restaurant:
    auth.userData.isAdmin !== null
      ? auth.userData.isAdmin
        ? restaurants.data[auth.userData.selectedRestaurant]
        : auth.userData.restaurants[auth.userData.selectedRestaurant]
      : {},
  isAdmin: auth.userData.isAdmin,
});

export default connect(mapStateToProps)(Loyalty);
