import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import classNames from 'classnames';
import ClipLoader from 'react-spinners/ClipLoader';

import { useFormatMessage, useFormatDate } from 'hooks';
import Table from 'components/Table';
import {
  fetchUsers,
  deleteUser,
  clearUsersData,
  modifyUser,
} from 'state/actions/users';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import classes from './Users.module.scss';
import moment from 'moment';

const Users = () => {
  const { usersList, user, error, loading, deleted, restaurant } = useSelector(
    ({ auth, users, restaurants }) => ({
      usersList: users.data,
      user: auth.userData,
      error: users.error,
      loading: users.loading,
      deleted: users.deleted,
      restaurant:
        auth.userData.isAdmin !== null
          ? auth.userData.isAdmin
            ? restaurants.data[auth.userData.selectedRestaurant]
            : auth.userData.restaurants[auth.userData.selectedRestaurant]
          : {},
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    userId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  const [search, setSearch] = useState();

  useEffect(() => {
    if (user.isAdmin) {
      dispatch(fetchUsers());
    } else {
      dispatch(fetchUsers(restaurant.id));
    }

    return () => dispatch(clearUsersData());
  }, [dispatch, user.isAdmin, restaurant]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        userId: null,
        isOpen: !prevState.isOpen,
      }));
    }
  }, [deleted, loading]);

  const onRemoveButtonClickHandler = (userId) => {
    setDeleteModal((prevState) => ({
      userId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ userId: null, isOpen: false });
  };

  const onDisableUserHandler = () => {
    let user = usersList.find((el) => el.id === deleteModal.userId);
    user.isDisabled = user.isDisabled === undefined ? true : !user.isDisabled;
    dispatch(modifyUser(user));
    setDeleteModal({ userId: null, isOpen: false });
  };

  const adminHeader = useFormatMessage('Users.admin');
  const roleHeader = useFormatMessage('Users.role');

  const columns = [
    {
      Header: '',
      accessor: 'logoUrl',
      Cell: ({ row }) => (
        <div className="image">
          <img src={row.original.logoUrl} alt="" className="is-rounded" />
        </div>
      ),
      disableSortBy: true,
    },
    {
      Header: useFormatMessage('Users.name'),
      accessor: 'name',
    },
    {
      Header: useFormatMessage('Users.email'),
      accessor: 'email',
    },
    {
      Header: useFormatMessage('Users.location'),
      accessor: 'location',
    },
    ...(user.isAdmin
      ? [
          {
            Header: adminHeader,
            accessor: 'user.isAdmin',
            Cell: ({ row }) => (
              <small className="has-text-grey is-abbr-like">
                {row.original.isAdmin ? (
                  <span className="icon">
                    <i className="mdi mdi-check" />
                  </span>
                ) : (
                  <span className="icon">
                    <i className="mdi mdi-close" />
                  </span>
                )}
              </small>
            ),
          },
        ]
      : []),
    {
      Header: roleHeader,
      accessor: 'role',
    },
    {
      Header: useFormatMessage('Users.created'),
      accessor: 'created',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {useFormatDate(row.original.createdAt, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </small>
      ),
    },
    {
      Header: useFormatMessage('Users.lastSeen'),
      accessor: 'lastSeen',
      Cell: ({ row }) => (
        <small className="has-text-grey is-abbr-like">
          {row.original.lastSeen &&
            moment(row.original.lastSeen).format('ddd, MMM D, yyyy')}
        </small>
      ),
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <>
          {((!row.original.isAdmin && row.original.role !== 'Owner') ||
            (user.isAdmin && !row.original.isAdmin)) && (
            <div className="buttons is-right">
              <Link
                to={`/users/${row.original.id}`}
                className="button is-small is-primary"
              >
                <span className="icon is-small">
                  <i className="mdi mdi-account-edit" />
                </span>
              </Link>

              <button
                type="button"
                className={`button is-small ${
                  !row.original.isDisabled ? 'is-danger' : 'is-success'
                }`}
                onClick={() => onRemoveButtonClickHandler(row.original.id)}
                title={
                  !row.original.isDisabled ? 'Disable User' : 'Reactivate User'
                }
              >
                <span className="icon is-small">
                  <i
                    className={`mdi ${
                      !row.original.isDisabled
                        ? 'mdi-account-off'
                        : 'mdi-account'
                    }`}
                  />
                </span>
              </button>
            </div>
          )}
        </>
      ),
      disableSortBy: true,
    },
  ];

  const data = search
    ? usersList.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.id;
        delete clonedElem.isAdmin;
        delete clonedElem.logoUrl;
        delete clonedElem.isDisabled;
        delete clonedElem.restaurantIds;
        delete clonedElem.lastSeen
        return Object.values(clonedElem).some((field) =>
          field.toLowerCase().includes(search.toLowerCase())
        );
      })
    : usersList;

  const disableButton = useFormatMessage('Users.disableButton');
  const enableButton = useFormatMessage('Users.enableButton');
  const confirmMessage = useFormatMessage('Users.confirm');

  const disableMessage = useFormatMessage('Users.disable');
  const reEnableMessage = useFormatMessage('Users.reEnable');

  const cancelMessage = useFormatMessage('Users.cancel');

  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={
            !usersList.find((el) => el.id === deleteModal.userId).isDisabled
              ? disableButton
              : enableButton
          }
          title={confirmMessage}
          body={
            !usersList.find((el) => el.id === deleteModal.userId).isDisabled
              ? disableMessage
              : reEnableMessage
          }
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDisableUserHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <nav className="level" style={{ marginTop: '30px', marginRight: '30px' }}>
        <div className="level-left">
          <div className="level-item">
            <h1 className="title has-text-centered pl-3">
              {useFormatMessage('Users.users')}
            </h1>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <Link to={paths.ADD_USER} className="button is-warning">
              {useFormatMessage('Users.newUser')}
            </Link>
          </div>
        </div>
      </nav>

      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className={classNames('card-header-title', classes.tableHeader)}>
              <span>{useFormatMessage('Users.search')}</span>
              <input
                type="text"
                className="input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </p>
          </header>
          <div className="b-table">
            {loading ? (
              <ClipLoader
                css={`
                  display: block;
                  margin: auto;
                  margin-top: 100px;
                  margin-bottom: 100px;
                `}
              />
            ) : (
              <Table columns={columns} data={data} />
            )}
            {error && 'Show error'}
          </div>
        </div>
      </section>
    </>
  );
};

export default Users;
