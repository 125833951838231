import firebase from 'firebase.js';
import React, { useState, useEffect, useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { GridContextProvider, GridDropZone, GridItem } from 'react-grid-dnd';
import './menu.css';
import { useFormatMessage } from '../../hooks';
import FoodForm from 'components/Menu/foodform';
import Foods from 'components/Menu/foods';
import { addMenuCategory } from '../../state/actions/restaurant';
import { v4 } from 'uuid';
import FoodItem from 'components/Menu/foodItem';

export const Menu = (props) => {
  const [menu, setMenu] = useState({ Foods: [] });
  const [selectedCategoryName, setSelectedCategoryName] = useState();
  const [categoryInputValue, setCategoryInputValue] = useState('');
  const [boxesPerRow, setBoxesPerRow] = useState(1);
  const [showFoodItemForm, setShowFoodItemForm] = useState(false);
  const [selectedFoodItemId, setSelectedFoodItemId] = useState('');
  const [foodsListIndex, setFoodsListIndex] = useState(0);
  const dropzoneLeft = useRef(null);
  const menuItems = useFormatMessage('Menu.foodItems');
  const categoriesLabel = useFormatMessage('Menu.categories');
  const inputPlaceholder = useFormatMessage('Menu.inputPlaceholder');
  const menuLabel = useFormatMessage('Menu.menu');
  const foodItemsInCategory = useFormatMessage('Menu.foodItemsInCategory');

  const save = useFormatMessage('Menu.save');

  const onFoodItemDelete = (id) => {
    setSelectedFoodItemId('');

    let newMenu = { ...menu };

    newMenu.Foods = newMenu.Foods.filter((el) => el.id !== id);
    newMenu.Categories.forEach((category) => {
      category.Foods = category.Foods.filter((el) => el.id !== id);
    });

    setMenu(newMenu);

    props.addMenuCategory({
      restaurantId: props.restaurant.id,
      menuDoc: newMenu,
    });
    setShowFoodItemForm(!showFoodItemForm);
  };

  const onFoodFormSubmit = (data) => {
    let newMenu = { ...menu };
    const foodFormCategories = [...data.categories.map((el) => el.name)];
    delete data.categories;

    if (data.Selections) {
      data.Selections.forEach((el) => {
        if (el.IsRequired === undefined) {
          el.IsRequired = false;
        }
      });
    }

    data.Ingredients = data.Ingredients.split(',');

    data.Categories = [...new Set(data.foodAMPM.concat(data.foodTypes))];
    delete data.foodAMPM;
    delete data.foodTypes;

    if (data.id === undefined) {
      data.id = v4();
      newMenu.Foods.push(data);

      newMenu.Categories.forEach((category) => {
        if (foodFormCategories.includes(category.Name)) {
          category.Foods.push(data);
        }
      });
    } else {
      let oldFoodItemIndex = newMenu.Foods.findIndex((el) => el.id === data.id);

      newMenu.Foods.splice(oldFoodItemIndex, 1, data);

      newMenu.Categories.forEach((category) => {
        let index = category.Foods.findIndex((el) => el.id === data.id);
        if (index > -1) {
          if (foodFormCategories.includes(category.Name)) {
            category.Foods[index] = data;
          } else {
            category.Foods.splice(index, 1);
          }
        } else {
          if (foodFormCategories.includes(category.Name)) {
            category.Foods.push(data);
          }
        }
      });
    }

    setMenu(newMenu);

    props.addMenuCategory({
      restaurantId: props.restaurant.id,
      menuDoc: newMenu,
    });
    setShowFoodItemForm(!showFoodItemForm);
  };

  useEffect(() => {
    const fetchMenu = async () => {
      const snap = await firebase.firestore()
        .collection('menu')
        .doc(props.restaurant.id)
        .get();
      if (
        snap.exists &&
        snap.data().Categories &&
        snap.data().Categories.length > 0
      ) {
        setSelectedCategoryName(snap.data().Categories[0].Name);

        setMenu(snap.data());
      } else {
        setMenu({
          Categories: [{ Name: 'Popular', Foods: [] }],
          Foods: [],
          Promotions: [],
        });
        setSelectedCategoryName('Popular');
      }
    };

    fetchMenu();
  }, [props.restaurant]);

  const categoriesOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    let newArray = reorder(
      [...menu.Categories],
      result.source.index,
      result.destination.index
    );

    setMenu({ ...menu, Categories: newArray });
  };

  const onCategorySubmitted = (e) => {
    e.preventDefault();
    if (
      menu.Categories.filter((el) => el.Name === categoryInputValue).length ===
      0
    ) {
      setMenu({
        ...menu,
        Categories: [
          ...menu.Categories,
          { Name: categoryInputValue, Foods: [] },
        ],
      });

      setCategoryInputValue('');
    }
  };

  const handleStatusChange = (newStatus, id) => {
    const newMenu = { ...menu };
    const foodIndex = newMenu.Foods.findIndex((el) => el.id === id);
    newMenu.Foods[foodIndex].Status = newStatus;
    newMenu.Categories.forEach((category) => {
      const index = category.Foods.findIndex((el) => el.id === id);
      if (index > -1) {
        category.Foods[index].Status = newStatus;
      }
    });

    setMenu(newMenu);
    props.addMenuCategory({
      restaurantId: props.restaurant.id,
      menuDoc: newMenu,
    });
  };

  const renderFoodInSelectedCategory = () => {
    if (menu.Categories) {
      let category = menu.Categories.filter(
        (el) => el.Name === selectedCategoryName
      );
      if (category[0]) {
        return category[0].Foods.map((item, index) => (
          <GridItem key={item.Name + index}>
            <div
              className="grid-item"
              style={{
                cursor: `${props.user.role === 'Staff' ? 'default' : 'grab'}`,
              }}
            >
              <div className="grid-category-item">{item.Name}</div>
            </div>
          </GridItem>
        ));
      }
    }
  };

  const computeGridHeight = () => {
    let height = 400;
    if (menu.Categories) {
      const category = menu.Categories.filter(
        (el) => el.Name === selectedCategoryName
      );
      if (category[0]) {
        height = (category[0].Foods.length / boxesPerRow + 1) * 80;
      }
    }
    if (height < 400) {
      height = 400;
    }
    return `${height}px`;
  };

  const onCategoryDelete = (name) => {
    let newArray = [...menu.Categories];
    let categoryIndex = newArray.findIndex((el) => el.Name === name);
    newArray.splice(categoryIndex, 1);

    if (newArray.length === 0) {
      newArray = [{ Name: 'Popular', Foods: [] }];
    }

    if (selectedCategoryName === name) {
      setSelectedCategoryName(newArray[0].Name);
    }
    setMenu({
      ...menu,
      Categories: newArray,
    });
  };

  const onFoodDragEnd = (sourceId, sourceIndex, targetIndex, targetId) => {
    const category = menu.Categories.filter(
      (el) => el.Name === selectedCategoryName
    )[0];
    const categoryIndex = menu.Categories.findIndex(
      (el) => el.Name === selectedCategoryName
    );
    const categories = [...menu.Categories];
    const newFoodsArrayInCategory = [...category.Foods];
    if (targetId) {
      if (sourceId === 'left') {
        //removing item from menu category
        newFoodsArrayInCategory.splice(sourceIndex, 1);
        categories[categoryIndex].Foods = newFoodsArrayInCategory;
        setMenu({ ...menu, Categories: categories });
      } else {
        //adding item to menu category
        const foodItem = menu.Foods[sourceIndex + 5 * foodsListIndex];
        const foods = menu.Foods;
        foods.splice(sourceIndex + 5 * foodsListIndex, 1);
        newFoodsArrayInCategory.splice(targetIndex, 0, foodItem);
        categories[categoryIndex].Foods = newFoodsArrayInCategory;
        setMenu({ Foods: foods, Categories: categories });
        foods.splice(sourceIndex + 5 * foodsListIndex, 0, foodItem);
        setTimeout(() => {
          setMenu({ Foods: foods, Categories: categories });
        }, 1000);
      }
    } else {
      //reorder foods in a category
      if (sourceId === 'left') {
        const newFoodsArrayInCategory = reorder(
          [...category.Foods],
          sourceIndex,
          targetIndex
        );
        categories[categoryIndex].Foods = newFoodsArrayInCategory;
        setMenu({ ...menu, Categories: categories });
      } else {
        //reorder list of available foods
        const newArrayofFoods = reorder(
          [...menu.Foods],
          sourceIndex,
          targetIndex
        );
        setMenu({ ...menu, Foods: newArrayofFoods });
      }
    }
  };

  const getCategoriesItemStyle = (name, draggableStyle) => ({
    userSelect: 'none',
    cusor: 'pointer',
    padding: '4px 2px 4px 16px',
    margin: '8px',
    flexShrink: 0,
    borderStyle: 'solid',
    borderColor: '#CEAD29',
    borderRadius: '30px',
    fontWeight: '600',
    backgroundColor: `${name === selectedCategoryName ? '#CEAD29' : 'white'}`,
    color: `${name !== selectedCategoryName ? 'black' : 'white'}`,
    ...draggableStyle,
  });

  const getCategoriesContainerStyle = () => ({
    display: 'flex',
    padding: '8px',
    overflow: 'auto',
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  useEffect(() => {
    setBoxesPerRow((dropzoneLeft.current.clientWidth / 240).toFixed(0));

    const resizeGrid = () => {
      setBoxesPerRow((dropzoneLeft.current.clientWidth / 240).toFixed(0));
    };
    window.addEventListener('resize', resizeGrid);

    return () => {
      window.removeEventListener('resize', resizeGrid);
    };
  });

  return (
    <>
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <h1
              className="title has-text-centered pl-3"
              style={{ marginTop: '30px' }}
            >
              {menuLabel}
            </h1>
          </div>
        </div>
      </nav>
      <section className="section is-main-section">
        <GridContextProvider onChange={onFoodDragEnd}>
          <div className="columns">
            <div className="column is-two-thirds">
              <div className="container">
                <h1 className="title">{categoriesLabel}</h1>
                <div
                  style={{
                    borderStyle: 'solid',
                    borderRadius: '30px',
                    padding: '32px',
                    border: '1px solid black',
                  }}
                >
                  <DragDropContext onDragEnd={categoriesOnDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          style={getCategoriesContainerStyle()}
                          {...provided.droppableProps}
                        >
                          {menu.Categories &&
                            menu.Categories.map((item, index) => (
                              <Draggable
                                key={item.Name}
                                draggableId={item.Name}
                                index={index}
                                isDragDisabled={props.user.role === 'Staff'}
                              >
                                {(provided) => (
                                  <div
                                    id={item.Name}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getCategoriesItemStyle(
                                      item.Name,
                                      provided.draggableProps.style
                                    )}
                                    onClick={(e) =>
                                      e.target ===
                                      document.getElementById(`${item.Name}`)
                                        ? setSelectedCategoryName(item.Name)
                                        : null
                                    }
                                  >
                                    {item.Name}{' '}
                                    <a
                                      disabled={props.user.role === 'Staff'}
                                      style={{
                                        backgroundColor: 'transparent',

                                        color: `${
                                          item.Name !== selectedCategoryName
                                            ? 'black'
                                            : 'white'
                                        }`,
                                      }}
                                      onClick={(e) =>
                                        onCategoryDelete(item.Name)
                                      }
                                    >
                                      <span className="icon">
                                        <i
                                          className="mdi mdi-close"
                                          style={{ fontSize: 20 }}
                                        />
                                      </span>
                                    </a>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <form onSubmit={onCategorySubmitted}>
                    <input
                      className="input is-rounded"
                      type="text"
                      placeholder={inputPlaceholder}
                      value={categoryInputValue}
                      onChange={(e) => setCategoryInputValue(e.target.value)}
                      disabled={props.user.role === 'Staff'}
                    />
                  </form>
                </div>
              </div>
              <div
                className="container is-hidden-mobile"
                style={{ paddingTop: '20px' }}
              >
                <h1 className="title">
                  {foodItemsInCategory} - {selectedCategoryName}
                </h1>
                <div className="grid-container" ref={dropzoneLeft}>
                  <GridDropZone
                    className="dropzone left"
                    id="left"
                    boxesPerRow={boxesPerRow}
                    rowHeight={80}
                    style={{ height: computeGridHeight() }}
                    disableDrag={props.user.role === 'Staff'}
                  >
                    {renderFoodInSelectedCategory()}
                    {props.user.role !== 'Staff' && (
                      <div className="grid-footer" style={{ margin: '8px' }}>
                        <button
                          className="button"
                          onClick={() =>
                            props.addMenuCategory({
                              restaurantId: props.restaurant.id,
                              menuDoc: menu,
                            })
                          }
                        >
                          {save}
                        </button>
                      </div>
                    )}
                  </GridDropZone>
                </div>
              </div>
              <div
                className="container is-hidden-tablet"
                style={{ paddingTop: '20px' }}
              >
                <h1 className="title">
                  {menuItems}{' '}
                  <div className="btn-holder top" style={{ float: 'right' }}>
                    <div
                      className="button foodclass"
                      onClick={
                        props.user.role === 'Staff'
                          ? null
                          : () => {
                              setSelectedFoodItemId('');
                              setShowFoodItemForm(!showFoodItemForm);
                            }
                      }
                      style={{ borderRadius: '50%', border: '1px solid' }}
                      disabled={props.user.role === 'Staff'}
                    >
                      <span className="icon is-large">
                        <i className="mdi mdi-plus" />
                      </span>
                    </div>
                  </div>
                </h1>

                <div
                  style={{
                    borderStyle: 'solid',
                    borderRadius: '30px',
                    padding: '8px',
                    border: '1px solid black',
                  }}
                >
                  {menu.Foods.map((item) => (
                    <FoodItem
                      key={item.id}
                      item={item}
                      menu={menu}
                      handleStatusChange={handleStatusChange}
                      user={props.user}
                      setMenu={setMenu}
                      setSelectedFoodItemId={setSelectedFoodItemId}
                      setShowFoodItemForm={setShowFoodItemForm}
                      showFoodItemForm={showFoodItemForm}
                    />
                  ))}
                </div>
                {props.user.role !== 'Staff' && (
                  <div className="has-text-centered" style={{ margin: '8px' }}>
                    <button
                      className="button"
                      onClick={() =>
                        props.addMenuCategory({
                          restaurantId: props.restaurant.id,
                          menuDoc: menu,
                        })
                      }
                    >
                      {save}
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="is-hidden-mobile column is-one-third">
              <Foods
                setSelectedFoodItemId={setSelectedFoodItemId}
                setShowFoodItemForm={setShowFoodItemForm}
                showFoodItemForm={showFoodItemForm}
                foodsListIndex={foodsListIndex}
                setFoodsListIndex={setFoodsListIndex}
                user={props.user}
                menu={menu}
                gridId="right"
                handleStatusChange={handleStatusChange}
              />
            </div>
          </div>
        </GridContextProvider>
      </section>

      <FoodForm
        defaultValues={
          selectedFoodItemId !== ''
            ? menu.Foods.filter((el) => el.id === selectedFoodItemId)[0]
            : {}
        }
        onFoodItemDelete={onFoodItemDelete}
        onSubmit={onFoodFormSubmit}
        isActive={showFoodItemForm}
        menu={menu}
        closeModal={() => setShowFoodItemForm(!showFoodItemForm)}
        title={useFormatMessage('Menu.addFoodItem')}
      />
    </>
  );
};

const mapStateToProps = ({ auth, restaurants }) => ({
  user: auth.userData,
  restaurant:
    auth.userData.isAdmin !== null
      ? auth.userData.isAdmin
        ? restaurants.data[auth.userData.selectedRestaurant]
        : auth.userData.restaurants[auth.userData.selectedRestaurant]
      : {},
});

const mapDispatchToProps = {
  addMenuCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
