import React, { useEffect, useState } from 'react';
import { Chart, Line, Axis, Tooltip } from 'bizcharts';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
var momentDurationFormatSetup = require('moment-duration-format');

momentDurationFormatSetup(moment);
const AverageTime = (props) => {
  const [data, setData] = useState([]);
  const [dateRange, setDateRange] = useState('Day');
  const day = useFormatMessage('Analytics.day');
  const month = useFormatMessage('Analytics.month');
  const week = useFormatMessage('Analytics.week');
  const time = useFormatMessage('Analytics.time');
  const title = useFormatMessage('Analytics.averageOrderTime');

  const processData = (rawData) => {
    let processedData = [];
    let docs;

    rawData.sort((a, b) => {
      return a.id - b.id;
    });

    switch (dateRange) {
      case 'Day':
        var startOfWeek = moment().startOf('isoWeek').toDate();
        var endOfWeek = moment().endOf('isoWeek').toDate();

        docs = rawData.filter(
          (el) => el.id >= startOfWeek && el.id <= endOfWeek
        );

        docs.forEach((doc) => {
          processedData.push({
            Day: parseInt(doc.id),
            Average: doc.data().total_time / doc.data().orders,
          });
        });

        break;
      case 'Week':
        var startOfMonth = moment().utc().startOf('month').toDate();
        var endOfMonth = moment().utc().endOf('month').toDate();

        docs = rawData.filter(
          (el) => el.id >= startOfMonth && el.id < endOfMonth
        );

        docs.forEach((doc) => {
          let date = moment
            .unix(doc.id / 1000)
            .utc()
            .startOf('isoWeek')
            .valueOf();

          let index = processedData.findIndex((el) => el.Day === date);
          if (index === -1) {
            processedData.push({
              Day: date,
              Time: doc.data().total_time,
              Orders: doc.data().orders,
            });
          } else {
            processedData[index].Time += doc.data().total_time;
            processedData[index].Orders += doc.data().orders;
          }
        });

        processedData.forEach((el) => {
          el.Average = parseInt((el.Time / el.Orders).toFixed(0));
        });

        break;

      case 'Month':
        rawData.forEach((doc) => {
          let date = moment
            .unix(doc.id / 1000)
            .utc()
            .startOf('month')
            .valueOf();

          let index = processedData.findIndex((el) => el.Day === date);
          if (index === -1) {
            processedData.push({
              Day: date,
              Time: doc.data().total_time,
              Orders: doc.data().orders,
            });
          } else {
            processedData[index].Time += doc.data().total_time;
            processedData[index].Orders += doc.data().orders;
          }
        });

        processedData.forEach((el) => {
          el.Average = parseInt((el.Time / el.Orders).toFixed(0));
        });

        break;
    }

    setData(processedData);
  };

  useEffect(() => {
    processData(props.data);
  }, [props.data, dateRange]);

  const dateRangeStyle = (value) => ({
    color: value === dateRange ? '#CEAD29' : 'black',
  });
  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">{title}</p>
        <div className="card-header-icon field is-grouped is-grouped-right">
          <p className="control">
            <a
              style={dateRangeStyle('Day')}
              onClick={() => setDateRange('Day')}
            >
              {day}
            </a>
          </p>
          <p className="control">
            <a
              style={dateRangeStyle('Week')}
              onClick={() => setDateRange('Week')}
            >
              {week}
            </a>
          </p>
          <p className="control">
            <a
              style={dateRangeStyle('Month')}
              onClick={() => setDateRange('Month')}
            >
              {month}
            </a>
          </p>
        </div>
      </header>
      <div className="card-content">
        <Chart
          padding={[10, 20, 50, 40]}
          autoFit
          height={250}
          data={data}
          scale={{
            Day: {
              ticks: data.map((el) => el.Day),
            },
          }}
        >
          <Axis
            name="Day"
            visible={true}
            label={{
              formatter(text) {
                if (dateRange === 'Day' || dateRange === 'Week') {
                  return moment
                    .unix(text / 1000)
                    .utc()
                    .format('DD/MM');
                } else {
                  return moment
                    .unix(text / 1000)
                    .utc()
                    .format('MMM');
                }
              },
            }}
          />
          <Axis
            name="Average"
            visible={true}
            title
            label={{
              formatter(text) {
                return moment.duration(text, 'milliseconds').format('mm:ss');
              },
            }}
          />
          <Tooltip>
            {(title, items) => {
              return (
                <div style={{ padding: '8px' }}>
                  <span
                    style={{
                      backgroundColor: '#CEAD29',
                      height: '8px',
                      width: '8px',
                      marginRight: '10px',
                      display: 'inline-block',
                      borderRadius: '50%',
                    }}
                  />
                  {time}:{' '}
                  <span style={{ marginLeft: '10px' }}>
                    {moment
                      .duration(items[0].data.Average, 'milliseconds')
                      .format('mm:ss')}
                  </span>
                </div>
              );
            }}
          </Tooltip>
          <Line shape="hv" position="Day*Average" color="#CEAD29" />
        </Chart>
      </div>
    </div>
  );
};

export default AverageTime;
