import firebase from 'firebase.js';
import { useDebounce } from 'hooks';
import React, { useEffect, useState, useRef } from 'react';

const AutoComplete = (props) => {
  const [input, setInput] = useState('');
  const [matches, setMatches] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [handled, setHandled] = useState(false);
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setMatches([]);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const handleKeyPress = async (event) => {
    switch (event.which) {
      case 13: // Enter key
        if (matches.length) {
          handleSelection(null, matches[activeIndex]);
        }
        break;
      case 38: // Up arrow
        setActiveIndex(activeIndex >= 1 ? activeIndex - 1 : 0);

        break;
      case 40: // Down arrow
        setActiveIndex(
          activeIndex < matches.length - 1
            ? activeIndex + 1
            : matches.length - 1
        );

        break;
      default:
        break;
    }
  };

  const handleSelection = async (e, match) => {
    setMatches([]);
    if (e) {
      e.preventDefault();
    }
    setHandled(true);

    setInput(match.name);

    let restaurant = await fetchRestaurant(match.uid);
    if (props.clickCallback) {
      props.clickCallback({ ...restaurant.data(), id: restaurant.id });
    }
  };

  const fetchRestaurant = async (id) => {
    return await firebase.firestore().collection('restaurants').doc(id).get();
  };

  const debouncedInput = useDebounce(input, 300);

  useEffect(() => {
    if (debouncedInput !== '' && !handled) {
      firebase.firestore()
        .collection('search')
        .where('nameArray', 'array-contains', debouncedInput.toLowerCase())
        .get()
        .then((res) => {
          if (!res.empty) {
            setMatches(res.docs.map((e) => e.data()));
          }
        });
    } else {
      setMatches([]);
    }
    setHandled(false);
  }, [debouncedInput]);

  useEffect(() => {
    if (props.defaultValue) {
      setHandled(true);
      setInput(props.defaultValue);
    }
  }, [props.defaultValue]);

  return (
    <div ref={ref} className="field" style={{ width: `${props.size}%` }}>
      {props.label && <label className="label">{props.label}</label>}
      <div className="control is-expanded">
        <div
          style={{ width: '100%' }}
          className={`dropdown ${matches.length > 0 ? 'is-active' : ''}`}
        >
          <div className="dropdown-trigger" style={{ width: '100%' }}>
            <input
              type="text"
              className={`input ${
                props.isRounded === undefined ? 'is-rounded' : ''
              }`}
              name={props.name}
              autoComplete="off"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyPress}
              placeholder={props.placeholder}
            />
          </div>
          <div className="dropdown-menu">
            {matches.length > 0 && (
              <div className="dropdown-content">
                {matches.map((match, index) => (
                  <a
                    className={`dropdown-item ${
                      index === activeIndex ? 'is-active' : ''
                    }`}
                    href="/"
                    key={match.uid}
                    onClick={(event) => handleSelection(event, match)}
                  >
                    {match.name}
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoComplete;
