import React from 'react';
import { useFormatMessage } from 'hooks';
import { GridDropZone, GridItem } from 'react-grid-dnd';
import FoodItem from './foodItem';
const Foods = (props) => {
  const menuItems = useFormatMessage('Menu.foodItems');
  const justDnd = useFormatMessage('Menu.justDnd');
  const renderFoodItems = () => {
    if (props.menu.Foods) {
      return props.menu.Foods.slice(
        props.foodsListIndex * 5,
        props.foodsListIndex * 5 + 5
      ).map((item) => (
        <GridItem key={item.id}>
          <FoodItem
            item={item}
            handleStatusChange={props.handleStatusChange}
            user={props.user}
            setSelectedFoodItemId={props.setSelectedFoodItemId}
            setShowFoodItemForm={props.setShowFoodItemForm}
            showFoodItemForm={props.showFoodItemForm}
          />
        </GridItem>
      ));
    }
  };

  return (
    <>
      <h1 className="title">
        {menuItems}
        <span className="title-span">{justDnd}</span>
        <div className="btn-holder top" style={{ float: 'right' }}>
          <div
            className="button foodclass"
            onClick={
              props.user.role === 'Staff'
                ? null
                : () => {
                    props.setSelectedFoodItemId('');
                    props.setShowFoodItemForm(!props.showFoodItemForm);
                  }
            }
            style={{ borderRadius: '50%', border: '1px solid' }}
            disabled={props.user.role === 'Staff'}
          >
            <span className="icon is-large">
              <i className="mdi mdi-plus" />
            </span>
          </div>
        </div>
      </h1>
      <div className="grid-container">
        <GridDropZone
          className="dropzone right"
          id={props.gridId}
          boxesPerRow={1}
          rowHeight={130}
          disableDrag={props.user.role === 'Staff'}
        >
          {renderFoodItems()}
          <div className="grid-footer">
            <a
              className="pagination-previous"
              disabled={props.foodsListIndex === 0}
              onClick={() => props.setFoodsListIndex(props.foodsListIndex - 1)}
            >
              {useFormatMessage('Orders.previousPage')}
            </a>

            <a
              className="pagination-next"
              disabled={
                props.menu.Foods &&
                (props.menu.Foods.length <= 5 ||
                  props.menu.Foods.slice(
                    props.foodsListIndex * 5,
                    props.foodsListIndex * 5 + 5
                  ).length < 5)
              }
              onClick={() => props.setFoodsListIndex(props.foodsListIndex + 1)}
            >
              {useFormatMessage('Orders.nextPage')}
            </a>
          </div>
        </GridDropZone>
      </div>
    </>
  );
};

export default Foods;
