import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';

import { useChangeHandler, useFormatMessage } from 'hooks';
import { resetPassword, authCleanUp } from 'state/actions/auth';
import paths from 'pages/Router/paths';
import classes from './ResetPassword.module.scss';

const ResetPassword = (props) => {
  const { loading, error, restoredPassword, isAuth } = useSelector(
    (state) => ({
      loading: state.auth.loading,
      error: state.auth.error,
      restoredPassword: state.auth.restoredPassword,
      isAuth: !!state.auth.userData.userId,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [resetPasswordData, setResetPasswordData] = useState('');

  const onChangeHandler = useChangeHandler(setResetPasswordData);

  useEffect(() => {
    document.documentElement.classList.remove(
      'has-aside-left',
      'has-navbar-fixed-top'
    );
    return () => {
      document.documentElement.classList.add(
        'has-aside-left',
        'has-navbar-fixed-top'
      );
      dispatch(authCleanUp());
    };
  }, [dispatch]);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(resetPassword(resetPasswordData.email));
  };

  const modifierLoading = loading && 'is-loading';

  const redirect = isAuth && <Redirect to={paths.ROOT} />;

  const recoverEmailMessage = useFormatMessage('ResetPassword.recoverEmail', {
    mail: resetPasswordData.email,
  });
  const emailMessage = useFormatMessage('ResetPassword.email');
  const emailRegistrationMessage = useFormatMessage(
    'ResetPassword.emailRegistration'
  );
  const resetLinkMessage = useFormatMessage('ResetPassword.resetLink');
  const backMessage = useFormatMessage('ResetPassword.back');

  return (
    <section className="section hero is-error-section">
      {redirect}
      <div className="hero-body">
        <div className="container">
          {restoredPassword ? (
            <p className={classes['sub-title']}>{recoverEmailMessage}</p>
          ) : (
            <form onSubmit={onSubmitHandler}>
              <div className="field">
                <label className="label">{emailMessage}</label>
                <div className="control">
                  <input
                    type="email"
                    className="input"
                    required
                    value={resetPasswordData.email}
                    name="email"
                    onChange={onChangeHandler}
                  />
                </div>
                <p className="help">{emailRegistrationMessage}</p>
              </div>

              <div className="field is-grouped is-grouped-right">
                <div className="control">
                  <button
                    className={`button is-warning ${modifierLoading}`}
                    type="submit"
                  >
                    {resetLinkMessage}
                  </button>
                </div>
                <div className="control">
                  <button
                    className="button is-outlined"
                    onClick={props.closeModal}
                  >
                    {backMessage}
                  </button>
                </div>
              </div>
              {error && (
                <p className={`has-text-danger ${classes.errorMessage}`}>
                  {error}
                </p>
              )}
            </form>
          )}
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
