export default {
  ROOT: '/',
  LOGIN: '/login',
  USERS: '/users',
  MENU: '/menu',
  ADD_USER: '/users/new',
  MODIFY_USER: '/users/:id',
  PROFILE: '/profile',
  RESET_PASSWORD: '/recover-password',
  PENDING_ORDERS: '/pending-orders',
  COMPLETED_ORDERS: '/completed-orders',
  DECLINED_ORDERS: '/declined-orders',
  RESTAURANT: '/restaurant',
  SETTINGS: '/settings',
  PROMOTIONS: '/promotions',
  HEYDOOR_PAYMENTS: '/heydoor-payments',
  ANALYTICS: '/analytics',
  UNAUTHORIZED: '/unauthorized',
  REWARDS: '/rewards',
  LOYALTY: '/loyalty',
  ACTION: '/action',
  REDEEM: '/redeem',
  REDEEMLIST: '/redeemList',
};
