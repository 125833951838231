import firebase from 'firebase.js';
import React, { useState, useEffect } from 'react';
import logoDoorBlack from '../../assets/heydoor_door_black.png';
import logoBlack from '../../assets/heydoor_word_black.png';
import logoGold from '../../assets/heydoor_word_gold_black.png';
import './ordercard.scss';
import { useFormatMessage } from 'hooks';
import { CustomModal } from '../Modal';
import FoodReadyTimePicker from './food_ready_time_picker';
import ConfirmationModal from 'components/ConfirmationModal';
import moment from 'moment';

const OrderCard = (props) => {
  const [customer, setCustomer] = useState({});
  useEffect(() => {
    firebase.firestore()
      .collection('customers')
      .doc(props.order.customer_id)
      .get()
      .then((value) => {
        setCustomer(value.data());
      });
  }, [props.order.customer_id]);

  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState({
    showing: false,
    action: '',
  });

  const accept = useFormatMessage('Orders.accept');
  const decline = useFormatMessage('Orders.decline');

  const orderFor = useFormatMessage('Orders.orderFor');
  const customerHere = useFormatMessage('Orders.customerHere');
  const order = useFormatMessage('Orders.order');
  const delayFoodReadyTime = useFormatMessage('Orders.delayFoodReadyTime');
  const completeOrder = useFormatMessage('Orders.completeOrder');
  const confirmationTitle = useFormatMessage('Orders.confirmationTitle');
  const acceptConfirmationMessage = useFormatMessage(
    'Orders.acceptConfirmationMessage'
  );
  const declineConfirmationMessage = useFormatMessage(
    'Orders.declineConfirmationMessage'
  );

  const updateOrderStatus = (orderStatus) => {
    firebase.firestore()
      .collection('orders')
      .doc(props.order.id)
      .update({ current_status: orderStatus });
  };

  useEffect(() => {
    setTime(calculateTimeLeft());
    const interval = setInterval(() => {
      if (props.order.current_status !== 'New') {
        setTime(calculateTimeLeft());
      }
    }, 60000);
    return () => clearInterval(interval);
  }, [props.order.food_ready_time_minutes, props.order.accepted_at_posix]);

  const calculateTimeLeft = () => {
    if (props.order.accepted_at_posix) {
      let dueTime = moment(props.order.accepted_at_posix);
      dueTime.add(props.order.food_ready_time_minutes, 'm');
      let diff = dueTime.diff(moment(), 'm');
      if (diff > 0) {
        return diff + "'";
      }
    }

    return "0'";
  };

  const [time, setTime] = useState(calculateTimeLeft());

  const renderedFoodItems = () => {
    return props.order.food_items.map((foodItem, index) => {
      return (
        <div key={index} style={{ paddingBottom: '4px' }}>
          <p>
            1x {foodItem.Name}
            <span style={{ float: 'right' }}>
              ${foodItem.Price && foodItem.Price.toFixed(2)}
            </span>
          </p>

          <p>
            {foodItem.Selections.length > 0 &&
              foodItem.Selections.map((el) => el.Name).join(', ')}
          </p>
        </div>
      );
    });
  };

  const renderName = () => {
    let name = '';

    if (customer) {
      if (customer.firstName) {
        name += customer.firstName + ' ';
      }
      if (customer.lastName) {
        name += customer.lastName;
      }
    }
    if (name === '') {
      name = 'Name Unknown';
    }
    return name;
  };

  const renderDate = () => {
    const d = new Date(props.order.created_at_posix);
    const year = d.getFullYear(); // 2019
    const date = d.getDate();
    const month = d.getMonth() + 1;
    const hrs = d.getHours();
    const mins = d.getMinutes();
    const formatted = `${date}/${month}/${year} ${hrs}:${mins}`;
    return formatted;
  };

  const renderFooter = () => {
    if (props.order.current_status === 'New') {
      return (
        <div>
          <button
            className="button is-danger"
            onClick={() =>
              setShowConfirmation({ showing: true, action: 'decline' })
            }
          >
            {decline}
          </button>
          <button
            className="button is-success"
            onClick={() =>
              setShowConfirmation({ showing: true, action: 'accept' })
            }
          >
            {accept}
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <button
            className="button"
            disabled={
              !props.order.order_statuses.some(r => ['Preparing', 'FoodIsReady'].includes(r)) ||
              props.order.current_status === 'Completed' ||
              props.order.current_status === 'CancelledByVendor'
            }
            onClick={() => updateOrderStatus('Completed')}
          >
            {completeOrder}
          </button>
        </div>
      );
    }
  };
  return (
    <div
      className="order-card"
      style={{
        position: 'relative',
        boxShadow: `1px 7px 10px ${props.order.current_status === 'Preparing'
          ? '#CEAD29'
          : props.order.current_status === 'FoodIsReady'
            ? 'green'
            : '#bbb'
          }`,
      }}
    >
      <div className="order-card-header">
        {props.order.current_status === 'Preparing' ||
          props.order.current_status === 'Accepted' ? (
          <div
            className="time-indicator"
            onClick={() => setShowModal(!showModal)}
          >
            <div
              style={{
                transform: 'translate(0%,20%)',
              }}
            >
              {time}
            </div>
          </div>
        ) : (
          <></>
        )}
        <p>{props.restaurant.name}</p>
      </div>
      <p className="order-customer-details">
        {orderFor}
        {renderName()}{' '} <br />
        {renderDate()}{' '} <br />
        {customer && customer.phoneNumber ? customer.phoneNumber : ''}
      </p>
      <p className="order-customer-details" style={{ color: 'red' }}>
        {props.order.current_status === 'CancelledByVendor'
          ? 'Declined Order'
          : ''}
      </p>
      <div className="order-card-content">
        <p style={{ fontSize: 25 }}>
          {order} #{props.order.id && props.order.id.substr(0, 7)}
        </p>

        <hr className="divider" />
        {renderedFoodItems()}
      </div>
      <div className="spacer"></div>
      <div className="order-card-actions">
        {props.order.current_status === 'Completed' ||
          props.order.current_status === 'CancelledByVendor' ? (
          <></>
        ) : (
          <p style={{ color: '#CEAD29', fontWeight: 'bold' }}>
            {props.order.customer_is_here && customerHere}
          </p>
        )}
        {renderFooter()}
        <img className="footer-logo" src={logoBlack} alt="heydoor Logo" />
      </div>
      <ConfirmationModal
        isActive={showConfirmation.showing}
        title={confirmationTitle}
        body={
          showConfirmation.action === 'accept'
            ? acceptConfirmationMessage
            : declineConfirmationMessage
        }
        confirmColor={
          showConfirmation.action === 'accept' ? 'is-success' : null
        }
        confirmButtonMessage={
          showConfirmation.action === 'accept' ? accept : decline
        }
        onConfirmation={() => {
          if (showConfirmation.action === 'accept') {
            updateOrderStatus('Preparing');
          } else {
            updateOrderStatus('CancelledByVendor');
          }
          setShowConfirmation({ showing: false, action: '' });
        }}
        cancelButtonMessage="Cancel"
        onCancel={() => setShowConfirmation({ showing: false, action: '' })}
      />
      <CustomModal
        isActive={showModal}
        closeModal={() => setShowModal(!showModal)}
        title={delayFoodReadyTime}
      >
        <FoodReadyTimePicker
          order={props.order}
          closeModal={() => setShowModal(!showModal)}
        />
      </CustomModal>
    </div>
  );
};

export default OrderCard;
