import React, { useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import heydoor_door_black from '../../../assets/heydoor_door_black.png';
import { availableLocales } from 'utils';
import { useFormatMessage } from 'hooks';
import { logout, updateSelectedRestaurant } from 'state/actions/auth';
import { updateActiveRestaurant } from 'state/actions/restaurant';
import paths from 'pages/Router/paths';
import defaultLogo from 'assets/user-default-log.svg';
import homeIcon from 'assets/home_icon.png';
import Link from '../Link';
import './navbar.css';
import Autocomplete from 'components/Autocomplete';
import ConfirmationModal from 'components/ConfirmationModal';

const NavBar = ({ handleMobileToggle, asideMobileActive }) => {
  const [navMobileActive, setNavMobileActive] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [buttonDown, setButtonDown] = useState('');

  const {
    userName,
    logoUrl,
    locale,
    role,
    restaurants,
    selectedRestaurant,
    isAdmin,
  } = useSelector(
    (state) => ({
      isAdmin: state.auth.userData.isAdmin,
      userName: state.auth.userData.name,
      logoUrl: state.auth.userData.logoUrl,
      locale: state.preferences.locale,
      role: state.auth.userData.role,
      restaurants: state.auth.userData.restaurants,
      selectedRestaurant: state.auth.userData.selectedRestaurant,
      restaurantList: state.restaurants.data,
    }),
    shallowEqual
  );

  const scrollRef = useRef();

  const dispatch = useDispatch();

  const onClickLogoutHandler = () => {
    dispatch(logout());
  };

  const onMobileToggleHandler = useCallback(() => {
    setNavMobileActive(!navMobileActive);
  }, [setNavMobileActive, navMobileActive]);

  // const changeLocaleHandler = (local) => {
  //   dispatch(setUserLocale(local));
  // };

  const restaurantStyle = (index) => ({
    fontWeight: index === selectedRestaurant ? '500' : 'normal',
    backgroundColor: index === selectedRestaurant ? '#4992a0' : 'white',
    color: index === selectedRestaurant ? 'white' : 'black',
    borderColor: 'black',
  });

  const selectedRestaurantHandler = (index) => {
    dispatch(updateSelectedRestaurant(index));
  };

  // const locales = availableLocales.filter((local) => local !== locale);

  const scroll = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset;
  };

  return (
    <nav id="navbar-main" className="navbar is-fixed-top">
      <div className="navbar-brand">
        <a
          className="navbar-item is-hidden-desktop jb-aside-mobile-toggle"
          onClick={handleMobileToggle}
        >
          <span className="icon">
            <i
              className={classNames(
                'mdi',
                'mdi-24px',
                { 'mdi-forwardburger': !asideMobileActive },
                { 'mdi-backburger': asideMobileActive }
              )}
            />
          </span>
        </a>
      </div>
      <div className="navbar-brand is-right">
        <a
          className="navbar-item is-hidden-desktop jb-navbar-menu-toggle"
          data-target="navbar-menu"
          onClick={onMobileToggleHandler}
        >
          <span className="icon">
            <i
              className={classNames(
                'mdi',
                { 'mdi-dots-vertical': !navMobileActive },
                { 'mdi-close': navMobileActive }
              )}
            />
          </span>
        </a>
      </div>
      <div
        className={classNames('navbar-menu', 'fadeIn', 'animated', 'faster', {
          'is-active': navMobileActive,
        })}
        id="navbar-menu"
      >
        <div className="navbar-end">
          <div className="navbar-item">
            {isAdmin && (
              <>
                <span className="icon">
                  <i className="mdi mdi-magnify" />
                </span>
                <Autocomplete
                  size={100}
                  name="restaurants"
                  clickCallback={(restaurant) => {
                    dispatch(updateActiveRestaurant(restaurant));
                  }}
                  className="column is-half"
                  placeholder="Search for restaurant"
                />
              </>
            )}
          </div>
          {role === 'Owner' && !isAdmin && (
            <div className="navbar-item">
              {restaurants.length > 1 && (
                <div
                  className="carousel-button"
                  onClick={() => scroll(-100)}
                  onMouseDown={() => setButtonDown('left')}
                  onMouseUp={() => setButtonDown('')}
                >
                  <span className="icon">
                    <i
                      className={`mdi ${buttonDown === 'left'
                        ? 'mdi-chevron-left-circle'
                        : 'mdi-chevron-left'
                        }`}
                    />
                  </span>
                </div>
              )}
              <div ref={scrollRef} className="restaurant-cards-container">
                {restaurants.map((restaurant, index) => (
                  <div key={index} style={{ paddingTop: '15px' }}>
                    <div
                      key={restaurant.name}
                      className="carousel-item"
                      style={restaurantStyle(index)}
                      onClick={() => selectedRestaurantHandler(index)}
                    >
                      {restaurant.address.suburb || restaurant.name}

                      <img src={homeIcon} className="carousel-item-icon" />
                    </div>
                  </div>
                ))}
              </div>
              {restaurants.length > 1 && (
                <div
                  className="carousel-button"
                  onClick={() => scroll(100)}
                  onMouseDown={() => setButtonDown('right')}
                  onMouseUp={() => setButtonDown('')}
                >
                  <span className="icon">
                    <i
                      className={`mdi ${buttonDown === 'right'
                        ? 'mdi-chevron-right-circle'
                        : 'mdi-chevron-right'
                        }`}
                    />
                  </span>
                </div>
              )}
            </div>
          )}
          {/* <div className="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
            <a className="navbar-link is-arrowless">
              <div className="is-user-avatar">
                <span>
                  <img id={locale} src={flags[locale]} alt={`${locale} flag`} />
                </span>
              </div>
              <span className="icon">
                <i className="mdi mdi-chevron-down" />
              </span>
            </a>
            <div className="navbar-dropdown">
              {locales.map((local, index) => (
                <a
                  key={index}
                  onClick={() => changeLocaleHandler(local)}
                  className="navbar-item"
                  id={local}
                  key={local}
                >
                  <div className="is-user-avatar">
                    <span>
                      <img src={flags[local]} alt={`${local} flag`} />
                    </span>
                  </div>
                </a>
              ))}
            </div>
          </div> */}
          <div className="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
            <a className="navbar-link is-arrowless">
              <div className="is-user-avatar">
                <img
                  src={logoUrl || defaultLogo}
                  alt="User profile"
                  style={{ maxHeight: '1.4rem' }}
                />
              </div>
              <div className="is-user-name">
                <span>{userName}</span>
              </div>
              <span className="icon">
                <i className="mdi mdi-chevron-down" />
              </span>
            </a>
            <div className="navbar-dropdown">
              <Link to={paths.PROFILE} onClick={onMobileToggleHandler}>
                <span className="icon">
                  <i className="mdi mdi-settings" />
                </span>
                <span>{useFormatMessage('Aside.settings')}</span>
              </Link>
              <hr className="navbar-divider" />
              <a
                className="navbar-item"
                id="logout"
                onClick={() => {
                  setShowLogout(true);
                }}
              >
                <span
                  className="icon"
                  style={{ width: '24px', marginRight: '0.75rem' }}
                >
                  <img
                    style={{
                      width: '16px',
                      height: '20px',
                      display: 'block',
                    }}
                    src={heydoor_door_black}
                  />
                </span>
                <span>{useFormatMessage('NavBar.logOut')}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        isActive={showLogout}
        isLoading={false}
        title={useFormatMessage('Aside.logOut')}
        body={useFormatMessage('Aside.confirmLogout')}
        confirmButtonMessage={useFormatMessage('Aside.logOut')}
        onConfirmation={onClickLogoutHandler}
        cancelButtonMessage={useFormatMessage('Aside.cancel')}
        onCancel={() => setShowLogout(false)}
      />
    </nav>
  );
};

NavBar.propTypes = {
  handleMobileToggle: PropTypes.func.isRequired,
  asideMobileActive: PropTypes.bool,
};

export default NavBar;
