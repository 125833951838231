import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useFormatMessage } from 'hooks';
import firebase from 'firebase.js';

import StackGrid from 'react-stack-grid';
import FoodPieChart from 'components/AnalyticsCharts/FoodPieChart';
import SalesAndOrders from 'components/AnalyticsCharts/SalesAndOrders';
import TimeByFoodItem from 'components/AnalyticsCharts/TimeByFoodItem';
import AverageTime from 'components/AnalyticsCharts/AverageTime';

export const Analytics = (props) => {
  const pageTitle = useFormatMessage('Analytics.analytics');
  const [data, setData] = useState([]);
  const [columnWidth, setColumnWidth] = useState(40);
  const date = new Date();

  useEffect(() => {
    const resizeColumn = () => {
      if (document.body.clientWidth > 700) {
        setColumnWidth(40);
      } else {
        setColumnWidth(100);
      }
    };
    window.addEventListener('resize', resizeColumn);

    return () => {
      window.removeEventListener('resize', resizeColumn);
    };
  });

  useEffect(() => {
    const unsubscribe = firebase.firestore()
      .collection('analytics')
      .doc(props.restaurant.id)
      .collection(date.getFullYear().toString())
      .onSnapshot(function (querySnapshot) {
        setData(querySnapshot.docs);
      });
    return () => unsubscribe();
  }, [props.restaurant]);

  return (
    <>
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <h1
              className="title has-text-centered pl-3"
              style={{ marginTop: '30px' }}
            >
              {pageTitle}
            </h1>
          </div>
        </div>
      </nav>

      <StackGrid columnWidth={`${columnWidth}%`} style={{ margin: '10px' }}>
        <SalesAndOrders data={data} />
        <AverageTime data={data} />
        <FoodPieChart data={data} />
        <TimeByFoodItem data={data} />
      </StackGrid>
    </>
  );
};

const mapStateToProps = ({ auth, restaurants }) => ({
  restaurant:
    auth.userData.isAdmin !== null
      ? auth.userData.isAdmin
        ? restaurants.data[auth.userData.selectedRestaurant]
        : auth.userData.restaurants[auth.userData.selectedRestaurant]
      : {},
});

export default connect(mapStateToProps)(Analytics);
