import React from 'react';

const CheckboxSelect = ({ data, value, onChange, error }) => {
  const change = (item) => {
    let list = [];
    if (value && value.includes(item)) {
      list = value.filter((el) => el !== item);
    } else {
      if (value) {
        list = [...value];
      }
      list.push(item);
    }
    onChange(list);
  };

  return (
    <div
      style={{
        border: `1px solid ${error === undefined ? 'black' : 'red'}`,
        borderRadius: '4px',
        height: '300px',
        overflow: 'scroll',
      }}
    >
      {data.map((el) => (
        <label key={el} className="blue-checkbox">
          <input
            type="checkbox"
            checked={(value && value.includes(el)) || false}
            onChange={(e) => change(el)}
          />
          <div style={{ display: 'flex', margin: '0.5em 1em' }}>
            <div className="checkmark" />
            <span style={{ paddingLeft: '8px' }}>{el}</span>
          </div>
        </label>
      ))}
    </div>
  );
};

export default CheckboxSelect;
