import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase.js';
import OrderCard from '../../components/Orders/ordercard';
import { useFormatMessage } from 'hooks/index';
import { CustomModal } from '../../components/Modal';
import { DatePicker } from 'antd';
import { useDebounce } from 'hooks';
const { RangePicker } = DatePicker;

const DeclinedOrders = (props) => {
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState({ previous: 0, current: 0 });
  const [selectedOrder, setSelectedOrder] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [last, setLast] = useState();
  const [search, setSearch] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);

  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    const search = async () => {
      setLoadingSearch(true);

      const snap = await firebase.firestore()
        .collection('orders')
        .limit(9)
        .where('restaurant_id', '==', props.restaurant.id)
        .where('current_status', '==', 'CancelledByVendor')
        .orderBy('id')
        .startAt(debouncedSearch)
        .endAt(debouncedSearch + '~')
        .get();

      const orders = snap.docs.map((el) => el.data());
      orders.sort((b, a) => a.created_at_posix - b.created_at_posix);
      setOrders(orders);
      setLoadingSearch(false);
    };

    if (debouncedSearch === '') {
      setPage({ previous: 0, current: 0 });
      setOrders([]);
    } else {
      search();
    }
  }, [debouncedSearch]);

  const loadDocs = async () => {
    let query = firebase.firestore()
      .collection('orders')
      .limit(10)
      .where('restaurant_id', '==', props.restaurant.id)
      .where('current_status', '==', 'CancelledByVendor')
      .orderBy('created_at_posix', 'desc');

    if (page.current > 0) {
      if (page.current > page.previous) {
        query = query.startAfter(last);
      }
    }

    if (dateRange.length > 0) {
      query = query
        .where('created_at_posix', '>=', dateRange[0].valueOf())
        .where('created_at_posix', '<=', dateRange[1].valueOf());
    }

    const snapshot = await query.get();
    if (!snapshot.empty && page.current >= page.previous) {
      const newOrders = orders.concat(
        snapshot.docs.map((e) => ({ ...e.data(), id: e.id }))
      );

      setOrders(newOrders);
      setLast(newOrders.slice(-1)[0].created_at_posix);

      if (page.current === 0 && newOrders.length > 0) {
        setSelectedOrder(newOrders[0]);
      }
    }
  };

  useEffect(() => {
    loadDocs();
    if (page.current === 0 && orders.length > 0) {
      setSelectedOrder(orders[0]);
    } else {
      setSelectedOrder({});
    }
  }, [page]);

  useEffect(() => {
    setPage({ previous: 0, current: 0 });
    setOrders([]);
  }, [props.restaurant]);

  const renderOrderTable = () => {
    let rows = orders
      .slice(page.current * 10, 10 + page.current * 10)
      .map((order) => {
        const date = new Date(order.created_at_posix);
        return (
          <tr
            key={order.id}
            onClick={() =>
              selectedOrder.id === order.id
                ? setSelectedOrder({})
                : setSelectedOrder(order)
            }
            style={{ cursor: 'pointer' }}
            className={`${order.id === selectedOrder.id ? 'is-selected' : ''}`}
          >
            <td>
              {`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`}
            </td>
            <td>Order #{order.id && order.id.substring(0, 7)}</td>
            <td>${order.final_price && order.final_price.toFixed(2)}</td>
          </tr>
        );
      });
    if (rows.length < 10) {
      rows = rows.concat(
        Array.from(Array(10 - rows.length), (_, i) => (
          <tr key={i}>
            {Array(3)
              .fill(null)
              .map((e, index) => (
                <td key={index} className="td-not-collapsible" />
              ))}
          </tr>
        ))
      );
    }
    return rows;
  };

  const renderOrderCard = () => {
    if (Object.keys(selectedOrder).length === 0) {
      return <div></div>;
    } else {
      return <OrderCard order={selectedOrder} restaurant={props.restaurant} />;
    }
  };

  return (
    <>
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <h1
              className="title has-text-centered pl-3"
              style={{ marginTop: '30px' }}
            >
              {useFormatMessage('DeclinedOrders.declinedOrders')}
            </h1>
          </div>
        </div>
      </nav>
      <section className="section">
        <div className="container">
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-evenly',
            }}
          >
            <div>
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <button
                        className="button"
                        onClick={() => setShowModal(!showModal)}
                      >
                        {useFormatMessage('Orders.dateRangeFilter')}
                      </button>
                    </div>
                  </div>
                  <div className="field has-addons">
                    <div
                      className={`control is-expanded ${
                        loadingSearch && 'is-loading'
                      }`}
                    >
                      <input
                        className="input"
                        value={search}
                        placeholder={useFormatMessage(
                          'Orders.searchByReference'
                        )}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <div className="control">
                      <button className="button" onClick={() => setSearch('')}>
                        <span className="icon">
                          <i className="mdi mdi-close" />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <table className="table is-hoverable">
                <tbody>{renderOrderTable()}</tbody>
                <tfoot>
                  <tr>
                    <td>
                      <a
                        className="pagination-previous"
                        disabled={page.current === 0}
                        onClick={() =>
                          setPage({
                            previous: page.current,
                            current: page.current - 1,
                          })
                        }
                      >
                        {useFormatMessage('Orders.previousPage')}
                      </a>
                      <a className="pagination-link" aria-label="Current Page">
                        {page.current + 1}
                      </a>
                      <a
                        className="pagination-next"
                        disabled={orders.slice(page.current * 10).length < 10}
                        onClick={() =>
                          setPage({
                            previous: page.current,
                            current: page.current + 1,
                          })
                        }
                      >
                        {useFormatMessage('Orders.nextPage')}
                      </a>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div style={{ flex: '0 1 250px', paddingTop: '40px' }}>
              {renderOrderCard()}
            </div>
          </div>
        </div>
        <CustomModal
          isActive={showModal}
          closeModal={() => setShowModal(!showModal)}
          title="Set date range"
        >
          <div className="level">
            <div className="level-item has-text-centered">
              <RangePicker value={dateRange} onChange={setDateRange} />
            </div>
          </div>
          <div className="level">
            <div className="level-item has-text-centered">
              <button
                className="button"
                onClick={() => {
                  setShowModal(!showModal);
                  setDateRange([]);
                  setOrders([]);
                  setPage({
                    previous: 0,
                    current: 0,
                  });
                }}
              >
                {useFormatMessage('Orders.clear')}
              </button>
              <div style={{ width: '20px' }}></div>
              <button
                className="button"
                onClick={() => {
                  setShowModal(!showModal);
                  setOrders([]);
                  setPage({
                    previous: 0,
                    current: 0,
                  });
                }}
              >
                {useFormatMessage('Orders.submit')}
              </button>
            </div>
          </div>
        </CustomModal>
      </section>
    </>
  );
};

const mapStateToProps = ({ auth, restaurants }) => ({
  restaurant:
    auth.userData.isAdmin !== null
      ? auth.userData.isAdmin
        ? restaurants.data[auth.userData.selectedRestaurant]
        : auth.userData.restaurants[auth.userData.selectedRestaurant]
      : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeclinedOrders);
