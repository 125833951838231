import React, { useState, useRef, useEffect } from 'react';

const Dropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const [value, setValue] = useState(props.placeholder);
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <div
      ref={ref}
      className={`dropdown ${isOpen ? 'is-active' : ''}`}
      style={{ width: '100%' }}
    >
      <div className="dropdown-trigger" style={{ width: '100%' }}>
        <button
          className={`button ${
            props.error && 'is-danger is-focused is-inverted is-outlined'
          }`}
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(!isOpen);
          }}
          style={{ width: '100%' }}
        >
          <span
            style={{
              width: `${props.width || '150px'}`,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {value}
          </span>
          <span className="icon is-small">
            <i className="mdi mdi-arrow-down" aria-hidden="true"></i>
          </span>
        </button>
      </div>
      <div
        className="dropdown-menu"
        id="dropdown-menu"
        role="menu"
        style={{ maxHeight: '12em', width: '100%' }}
      >
        <div
          className="dropdown-content"
          style={{ overflow: 'auto', maxHeight: 'inherit' }}
        >
          {props.data.map((data, index) => {
            return (
              <a
                key={index}
                className={`dropdown-item ${data === value ? 'is-active' : ''}`}
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpen(!isOpen);
                  props.onChange(data);
                }}
              >
                {data}
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
