import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError, FIREBASE_RESPONSE } from 'utils';
import firebase from 'firebase.js';

export const RESTAURANTS_FETCH_DATA_INIT = createAction(
  'RESTAURANTS_FETCH_DATA_INIT'
);
export const RESTAURANTS_FETCH_DATA_SUCCESS = createAction(
  'RESTAURANTS_FETCH_DATA_SUCCESS'
);
export const RESTAURANTS_FETCH_DATA_FAIL = createAction(
  'RESTAURANTS_FETCH_DATA_FAIL'
);
export const REST_CREATE_CATEGORY_INIT = createAction(
  'REST_CREATE_CATEGORY_INIT'
);
export const REST_CREATE_CATEGORY_FAIL = createAction(
  'REST_CREATE_CATEGORY_FAIL'
);
export const REST_CREATE_CATEGORY_SUCCESS = createAction(
  'REST_CREATE_CATEGORY_SUCCESS'
);

const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item.data(),
    };
  }, initialValue);
};

export const addMenuCategory = ({ restaurantId, menuDoc }) => {
  return async (dispatch, getState) => {
    dispatch(REST_CREATE_CATEGORY_INIT());
    const { locale } = getState().preferences;

    let menuCategories = [];
    menuDoc.Categories.forEach((category) => {
      category.Foods.forEach((food) => {
        if (food.Categories) {
          menuCategories = menuCategories.concat(food.Categories);
        }
      });
    });

    const restaurant = await firebase
      .firestore()
      .collection('restaurants')
      .doc(restaurantId)
      .get();

    let categories = [...restaurant.data().categories];
    categories = [...new Set(categories.concat(menuCategories))];

    try {
      await restaurant.ref.update({ categories: categories });
      await firebase
        .firestore()
        .collection('menu')
        .doc(restaurantId)
        .set(menuDoc, { merge: true });
        
    } catch (error) {
      console.log(error)
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        REST_CREATE_CATEGORY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Menu updated successfully');
    return dispatch(REST_CREATE_CATEGORY_SUCCESS({}));
  };
};

export const updateActiveRestaurant = (restaurant) => {
  return (dispatch) => {
    return dispatch(
      RESTAURANTS_FETCH_DATA_SUCCESS({
        restaurants: [restaurant],
      })
    );
  };
};

// export const updateRestaurantsList = (restaurantId) => {
//   return async (dispatch, getState) => {
//     const restaurantsList = getState().restaurants.data;
//     // dispatch(RESTAURANTS_FETCH_DATA_INIT());
//     try {
//       let doc = await firebase
//         .firestore()
//         .collection('restaurants')
//         .doc(restaurantId)
//         .get();

//       let index = restaurantsList.findIndex((el) => el.id === restaurantId);

//       if (index === -1) {
//         restaurantsList.push(doc.data());
//       } else {
//         restaurantsList.splice(index, 1, doc.data());
//       }
//     } catch (error) {
//       toastr.error('', error);
//       return dispatch(RESTAURANTS_FETCH_DATA_FAIL({ error }));
//     }

//     return dispatch(
//       RESTAURANTS_FETCH_DATA_SUCCESS({
//         restaurants: restaurantsList,
//       })
//     );
//   };
// };

// export const fetchRestaurants = () => {
//   return async (dispatch, getState) => {
//     dispatch(RESTAURANTS_FETCH_DATA_INIT());

//     const { id } = getState().auth.userData;

//     let restaurants = {};

//     try {
//       let restaurantsStr = await firebase
//         .firestore()
//         .collection('restaurants')
//         .get();
//       //let tmp = userStr.docs.map(doc => {doc.data()});
//       restaurants = convertArrayToObject(restaurantsStr.docs, 'id');
//       // users = (
//       //   await firebase
//       //     .database()
//       //     .ref('users')
//       //     .once('value')
//       // ).val();
//     } catch (error) {
//       toastr.error('', error);
//       return dispatch(RESTAURANTS_FETCH_DATA_FAIL({ error }));
//     }

//     const restaurantsData = restaurants
//       ? Object.entries(restaurants).map(([key, value]) => ({
//           id: key,
//           ...value,
//         }))
//       : [];

//     return dispatch(
//       RESTAURANTS_FETCH_DATA_SUCCESS({
//         restaurants: restaurantsData,
//       })
//     );
//   };
// };
