import React, { useState, useEffect } from 'react';
import { useFormatMessage } from 'hooks';
import { Chart, Interval, Coordinate, Interaction } from 'bizcharts';
import moment from 'moment';
var momentDurationFormatSetup = require('moment-duration-format');

momentDurationFormatSetup(moment);

const TimeByFoodItem = (props) => {
  const [data, setData] = useState([]);
  const [dateRange, setDateRange] = useState('Day');

  const Day = useFormatMessage('Analytics.day');
  const month = useFormatMessage('Analytics.month');
  const week = useFormatMessage('Analytics.week');

  const title = useFormatMessage('Analytics.timeByFoodItem');

  const processData = (rawData) => {
    let processedData = [];
    let docs;

    rawData.sort((a, b) => {
      return a.id - b.id;
    });

    switch (dateRange) {
      case 'Day':
        var startOfWeek = moment().startOf('isoWeek').toDate();
        var endOfWeek = moment().endOf('isoWeek').toDate();
        docs = rawData.filter(
          (el) => el.id >= startOfWeek && el.id <= endOfWeek
        );

        docs.forEach((doc) => {
          if (doc.data().total_time_per_food) {
            Object.keys(doc.data().total_time_per_food).forEach((key) => {
              let index = processedData.findIndex((el) => el.Name === key);
              if (index === -1) {
                processedData.push({
                  Name: key,
                  Time: doc.data().total_time_per_food[key],
                  Amount: doc.data().foods[key],
                });
              } else {
                processedData[index].Time += doc.data().total_time_per_food[
                  key
                ];
                processedData[index].Amount += doc.data().foods[key];
              }
            });
          }
        });

        break;
      case 'Week':
        var startOfMonth = moment().utc().startOf('month').toDate();
        var endOfMonth = moment().utc().endOf('month').toDate();

        docs = rawData.filter(
          (el) => el.id >= startOfMonth && el.id < endOfMonth
        );

        docs.forEach((doc) => {
          if (doc.data().total_time_per_food) {
            Object.keys(doc.data().total_time_per_food).forEach((key) => {
              let index = processedData.findIndex((el) => el.Name === key);
              if (index === -1) {
                processedData.push({
                  Name: key,
                  Time: doc.data().total_time_per_food[key],
                  Amount: doc.data().foods[key],
                });
              } else {
                processedData[index].Time += doc.data().total_time_per_food[
                  key
                ];
                processedData[index].Amount += doc.data().foods[key];
              }
            });
          }
        });

        break;

      case 'Month':
        rawData.forEach((doc) => {
          if (doc.data().total_time_per_food) {
            Object.keys(doc.data().total_time_per_food).forEach((key) => {
              let index = processedData.findIndex((el) => el.Name === key);

              if (index === -1) {
                processedData.push({
                  Name: key,
                  Time: doc.data().total_time_per_food[key],
                  Amount: doc.data().foods[key],
                });
              } else {
                processedData[index].Time += doc.data().total_time_per_food[
                  key
                ];
                processedData[index].Amount += doc.data().foods[key];
              }
            });
          }
        });

        break;
    }

    for (var i = 0; i < processedData.length; i++) {
      processedData[i].Time = processedData[i].Time / processedData[i].Amount;
    }

    processedData.sort(function (b, a) {
      var textA = a.Name.toUpperCase();
      var textB = b.Name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    setData(processedData);
  };

  useEffect(() => {
    processData(props.data);
  }, [props.data, dateRange]);

  const dateRangeStyle = (value) => ({
    color: value === dateRange ? '#CEAD29' : 'black',
  });

  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">{title}</p>
        <div className="card-header-icon field is-grouped is-grouped-right">
          <p className="control">
            <a
              style={dateRangeStyle('Day')}
              onClick={() => setDateRange('Day')}
            >
              {Day}
            </a>
          </p>
          <p className="control">
            <a
              style={dateRangeStyle('Week')}
              onClick={() => setDateRange('Week')}
            >
              {week}
            </a>
          </p>
          <p className="control">
            <a
              style={dateRangeStyle('Month')}
              onClick={() => setDateRange('Month')}
            >
              {month}
            </a>
          </p>
        </div>
      </header>
      <div className="card-content">
        <Chart
          height={250}
          data={data}
          autoFit
          scale={{
            Time: {
              formatter: (v) =>
                moment.duration(v, 'milliseconds').format('mm:ss'),
            },
          }}
        >
          <Coordinate transpose />
          <Interval
            position="Name*Time"
            color="#CEAD29"
            label={[
              'Time',
              (val) => ({
                position: 'middle',
                offsetX: -15,
                content: moment.duration(val, 'milliseconds').format('mm:ss'),
                style: {
                  fill: '#fff',
                },
              }),
            ]}
          />
          <Interaction type="active-region" />
        </Chart>
      </div>
    </div>
  );
};

export default TimeByFoodItem;
