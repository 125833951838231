import { DatePicker } from 'antd';
import { useFormatMessage } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import firebase from 'firebase.js';

const RedeemForm = ({ closeModal, formData, title, user_id }) => {
  const { control, register, handleSubmit, errors, reset } = useForm();
  const errorRequired = useFormatMessage('RedeemForm.requiredField');
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    reset({
      ...formData.data,
      voucher_code: formData?.data?.voucher?.code,
      activated_at_posix: moment(),
    });
  }, [formData]);

  const onSubmit = async (data) => {
    setLoading(true);
    const reward_history = {
      ...data,
      customer_id: formData.data.customer_id,
      reward_user_id: formData.data.reward_user_id || user_id,
      reward_id: formData.data.reward_id,
    };

    await firebase.firestore().collection('rewards_history').add(reward_history);
    await firebase.firestore().runTransaction(async (transaction) => {
      const voucherDoc = await transaction.get(
        firebase.firestore()
          .collection('reward_voucher_codes')
          .doc(reward_history.customer_id)
      );
      const index = voucherDoc
        .data()
        .vouchers.findIndex((el) => el.rewardId === formData.data.reward_id);
      const newVouchers = [...voucherDoc.data().vouchers];
      newVouchers[index] = { ...newVouchers[index], used: true };
      transaction.update(voucherDoc.ref, { vouchers: newVouchers });
    });
    setLoading(false);
    closeModal();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`modal ${formData.isActive ? 'is-active' : ''}`}>
          <div className="modal-background" onClick={closeModal}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">{title}</p>
              <div className="field" style={{ marginRight: '10px' }}>
                <button
                  className={`button ${isLoading && 'is-loading'}`}
                  type="submit"
                >
                  {useFormatMessage('Redeem.submit')}
                </button>
              </div>
              <button
                className="delete"
                aria-label="close"
                onClick={closeModal}
              ></button>
            </header>
            <section className="modal-card-body">
              <div className="field is-horizontal">
                <div className="field-label is-normal ">
                  <label>{useFormatMessage('RedeemForm.code')}</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <input
                        className={`input ${
                          errors.voucher_code && 'is-danger'
                        }`}
                        name="voucher_code"
                        ref={register({ required: true })}
                      />
                      <div
                        className={`help ${
                          errors.voucher_code ? 'is-danger' : ''
                        }`}
                      >
                        {errors.voucher_code && errorRequired}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label>{useFormatMessage('RedeemForm.date')}</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <Controller
                        control={control}
                        as={DatePicker}
                        name="activated_at_posix"
                        rules={{
                          required: true,
                          setValueAs: (value) =>
                            value ? value.valueOf() : value,
                        }}
                      />
                      <div
                        className={`help ${
                          errors.activated_at_posix ? 'is-danger' : ''
                        }`}
                      >
                        {errors.activated_at_posix && errorRequired}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label>{useFormatMessage('RedeemForm.customerName')}</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <input
                        className={`input ${
                          errors.customer_name && 'is-danger'
                        }`}
                        name="customer_name"
                        ref={register({ required: true })}
                      />
                      <div
                        className={`help ${
                          errors.customer_name ? 'is-danger' : ''
                        }`}
                      >
                        {errors.customer_name && errorRequired}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label>{useFormatMessage('RedeemForm.rewardOffer')}</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <input
                        className={`input ${
                          errors.reward_offer && 'is-danger'
                        }`}
                        name="reward_offer"
                        ref={register({ required: true })}
                      />
                      <div
                        className={`help ${
                          errors.reward_offer ? 'is-danger' : ''
                        }`}
                      >
                        {errors.reward_offer && errorRequired}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className={`button is-pulled-right ${
                  isLoading && 'is-loading'
                }`}
                type="submit"
              >
                {useFormatMessage('Redeem.submit')}
              </button>
            </section>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RedeemForm;
