import React, { useState, useEffect } from 'react';
import { Chart, Interval, Tooltip, Legend, Line, Point, Axis } from 'bizcharts';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
const SalesAndOrders = (props) => {
  const [data, setData] = useState([]);
  const [dateRange, setDateRange] = useState('Day');
  const [scale, setScale] = useState({});
  const day = useFormatMessage('Analytics.day');
  const month = useFormatMessage('Analytics.month');
  const week = useFormatMessage('Analytics.week');
  const orders = useFormatMessage('Analytics.orders');
  const sales = useFormatMessage('Analytics.sales');

  const processData = (rawData) => {
    let processedData = [];
    let docs;

    rawData.sort((a, b) => {
      return a.id - b.id;
    });

    switch (dateRange) {
      case 'Day':
        var startOfWeek = moment().startOf('isoWeek').toDate();
        var endOfWeek = moment().endOf('isoWeek').toDate();

        docs = rawData.filter(
          (el) => el.id >= startOfWeek && el.id <= endOfWeek
        );

        docs.forEach((doc) => {
          processedData.push({
            Day: doc.id,
            Orders: doc.data().orders,
            Sales: doc.data().sales,
          });
        });

        break;
      case 'Week':
        var startOfMonth = moment().utc().startOf('month').toDate();
        var endOfMonth = moment().utc().endOf('month').toDate();

        docs = rawData.filter(
          (el) => el.id >= startOfMonth && el.id < endOfMonth
        );

        docs.forEach((doc) => {
          let date = moment
            .unix(doc.id / 1000)
            .utc()
            .startOf('isoWeek')
            .valueOf();

          let index = processedData.findIndex((el) => el.Day === date);
          if (index === -1) {
            processedData.push({
              Day: date,
              Orders: doc.data().orders,
              Sales: doc.data().sales,
            });
          } else {
            processedData[index].Orders += doc.data()?.orders || 0;
            processedData[index].Sales += doc.data()?.sales || 0;
          }
        });

        break;
      case 'Month':
        rawData.forEach((doc) => {
          let date = moment
            .unix(doc.id / 1000)
            .utc()
            .startOf('month')
            .valueOf();

          let index = processedData.findIndex((el) => el.Day === date);
          if (index === -1) {
            processedData.push({
              Day: date,
              Orders: doc.data().orders,
              Sales: doc.data().sales,
            });
          } else {
            processedData[index].Orders += doc.data()?.orders || 0;
            processedData[index].Sales += doc.data()?.sales || 0;
          }
        });
        break;
    }

    console.log(processedData);

    if (processedData.length > 0) {
      let maxOrders = 0;
      let maxSales = 0;
      processedData.forEach((el) => {
        if (el) {
          if (el.Orders && el.Orders > maxOrders) {
            maxOrders = el.Orders;
          }
          if (el.Sales && el.Sales > maxSales) {
            maxSales = el.Sales;
          }
        }
      });

      setScale({
        Orders: {
          min: 0,
          max: maxOrders + maxOrders / 10,
        },
        Sales: {
          min: 0,
          max: maxSales + maxSales / 10,
        },
        Day: {
          ticks: processedData.map((el) => el.Day),
        },
      });
    }

    setData(processedData);
  };

  useEffect(() => {
    processData(props.data);
  }, [props.data, dateRange]);

  const dateRangeStyle = (value) => ({
    color: value === dateRange ? '#CEAD29' : 'black',
  });

  let chartIns = null;
  const colors = ['#cead29', '#635209'];
  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title">
          {orders} & {sales}
        </p>
        <div className="card-header-icon field is-grouped is-grouped-right">
          <p className="control">
            <a
              style={dateRangeStyle('Day')}
              onClick={() => setDateRange('Day')}
            >
              {day}
            </a>
          </p>
          <p className="control">
            <a
              style={dateRangeStyle('Week')}
              onClick={() => setDateRange('Week')}
            >
              {week}
            </a>
          </p>
          <p className="control">
            <a
              style={dateRangeStyle('Month')}
              onClick={() => setDateRange('Month')}
            >
              {month}
            </a>
          </p>
        </div>
      </header>
      <div className="card-content">
        <Chart
          scale={scale}
          forceFit
          height={250}
          data={data}
          onGetG2Instance={(chart) => {
            chartIns = chart;
          }}
        >
          <Axis name="Orders" visible={false} />
          <Axis name="Sales" visible={false} />
          <Axis
            name="Day"
            visible={true}
            label={{
              formatter(text) {
                if (dateRange === 'Day' || dateRange === 'Week') {
                  return moment
                    .unix(text / 1000)
                    .utc()
                    .format('DD/MM');
                } else {
                  return moment
                    .unix(text / 1000)
                    .utc()
                    .format('MMM');
                }
              },
            }}
          />
          <Legend
            custom={true}
            allowAllCanceled={true}
            items={[
              {
                value: 'Orders',
                name: 'Orders',
                marker: {
                  symbol: 'square',
                  style: { fill: colors[0], r: 5 },
                },
              },
              {
                value: 'Sales',
                name: 'Sales',
                marker: {
                  symbol: 'hyphen',
                  style: { stroke: colors[1], r: 5, lineWidth: 3 },
                },
              },
            ]}
            onChange={(ev) => {
              const item = ev.item;
              const value = item.value;
              const checked = !item.unchecked;
              const geoms = chartIns.geometries;

              for (let i = 0; i < geoms.length; i++) {
                const geom = geoms[i];

                if (geom.getYScale().field === value) {
                  if (checked) {
                    geom.show();
                  } else {
                    geom.hide();
                  }
                }
              }
            }}
          />
          <Tooltip shared showTitle={false}>
            {(title, items) => {
              return (
                <div style={{ padding: '8px' }}>
                  <table
                    style={{
                      borderCollapse: 'separate',
                      borderSpacing: '0 8px',
                    }}
                  >
                    <tbody>
                      {items.filter((el) => el.name === 'Orders').length >
                        0 && (
                        <tr>
                          <td
                            style={{
                              backgroundColor: colors[0],
                              height: '8px',
                              width: '8px',
                              marginRight: '10px',
                              display: 'inline-block',
                              borderRadius: '50%',
                              marginTop: '3px',
                            }}
                          />
                          <td>{orders}: </td>
                          <td
                            style={{ marginLeft: '10px', textAlign: 'right' }}
                          >
                            {items[0].data.Orders}
                          </td>
                        </tr>
                      )}

                      {items.filter((el) => el.name === 'Sales').length > 0 && (
                        <tr>
                          <td
                            style={{
                              backgroundColor: colors[1],
                              height: '8px',
                              width: '8px',
                              marginRight: '10px',
                              display: 'inline-block',
                              borderRadius: '50%',
                              marginTop: '3px',
                            }}
                          />
                          <td>{sales}: </td>
                          <td
                            style={{ marginLeft: '10px', textAlign: 'right' }}
                          >
                            {'$' +
                              items[
                                items.length > 1 ? 1 : 0
                              ].data.Sales.toFixed(2)}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              );
            }}
          </Tooltip>
          <Interval position="Day*Orders" color={colors[0]} />
          <Line
            position="Day*Sales"
            color={colors[1]}
            size={3}
            shape="smooth"
          />
          <Point
            position="Day*Sales"
            color={colors[1]}
            size={3}
            shape="circle"
          />
        </Chart>
      </div>
    </div>
  );
};

export default SalesAndOrders;
