import * as React from 'react';

const CCData = props => {
  console.log(props);
  return (
    <div class="columns">
      <div class="column">
        {props.cardData.card.brand}~{props.cardData.card.last4}
      </div>
      <div class="column">
        {props.cardData.card.expMonth}/{props.cardData.card.expYear}
      </div>
      <div class="column is-1">
        <span
          onClick={() => {
            props.ccDeleteCallback();
          }}
          style={{
            cursor: 'pointer',
            float: 'right',
            borderRadius: '20px',
            padding: '0px 12px 0px 12px',
            color: 'green'
          }}
          className="icon"
        >
          <i className={`mdi mdi-delete`} />
        </span>
      </div>
    </div>
  )
};

export { CCData };

