import React from 'react';
import { useFormatMessage } from 'hooks';
const LoyaltyForm = ({
  restaurant,
  selectedAmount,
  setSelectedAmount,
  save,
  termsAndConditions,
  setTermsAndConditions,
  drinksLoyaltyEnabled,
  setDrinksLoyaltyEnabled,
  termsLoading,
  isLoading,
}) => {
  return (
    <div
      style={{
        border: '1px solid black',
        borderRadius: '20px',
        fontSize: '30px',
        padding: '16px',
        marginRight: '20px',
        marginBottom: '20px',
        width: '600px',
      }}
    >
      <h1>{restaurant.name}</h1>
      <p style={{ paddingTop: '30px' }}>
        Buy{' '}
        <span style={{ fontSize: '16px' }}>
          <span className="select">
            <select
              value={selectedAmount}
              onChange={(e) => setSelectedAmount(Number(e.target.value))}
            >
              {[4, 5, 8, 9, 10].map((e) => (
                <option key={e}>{e}</option>
              ))}
            </select>
          </span>
        </span>
        , Get the {selectedAmount + 1}th one FREE.
      </p>
      <p style={{ paddingTop: '30px' }}>{useFormatMessage('Loyalty.TnCs')}</p>
      <div className="field">
        <label className="label"></label>
        <div className={`control ${termsLoading && 'is-loading'}`}>
          <textarea
            className="textarea"
            placeholder={useFormatMessage('Loyalty.placeholderTnCs')}
            rows="10"
            value={termsAndConditions}
            onChange={(e) => setTermsAndConditions(e.target.value)}
          ></textarea>
        </div>
      </div>
      <div style={{ paddingTop: '30px' }}>
        <input
          id="loyaltyToggle"
          type="checkbox"
          name="loyaltyToggle"
          className="switch is-rounded"
          value={drinksLoyaltyEnabled}
          defaultChecked={drinksLoyaltyEnabled}
          onChange={() => setDrinksLoyaltyEnabled(!drinksLoyaltyEnabled)}
        />
        <label htmlFor="loyaltyToggle" />
        <span style={{ fontSize: '30px' }}>
          {useFormatMessage('Loyalty.enabled')}
        </span>
        <button
          className={`button is-pulled-right ${isLoading ? 'is-loading' : ''}`}
          onClick={save}
        >
          {useFormatMessage('Loyalty.save')}
        </button>
      </div>
    </div>
  );
};

export default LoyaltyForm;
