import RewardForm from 'components/Rewards/rewardsform';
import firebase from 'firebase.js';
import { useFormatMessage } from 'hooks';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

const Rewards = (props) => {
  const pageTitle = useFormatMessage('Rewards.heydoorRewards');
  const [showModal, setShowModal] = useState(false);
  const addReward = useFormatMessage('Rewards.addReward');

  const editReward = useFormatMessage('Rewards.editReward');
  const expired = useFormatMessage('Rewards.expired');
  const active = useFormatMessage('Rewards.active');
  const startingOn = useFormatMessage('Rewards.startingOn');
  const [rewards, setRewards] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const flexContainerStyle = () => ({
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 0,
    // justifyContent: 'space-evenly',
  });

  const promoStyle = () => ({
    width: '300px',
    padding: '10px',
    margin: '10px',
    fontSize: '16px',
    borderStyle: 'solid',
    borderWidth: 'thin',
    borderColor: '#cac8c8',
    borderRadius: '20px',
    overflow: 'auto',
  });

  const onSubmit = async ({ data, files }) => {
    console.log('submitting');
    setSubmitting(true);
    let timestamp = new Date().valueOf();
    let reward = {
      ...data,
      date_active_from_posix: data.date_range[0].valueOf(),
      date_active_to_posix: data.date_range[1].valueOf(),
      updated_at_posix: timestamp,
    };

    delete reward.date_range;

    if (reward.id === undefined) {
      reward.id = firebase.firestore().collection('rewards').doc().id;
      reward.created_at_posix = timestamp;
    }

    if (files.bg && files.bg.size) {
      const storageRef = firebase.storage()
        .ref()
        .child('rewards')
        .child(reward.id)
        .child('background');

      let meta = files.bg.meta;
      if (meta === undefined) {
        meta = {};
      }

      meta[props.user.id] = 'true';
      meta.name = files.bg.name;

      const task = await storageRef.put(files.bg, {
        contentType: files.bg.type,
        customMetadata: meta,
      });

      const imagePath = await task.ref.getDownloadURL();

      reward.bg_image = imagePath;
    }

    if (files.companyImage && files.companyImage.size) {
      const storageRef = firebase.storage()
        .ref()
        .child('rewards')
        .child(reward.id)
        .child('company_name');

      let meta = files.companyImage.meta;
      if (meta === undefined) {
        meta = {};
      }

      meta[props.user.id] = 'true';
      meta.name = files.companyImage.name;

      const task = await storageRef.put(files.companyImage, {
        contentType: files.companyImage.type,
        customMetadata: meta,
      });

      const imagePath = await task.ref.getDownloadURL();

      reward.company_image = imagePath;
    }

    if (files.moreDetails && files.moreDetails.data) {
      const storageRef = firebase.storage()
        .ref()
        .child('rewards')
        .child(reward.id)
        .child('more');

      let meta = files.moreDetails.meta;
      if (meta === undefined) {
        meta = {};
      }

      meta[props.user.id] = 'true';
      meta.name = files.moreDetails.name;

      var blob = new Blob([files.moreDetails.data], { type: 'text/plain' });

      const task = await storageRef.put(blob, {
        contentType: 'text/plain',
        customMetadata: meta,
      });

      const imagePath = await task.ref.getDownloadURL();

      reward.more_details = imagePath;
    }

    if (!props.user.isAdmin) {
      reward.userId = props.user.id;
    }

    firebase.firestore()
      .collection('rewards')
      .doc(reward.id)
      .set(reward, { merge: true });

    setSubmitting(false);
    setShowModal(!showModal);
  };

  useEffect(() => {
    const processSnapshot = (query) => {
      if (!query.empty) {
        let rewards = [...query.docs.map((e) => e.data())];
        rewards.sort((b, a) => a.date_active_to_posix - b.date_active_to_posix);

        setRewards(rewards);
      } else {
        setRewards([]);
      }
      setSelectedIndex(null);
    };
    let unsubscribe;
    if (props.user.isAdmin) {
      unsubscribe = firebase.firestore()
        .collection('rewards')
        .onSnapshot(processSnapshot);
    } else {
      unsubscribe = firebase.firestore()
        .collection('rewards')
        .where('userId', '==', props.user.id)
        .onSnapshot(processSnapshot);
    }

    return () => unsubscribe();
  }, [props.restaurant]);

  const renderStatus = (item) => {
    if (item.date_active_to_posix < Date.now()) {
      return expired;
    } else if (item.date_active_from_posix > Date.now()) {
      return (
        startingOn +
        ' ' +
        new Date(item.date_active_from_posix).toLocaleDateString()
      );
    } else {
      return active;
    }
  };

  return (
    <>
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <h1 className="title has-text-centered pl-3">{pageTitle}</h1>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <div style={{ float: 'right', margin: '30px' }}>
              <button
                className="button is-warning"
                onClick={() => {
                  setSelectedIndex(null);
                  setShowModal(!showModal);
                }}
              >
                {useFormatMessage('Rewards.addReward')}
              </button>
            </div>
          </div>
        </div>
      </nav>
      <section className="section" style={flexContainerStyle()}>
        {rewards.map((item, index) => {
          let exp_date = new Date(item.date_active_to_posix);
          return (
            <div key={item.id} className="card" style={promoStyle()}>
              <div>
                <p
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {item.title}
                </p>
                <p>{'Tier: ' + item.tier}</p>
                <p>{'Exp: ' + exp_date.toLocaleDateString()}</p>
                <p>
                  {renderStatus(item)}{' '}
                  <span
                    onClick={() => {
                      setSelectedIndex(index);
                      setShowModal(!showModal);
                    }}
                    style={{
                      cursor: 'pointer',
                      float: 'right',
                      borderRadius: '20px',
                      padding: '0px 12px 0px 12px',
                    }}
                    className="icon"
                  >
                    <i className={`mdi mdi-pencil`} />
                  </span>
                </p>
              </div>
            </div>
          );
        })}
        {Array(3 - (rewards.length % 3))
          .fill('')
          .map((e, index) => (
            <div key={index} style={{ flexBasis: '250px' }}></div>
          ))}
      </section>

      <RewardForm
        reward={selectedIndex !== null ? rewards[selectedIndex] : {}}
        onSubmit={onSubmit}
        closeModal={() => setShowModal(!showModal)}
        isActive={showModal}
        submitting={submitting}
        title={selectedIndex !== null ? editReward : addReward}
      />
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.userData,
});

export default connect(mapStateToProps)(Rewards);
