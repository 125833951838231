import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import RestaurantDetails from '../../components/Restaurant';
import { useFormatMessage } from 'hooks';
import Autocomplete from 'components/Autocomplete';
// import { useDispatch } from 'react-redux';
import RestaurantForm from 'components/Restaurant/restaurant_form';
import { restaurantFormSubmit } from 'components/Restaurant/restaurant_form_submit';
// import { updateRestaurantsList } from 'state/actions/restaurant';
import firebase from 'firebase.js';
import { ClipLoader } from 'react-spinners';

export const Restaurant = (props) => {
  const pageTitle = useFormatMessage('Restaurant.businessDetails');
  const addRestaurant = useFormatMessage('Restaurant.addRestaurant');

  const [searchedRestaurant, setSearchedRestaurant] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [recentlyAdded, setRecenltyAdded] = useState([]);
  const [loadingRecent, setLoadingRecent] = useState(false);

  const onRestaurantFormSubmit = async (data) => {
    setShowModal(!showModal);

    await restaurantFormSubmit(data, searchedRestaurant, selectedFile);
    // dispatch(updateRestaurantsList(restaurantId));
  };

  const onRecentlyAddedEdit = (restaurant) => {
    let newArray = [...recentlyAdded];
    let index = newArray.findIndex((el) => (el.id = restaurant.id));
    newArray[index] = restaurant;
    setRecenltyAdded(newArray);
  };

  useEffect(() => {
    let isMounted = true;
    const fetchRecentlyAdded = async () => {
      setLoadingRecent(true);
      if (props.user.isAdmin) {
        const snap = await firebase.firestore()
          .collection('restaurants')
          .orderBy('time_joined_posix', 'desc')
          .limit(10)
          .get();

        if (!snap.empty) {
          const restaurants = snap.docs.map((e) => ({
            ...e.data(),
            id: e.id,
          }));
          if (isMounted) setRecenltyAdded(restaurants);
        }
        setLoadingRecent(false);
      }
    };
    if (props.user.isAdmin) {
      fetchRecentlyAdded();
    }
    return () => { isMounted = false; };
  }, []);

  useEffect(() => {
    if (props.restaurantList.length > 0) {
      if (Object.keys(searchedRestaurant).length > 3) {
        let updatedSearchedRestaurant = props.restaurantList.find(
          (el) => el.id === searchedRestaurant.id
        );

        setSearchedRestaurant(updatedSearchedRestaurant);
      }
    }
  }, [props.restaurantList]);

  const renderSearch = () => {
    return (
      <div
        style={{
          position: 'relative',
          left: '50%',
          top: '50%',
          transform: 'translate(-25%,0%)',
        }}
      >
        <Autocomplete
          size={50}
          name="restaurants"
          data={props.restaurantList}
          clickCallback={(selection) => {
            setSearchedRestaurant(selection);
          }}
          placeholder="Search for restaurant"
        />
      </div>
    );
  };
  return (
    <>
      <nav className="level" style={{ marginTop: '30px' }}>
        <div className="level-left">
          <div className="level-item">
            <h1 className="title has-text-centered pl-3">{pageTitle}</h1>
          </div>
        </div>
        {props.user.isAdmin && (
          <div className="level-right" style={{ paddingRight: '32px' }}>
            <div className="level-item">
              <button
                className="button is-warning is-pulled-right"
                onClick={() => {
                  setSearchedRestaurant({
                    working_hours: Array(7).fill({
                      start_time: 0,
                      end_time: 0,
                    }),
                    address: {},
                    location: {},
                  });
                  setShowModal(!showModal);
                }}
              >
                {addRestaurant}
              </button>
            </div>
          </div>
        )}
      </nav>
      <section>
        {props.user.isAdmin && renderSearch()}
        {props.user.isAdmin
          ? Object.keys(searchedRestaurant).length > 3 && (
              <RestaurantDetails
                key={searchedRestaurant.name}
                restaurant={searchedRestaurant}
                user={props.user}
                onSearchedEdit={setSearchedRestaurant}
                isExpanded={true}
              />
            )
          : props.restaurants.map((restaurant, index) => (
              <RestaurantDetails
                key={restaurant.name}
                restaurant={restaurant}
                user={props.user}
                isExpanded={index === 0}
              />
            ))}
        {props.user.isAdmin &&
          (loadingRecent ? (
            <ClipLoader
              css={`
                display: block;
                margin: auto;
                margin-top: 100px;
              `}
            />
          ) : (
            recentlyAdded.map((restaurant) => (
              <RestaurantDetails
                key={restaurant.name}
                restaurant={restaurant}
                user={props.user}
                isExpanded={false}
                onSearchedEdit={onRecentlyAddedEdit}
              />
            ))
          ))}
      </section>

      <RestaurantForm
        isActive={showModal}
        title={addRestaurant}
        onSubmit={onRestaurantFormSubmit}
        setSelectedFile={setSelectedFile}
        restaurant={searchedRestaurant}
        closeModal={() => setShowModal(!showModal)}
        isAdmin={props.user.isAdmin}
      />
    </>
  );
};

const mapStateToProps = ({ auth, restaurants }) => ({
  restaurants: auth.userData.restaurants,
  user: auth.userData,
  restaurantList: restaurants.data,
});

export default connect(mapStateToProps)(Restaurant);
