import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useFormatMessage } from 'hooks';
import GlanceCard from 'components/Home/glancecard';
import firebase from 'firebase.js';
import './home.css';
import moment from 'moment';

const Home = (props) => {
  const atAGlance = useFormatMessage('Home.atAGlance');
  const [data, setData] = useState({
    salesToday: 0,
    ordersToday: 0,
    popularToday: '-',
    salesWeek: 0,
    ordersWeek: 0,
    popularWeek: '-',
  });

  useEffect(() => {
    const date = new Date();
    const processData = (week) => {
      const processedData = {};
      const todayPosix = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      )
        .valueOf()
        .toString();

      const today = week.find((el) => el.id === todayPosix);

      if (today) {
        processedData.ordersToday =
          (today.data()?.orders || 0) + (today.data()?.cancelled_orders || 0);

        processedData.salesToday = today.data().orders || 0;

        if (today.data().foods) {
          processedData.popularToday = Object.entries(
            today.data().foods
          ).reduce((prev, curr) => (curr[1] > prev[1] ? curr : prev))[0];
        } else {
          processedData.popularToday = '-';
        }
      } else {
        processedData.ordersToday = 0;
        processedData.popularToday = '-';
        processedData.salesToday = 0;
      }

      let ordersWeek = 0;
      let salesWeek = 0;
      week.forEach((doc) => {
        if (doc.data().orders) {
          ordersWeek += doc.data().orders;
          salesWeek += doc.data().orders;
        }
        if (doc.data().cancelled_orders) {
          ordersWeek += doc.data().cancelled_orders;
        }
      });

      const foods = {};
      week.forEach((doc) => {
        if (doc.data().foods) {
          Object.entries(doc.data().foods).forEach((food) => {
            if (foods[food[0]]) {
              foods[food[0]] += food[1];
            } else {
              foods[food[0]] = food[1];
            }
          });
        }
      });

      if (Object.keys(foods).length === 1) {
        processedData.popularWeek = Object.keys(foods)[0];
      } else {
        processedData.popularWeek = Object.entries(foods).reduce(
          (prev, curr) => (curr[1] > prev[1] ? curr : prev),
          [0, '']
        )[0];
      }

      setData({ ...processedData, ordersWeek, salesWeek });
    };
    const getData = async () => {
      const year = date.getFullYear();
      const snap = await firebase.firestore()
        .collection(`analytics/${props.restaurant.id}/${year}`)
        .get();

      if (!snap.empty) {
        const startOfWeek = moment().startOf('isoWeek').toDate();
        const endOfWeek = moment().endOf('isoWeek').toDate();
        const week = snap.docs.filter(
          (el) => el.id >= startOfWeek && el.id <= endOfWeek
        );
        if (week.length > 0) {
          processData(week);
        } else {
          setData({
            salesToday: 0,
            ordersToday: 0,
            popularToday: '-',
            salesWeek: 0,
            ordersWeek: 0,
            popularWeek: '-',
          });
        }
      }
    };
    if (props.restaurant) {
      getData();
    }
  }, [props.restaurant]);
  return (
    <>
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <h1
              className="title has-text-centered pl-3"
              style={{ marginTop: '30px' }}
            >
              {props.restaurant && props.restaurant.name + ' ' + atAGlance}
            </h1>
          </div>
        </div>
      </nav>

      <section className="section is-main-section">
        <div className="cards">
          <GlanceCard
            value={data.salesToday}
            label={useFormatMessage('Home.salesPerDay')}
          />
          <GlanceCard
            value={data.popularToday}
            label={useFormatMessage('Home.mostPopularDay')}
          />
          <GlanceCard
            value={data.ordersToday}
            label={useFormatMessage('Home.amountOrdersDay')}
          />
          <GlanceCard
            value={data.salesWeek}
            label={useFormatMessage('Home.salesPerWeek')}
          />
          <GlanceCard
            value={data.popularWeek}
            label={useFormatMessage('Home.mostPopularWeek')}
          />
          <GlanceCard
            value={data.ordersWeek}
            label={useFormatMessage('Home.amountOrdersWeek')}
          />
        </div>
      </section>

    </>
  );
};

const mapStateToProps = ({ auth, restaurants }) => ({
  restaurant:
    auth.userData.isAdmin !== null
      ? auth.userData.isAdmin
        ? restaurants.data[auth.userData.selectedRestaurant]
        : auth.userData.restaurants[auth.userData.selectedRestaurant]
      : {},
});

export default connect(mapStateToProps)(Home);
