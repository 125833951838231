import { createReducer } from 'redux-act';

import {
  RESTAURANTS_FETCH_DATA_INIT,
  RESTAURANTS_FETCH_DATA_SUCCESS,
  RESTAURANTS_FETCH_DATA_FAIL,
} from 'state/actions/restaurant';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false
};

export const restaurantsReducer = createReducer(
  {
    [RESTAURANTS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true
    }),
    [RESTAURANTS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.restaurants,
      loading: false,
      error: null
    }),
    [RESTAURANTS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
  },
  initialState
);
