import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { firebaseError, FIREBASE_RESPONSE } from 'utils';
import firebase from 'firebase.js';
import {auth} from 'firebase.js';
import { clearUsersDataLogout } from './users';
import { fetchRestaurants } from './restaurant';

export const AUTH_SIGN_IN_INIT = createAction('AUTH_SIGN_IN_INIT');
export const AUTH_SIGN_IN_FAIL = createAction('AUTH_SIGN_IN_FAIL');

export const AUTH_RESTORE_SESSION_INIT = createAction(
  'AUTH_RESTORE_SESSION_INIT'
);
export const AUTH_RESTORE_SESSION_SUCCESS = createAction(
  'AUTH_RESTORE_SESSION_SUCCESS'
);
export const AUTH_RESTORE_SESSION_FAIL = createAction(
  'AUTH_RESTORE_SESSION_FAIL'
);

export const AUTH_LOGOUT_INIT = createAction('AUTH_LOGOUT_INIT');
export const AUTH_LOGOUT_SUCCESS = createAction('AUTH_LOGOUT_SUCCESS');

export const AUTH_SET_PASSWORD_INIT = createAction('AUTH_SET_PASSWORD_INIT');
export const AUTH_SET_PASSWORD_SUCCESS = createAction(
  'AUTH_SET_PASSWORD_SUCCESS'
);
export const AUTH_SET_PASSWORD_FAIL = createAction('AUTH_SET_PASSWORD_FAIL');

export const AUTH_RESET_PASSWORD_INIT = createAction(
  'AUTH_RESET_PASSWORD_INIT'
);
export const AUTH_RESET_PASSWORD_SUCCESS = createAction(
  'AUTH_RESET_PASSWORD_SUCCESS'
);
export const AUTH_RESET_PASSWORD_FAIL = createAction(
  'AUTH_RESET_PASSWORD_FAIL'
);

export const AUTH_CLEAN_UP = createAction('AUTH_CLEAN_UP');

export const AUTH_FETCH_USER_DATA_INIT = createAction(
  'AUTH_FETCH_USER_DATA_INIT'
);
export const AUTH_FETCH_USER_DATA_SUCCESS = createAction(
  'AUTH_FETCH_USER_DATA_SUCCESS'
);
export const AUTH_FETCH_USER_DATA_FAIL = createAction(
  'AUTH_FETCH_USER_DATA_FAIL'
);

export const AUTH_CHANGE_PASSWORD_INIT = createAction(
  'AUTH_CHANGE_PASSWORD_INIT'
);
export const AUTH_CHANGE_PASSWORD_SUCCESS = createAction(
  'AUTH_CHANGE_PASSWORD_SUCCESS'
);
export const AUTH_CHANGE_PASSWORD_FAIL = createAction(
  'AUTH_CHANGE_PASSWORD_FAIL'
);

export const AUTH_UPDATE_USER_DATA = createAction('AUTH_UPDATE_USER_DATA');

export const AUTH_PROVIDER_INIT = createAction('AUTH_PROVIDER_INIT');

export const AUTH_PROVIDER_SUCCESS = createAction('AUTH_PROVIDER_SUCCESS');

export const AUTH_PROVIDER_FAIL = createAction('AUTH_PROVIDER_FAIL');

export const updateSelectedRestaurant = (index) => {
  return (dispatch, getState) => {
    let user = getState().auth.userData;
    const { uid } = firebase.auth().currentUser;
    user.selectedRestaurant = index;

    dispatch(AUTH_UPDATE_USER_DATA({ id: uid, ...user }));
  };
};

export const updateUserData = () => {
  return async (dispatch, getState) => {
    const { uid } = firebase.auth().currentUser;

    let user = { ...getState().auth.userData };

    let restaurants = [];
    try {
      let userStr = await firebase
        .firestore()
        .collection('users')
        .doc(uid)
        .get();
      let restaurantRef = await firebase
        .firestore()
        .collection('restaurants')
        .where('userIds', 'array-contains', uid)
        .get();

      user = Object.assign(userStr.data(), user);

      if (restaurantRef.empty) {
        console.log('No matching restaurants.');
      } else {
        restaurantRef.docs.forEach((el) => {
          // if (el.data().users && role === undefined) {
          //   role = el.data().users.find((user) => user.id === uid).role;
          // }
          restaurants.push(el.data());
        });
      }

      user.restaurants = restaurants;
      // user.role = role;
      dispatch(AUTH_UPDATE_USER_DATA({ id: uid, ...user }));
    } catch (error) {
      toastr.error('', error);
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    dispatch(AUTH_LOGOUT_INIT());

    dispatch(clearUsersDataLogout());
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
    sessionStorage.removeItem('signIn');
    await firebase.auth().signOut();

    dispatch(AUTH_LOGOUT_SUCCESS());
  };
};

export const verifyAuth = () => {
  return (dispatch) => {
    firebase.auth().onAuthStateChanged((user) => {
      dispatch(AUTH_RESTORE_SESSION_INIT());

      if (user !== null) {
        return dispatch(AUTH_RESTORE_SESSION_SUCCESS());
      }

      dispatch(AUTH_RESTORE_SESSION_FAIL());
      return dispatch(logout());
    });
  };
};

export const fetchUserData = () => {
  return async (dispatch) => {
    dispatch(AUTH_FETCH_USER_DATA_INIT());
    
    const { uid } = firebase.auth().currentUser;

    let user;
    let restaurants = [];

    try {
      let userStr = await firebase
        .firestore()
        .collection('users')
        .doc(uid)
        .get();

      let restaurantRef = await firebase
        .firestore()
        .collection('restaurants')
        .where('userIds', 'array-contains', uid)
        .get();

      user = userStr.data();

      // if (user.isAdmin) {
      //   dispatch(fetchRestaurants());
      // }

      if (restaurantRef.empty && !user.isAdmin && user.role !== 'Rewards') {
        toastr.error('', 'You have not been assigned a restaurant yet.');
        dispatch(logout());
        return;
      } else {
        restaurantRef.docs.forEach((el) => {
          restaurants.push({ ...el.data(), id: el.id });
        });
      }

      if (userStr.data().isDisabled) {
        toastr.error('', 'Your account was disabled');
        dispatch(logout());
        return;
      }

      user.restaurants = restaurants;
      user.selectedRestaurant = 0;

      if (user.role === undefined && !user.isAdmin) {
        toastr.error(
          '',
          'You have not been given any role in your assigned restaurant.'
        );
        dispatch(logout());
        return;
      }
    } catch (error) {
      dispatch(logout());
      return dispatch(AUTH_FETCH_USER_DATA_FAIL({ error }));
    }

    if (!user) {
      return dispatch(logout());
    }

    return dispatch(
      AUTH_FETCH_USER_DATA_SUCCESS({
        id: uid,
        ...user,
      })
    );
  };
};

export const checkUserData = () => {
  return (dispatch, getState) => {
    const { id } = getState().auth.userData;

    if (!id) {
      dispatch(fetchUserData());
    }
  };
};

export const authenticate = (email, password) => {
  return async (dispatch, getState) => {
    dispatch(AUTH_SIGN_IN_INIT());
    const { locale } = getState().preferences;
    try {
      await auth().signInWithEmailAndPassword(email, password);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      return dispatch(AUTH_SIGN_IN_FAIL({ error: errorMessage }));
    }

    // const { emailVerified } = firebase.auth().currentUser;

    // if (!emailVerified) {
    //   const errorMessage = firebaseError(
    //     FIREBASE_RESPONSE.USER_DISABLED,
    //     locale
    //   );
    //   return dispatch(AUTH_SIGN_IN_FAIL({ error: errorMessage }));
    // }

    return dispatch(fetchUserData());
  };
};

export const setPassword = (email, password, url) => {
  return async (dispatch, getState) => {
    dispatch(AUTH_SET_PASSWORD_INIT());
    const { locale } = getState().preferences;

    try {
      await firebase.auth().signInWithEmailLink(email, url);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      return dispatch(AUTH_SET_PASSWORD_FAIL({ error: errorMessage }));
    }

    const user = firebase.auth().currentUser;

    try {
      await user.updatePassword(password);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      return dispatch(AUTH_SET_PASSWORD_FAIL({ error: errorMessage }));
    }

    dispatch(AUTH_SET_PASSWORD_SUCCESS());

    return dispatch(fetchUserData());
  };
};

export const resetPassword = (email) => {
  return async (dispatch, getState) => {
    dispatch(AUTH_RESET_PASSWORD_INIT());
    const { locale } = getState().preferences;

    try {
      await firebase.auth().sendPasswordResetEmail(email);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      return dispatch(AUTH_RESET_PASSWORD_FAIL({ error: errorMessage }));
    }

    return dispatch(AUTH_RESET_PASSWORD_SUCCESS());
  };
};

export const authCleanUp = () => (dispatch) => dispatch(AUTH_CLEAN_UP());

export const changeUserPassword = (currentPassword, newPassword) => {
  return async (dispatch, getState) => {
    dispatch(AUTH_CHANGE_PASSWORD_INIT());
    const { locale } = getState().preferences;

    const user = firebase.auth().currentUser;

    const { email } = user;

    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      currentPassword
    );

    try {
      await user.reauthenticateWithCredential(credential);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(AUTH_CHANGE_PASSWORD_FAIL({ error: errorMessage }));
    }

    try {
      await user.updatePassword(newPassword);
    } catch (error) {
      const errorMessage = firebaseError(error, locale);
      toastr.error('', errorMessage);
      return dispatch(AUTH_CHANGE_PASSWORD_FAIL({ error: errorMessage }));
    }

    toastr.success('', 'Password changed successfully');
    return dispatch(AUTH_CHANGE_PASSWORD_SUCCESS());
  };
};

const authWithProvider = (provider) => {
  return async (dispatch, getState) => {
    dispatch(AUTH_PROVIDER_INIT());
    const { locale } = getState().preferences;
    let logInData;

    try {
      logInData = await firebase.auth().signInWithPopup(provider);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      return dispatch(AUTH_PROVIDER_FAIL({ error: errorMessage }));
    }
    const { user, additionalUserInfo } = logInData;

    const { uid } = firebase.auth().currentUser;

    const createdAt = new Date().toString();

    const { location } = additionalUserInfo.profile;

    const userData = {
      isAdmin: false,
      email: user.email,
      name: user.displayName,
      createdAt,
      logoUrl: user.photoURL,
      location: location?.name || null,
    };

    let userFromDb;
    try {
      let userStr = await firebase
        .firestore()
        .collection('users')
        .doc(uid)
        .get();
      userFromDb = userStr.data();
      // userFromDb = (
      //   await firebase
      //     .database()
      //     .ref(`users/${uid}`)
      //     .once('value')
      // ).val();
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      return dispatch(AUTH_PROVIDER_FAIL({ error: errorMessage }));
    }

    if (!userFromDb) {
      try {
        await firebase.firestore().collection('users').doc(uid).set(userData);
        // await firebase
        //   .database()
        //   .ref(`users/${uid}`)
        //   .set(userData);
      } catch (error) {
        const errorMessage = firebaseError(error.code, locale);
        return dispatch(AUTH_PROVIDER_FAIL({ error: errorMessage }));
      }
    }

    return dispatch(
      AUTH_PROVIDER_SUCCESS({ id: uid, ...userData, ...userFromDb })
    );
  };
};

export const authFacebook = () => {
  const provider = new firebase.auth.FacebookAuthProvider();
  provider.addScope('email');
  return authWithProvider(provider);
};

export const authGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/user.addresses.read');
  provider.addScope('https://www.googleapis.com/auth/userinfo.email');
  return authWithProvider(provider);
};

export const authMicrosoft = () => {
  const provider = new firebase.auth.OAuthProvider('microsoft.com');
  return authWithProvider(provider);
};
