import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import classNames from 'classnames';
import 'bulma-social/bin/bulma-social.min.css';

import logoWhite from '../../assets/heydoor_white.png';
import logoDoorBlack from '../../assets/heydoor_door_black.png';

import firebase from 'firebase.js';

import {
  authenticate,
  setPassword,
  authCleanUp,
  authFacebook,
  authGoogle,
  authMicrosoft,
  changePasswordAfterReset,
} from 'state/actions/auth';
import { useChangeHandler, useFormatMessage } from 'hooks';
import { inputValidations } from 'utils';
import paths from '../Router/paths';
import classes from './Login.module.scss';
import { CustomModal } from 'components/Modal';
import ResetPassword from 'pages/ResetPassword';
import { toastr } from 'react-redux-toastr';

const Login = () => {
  const { error, isAuth, loading, locale } = useSelector(
    (state) => ({
      error: state.auth.error,
      isAuth: !!state.auth.userData.id,
      loading: state.auth.loading,
      locale: state.preferences.locale,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const [authData, setAuthData] = useState({
    email: '',
    password: '',
  });

  const [attemptsCounter, setAttemptsCounter] = useState(0);
  const [showResetPassword, setShowResetPassword] = useState(false);

  const onChangeHandler = useChangeHandler(setAuthData);

  useEffect(() => {
    document.documentElement.classList.remove(
      'has-aside-left',
      'has-navbar-fixed-top'
    );
    return () => {
      document.documentElement.classList.add(
        'has-aside-left',
        'has-navbar-fixed-top'
      );
      dispatch(authCleanUp());
    };
  }, [dispatch]);

  const isEmailLink = firebase
    .auth()
    .isSignInWithEmailLink(window.location.href);

  const isResetPwdLink = window.location.href.includes('resetPassword');

  const onSubmitHandler = (event) => {
    event.preventDefault();

    if (isEmailLink) {
      dispatch(
        setPassword(authData.email, authData.password, window.location.href)
      );
    } else if (isResetPwdLink) {
      changePasswordAfterReset();
    } else {
      setAttemptsCounter(attemptsCounter + 1);
      dispatch(authenticate(authData.email, authData.password));
    }
  };

  const changePasswordAfterReset = async () => {
    const code = new URLSearchParams(window.location.search).get('oobCode');
    try {
      await firebase.auth().confirmPasswordReset(code, authData.password);

      toastr.success('Sucess', 'Password modified successfully', {
        onShowComplete: () => {
          window.location.assign('https://vendor.heydoor.com.au/login');
        },
      });
    } catch (e) {
      toastr.error('', e.message);
    }
  };

  const onRememberMeHandler = (event) => {
    if (event.target.checked) {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    } else {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
    }
  };

  // const iconsClassName = classNames('icon', classes.icon);

  // const onFacebookHandler = useCallback(() => {
  //   dispatch(authFacebook());
  // }, [dispatch, authFacebook]);

  // const onGoogleHandler = useCallback(() => {
  //   dispatch(authGoogle());
  // }, [dispatch, authGoogle]);

  // const onMicrosoftHandler = useCallback(() => {
  //   dispatch(authMicrosoft());
  // }, [dispatch, authMicrosoft]);

  useEffect(() => {
    if (attemptsCounter == 3) {
      setShowResetPassword(!showResetPassword);
    }
    if (attemptsCounter > 3) {
      setAttemptsCounter(0);
    }
  }, [attemptsCounter]);

  const inputs = isEmailLink
    ? inputValidations(authData.email, authData.password, locale)
    : {
      email: {
        modifier: null,
        message: null,
      },
      password: {
        modifier: null,
        message: null,
      },
      canSubmit: false,
    };

  const redirect = isAuth && <Redirect to={paths.ROOT} />;

  const setNewPasswordMessage = useFormatMessage('Login.setNewPassword');

  const loginMessage = useFormatMessage('Login.loginMessage');

  const login = useFormatMessage('Login.login');

  const setPasswordMessage = useFormatMessage('Login.setPassword');

  const rememberMe = useFormatMessage('Login.rememberMe');

  const forgotPasswordMessage = useFormatMessage('Login.forgotPassword');

  const email = useFormatMessage('Login.email');

  return (
    <section
      className="section hero is-fullheight is-error-section"
      style={{ backgroundColor: 'black' }}
    >
      {redirect}
      <div className="hero-body">
        <div className="container">
          <div
            style={{
              display: 'flex',
              alignContent: 'top',
              justifyContent: 'center',
            }}
          >
            <img
              src={logoWhite}
              alt="heydoor logo"
              style={{
                paddingTop: 0,
                maxHeight: '12rem',
              }}
            />
          </div>
          <div>
            <div>
              <div
                className="card has-card-header-background"
                style={{ backgroundColor: 'black' }}
              >
                <header className="has-text-centered">
                  <p style={{ color: '#CEAD29' }}>
                    {isEmailLink || isResetPwdLink
                      ? setNewPasswordMessage
                      : loginMessage}
                  </p>
                </header>
                <div
                  className="card-content"
                  style={{ padding: '30px 0px 0px 0px' }}
                >
                  <form onSubmit={onSubmitHandler}>
                    <div
                      style={{
                        margin: 'auto',
                        display: 'flex',
                        maxWidth: '500px',
                        justifyContent: 'center',
                        flexDirection: 'row',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignContent: 'space-around',
                        }}
                      >
                        <label
                          style={{
                            color: '#CEAD29',
                            paddingRight: '1rem',
                            margin: 'auto',
                          }}
                        >
                          {email}
                        </label>
                        <div style={{ height: '20px' }} />
                        <label
                          style={{
                            color: '#CEAD29',
                            paddingRight: '1rem',
                            margin: 'auto',
                          }}
                        >
                          {useFormatMessage('Login.password')}
                        </label>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexGrow: 2,
                          justifyContent: 'space-around',
                        }}
                      >
                        <input
                          className={classNames('input', inputs.email.modifier)}
                          type="email"
                          name="email"
                          required
                          value={authData.email}
                          onChange={onChangeHandler}
                        />
                        <div style={{ height: '20px' }} />
                        <input
                          className={classNames(
                            'input',
                            inputs.password.modifier
                          )}
                          type="password"
                          name="password"
                          required
                          value={authData.password}
                          onChange={onChangeHandler}
                        />
                      </div>
                    </div>
                    {inputs.email.message && (
                      <p className={classNames('help', inputs.email.modifier)}>
                        {inputs.email.message}
                      </p>
                    )}
                    {inputs.password.message && (
                      <p
                        className={classNames('help', inputs.password.modifier)}
                      >
                        {inputs.password.message}
                      </p>
                    )}

                    <br />
                    <div
                      style={{
                        display: 'flex',
                        flexFlow: 'wrap',
                        maxWidth: '500px',
                        margin: 'auto',
                        alignContent: 'space-between',
                        justifyContent: 'space-between',
                      }}
                    >
                      {!isResetPwdLink && (
                        <div className="control">
                          <label
                            className="checkbox is-unselectable"
                            style={{ color: 'white' }}
                          >
                            <input
                              type="checkbox"
                              onChange={onRememberMeHandler}
                              defaultChecked={false}
                            />

                            {rememberMe}
                          </label>
                        </div>
                      )}
                      <div className="control">
                        <button
                          type="submit"
                          className={classNames('button', 'is-warning', {
                            'is-loading': loading,
                          })}
                          disabled={isEmailLink ? !inputs.canSubmit : false}
                        >
                          {isEmailLink || isResetPwdLink
                            ? setPasswordMessage
                            : login}
                        </button>
                      </div>
                      {error && (
                        <p
                          className={classNames(
                            'has-text-danger',
                            classes.errorMessage
                          )}
                        >
                          {error}
                        </p>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        isActive={showResetPassword}
        closeModal={() => setShowResetPassword(!showResetPassword)}
        title={forgotPasswordMessage}
      >
        <ResetPassword
          closeModal={() => setShowResetPassword(!showResetPassword)}
        />
      </CustomModal>
    </section>
  );
};

export default Login;
