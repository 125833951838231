import RedeemForm from 'components/Rewards/redeemform';
import firebase from 'firebase.js';
import { useFormatMessage } from 'hooks';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

const Redeem = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [form, setForm] = useState({ isActive: false, data: {} });
  const [code, setCode] = useState('');
  const [codeInvalid, setCodeInvalid] = useState({
    showing: false,
    message: '',
  });

  const fetchDataFromCode = async () => {
    const rewardRef = await firebase.firestore()
      .collection('reward_voucher_codes')
      .where('codes', 'array-contains', code)
      .get();

    if (rewardRef.docs.length > 0) {
      const doc = rewardRef.docs[0];
      const user_id = doc.id;

      const voucher = doc.data().vouchers.find((el) => el.code === code);

      const rewardDoc = await firebase.firestore()
        .collection('rewards')
        .doc(voucher.rewardId)
        .get();

      const userDoc = await firebase.firestore()
        .collection('customers')
        .doc(user_id)
        .get();

      const reward = rewardDoc.data();
      const customer = userDoc.data();

      let name = customer?.firstName;
      if (name) {
        name += ' ';
        name += customer?.lastName;
      } else {
        name = '';
      }

      if (voucher.used) {
        return {
          invalid: 'used',
          customer_name: name === '' ? 'Unknown name' : name,
          reward_offer: reward.title,
        };
      }

      if (reward.date_active_to_posix < new Date().valueOf())
        return {
          invalid: 'expired',
          customer_name: name,
          reward_offer: reward.title,
        };

      return {
        customer_name: name,
        customer_id: userDoc.id,
        reward_offer: reward.title,
        reward_id: rewardDoc.id,
        reward_user_id: reward.userId,
        voucher,
      };
    }
    return {
      invalid: 'not-found',
    };
  };

  const errorMessage = async (data) => {
    let message = [''];
    if (data.invalid === 'not-found') {
      message = ['Voucher Code could not be found.'];
    } else if (data.invalid === 'expired') {
      message = ['Reward offer has expired.'];
    } else {
      try {
        const historyDoc = await firebase.firestore()
          .collection('rewards_history')
          .where('voucher_code', '==', code)
          .get();

        const { activated_at_posix } = historyDoc.docs[0].data();
        message = [
          [
            `This code has already been redeemed on this date by ${data.customer_name}`,
          ],
          [
            `
      ${moment(activated_at_posix).format('D/MM/yyyy')} by ${
              data.customer_name
            } rewards was ${data.reward_offer}`,
          ],
        ];
      } catch (e) {
        message = [
          'Either you do not have permission to redeem that code or that code was already redeemed by someone else',
        ];
      }
    }
    return message;
  };

  const validateCode = async () => {
    setLoading(!isLoading);

    const data = await fetchDataFromCode();

    if (data.invalid) {
      const message = await errorMessage(data);
      setCodeInvalid({
        showing: true,
        message,
      });
    } else {
      setForm({ isActive: true, data });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (codeInvalid.showing) {
      setCodeInvalid({ showing: false });
    }
  }, [code]);

  return (
    <>
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <h1
              className="title has-text-centered pl-3"
              style={{ marginTop: '30px' }}
            >
              {useFormatMessage('Redeem.redeem')}
            </h1>
          </div>
        </div>
      </nav>
      <section className="section" style={{ paddingTop: '0px' }}>
        <div className="field has-addons" style={{ maxWidth: '600px' }}>
          <div className={`control is-expanded ${isLoading && 'is-loading'}`}>
            <input
              className="input is-rounded"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder={useFormatMessage('Redeem.inputPlaceholder')}
            />
            {codeInvalid.showing &&
              codeInvalid.message.map((el, index) => (
                <p
                  key={el || index}
                  className={`help ${codeInvalid.showing && 'is-danger'}`}
                >
                  {el}
                </p>
              ))}
          </div>
          <div className="control is-expanded">
            <button
              disabled={isLoading}
              className="button is-warning is-rounded"
              onClick={validateCode}
            >
              {useFormatMessage('Redeem.submit')}
            </button>
          </div>
        </div>
      </section>
      <RedeemForm
        formData={form}
        closeModal={() => setForm({ isActive: false })}
        user_id={props.user.id}
        title={useFormatMessage('RedeemForm.title')}
      />
    </>
  );
};

const mapStateToProps = ({ auth, restaurants }) => ({
  restaurant: restaurants.data[auth.userData.selectedRestaurant],
  user: auth.userData,
});

export default connect(mapStateToProps)(Redeem);
