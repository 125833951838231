import React, { useState } from 'react';
import { useFormatMessage } from 'hooks';
import firebase from 'firebase.js';
const FoodReadyTimePicker = (props) => {
  const [value, setValue] = useState(props.order.food_ready_time_minutes || 0);
  const save = useFormatMessage('Orders.save');
  const cancel = useFormatMessage('Orders.cancel');

  const onSubmit = () => {
    firebase.firestore()
      .collection('orders')
      .doc(props.order.id)
      .update({
        ...props.order,
        food_ready_time_minutes: firebase.firestore.FieldValue.increment(value),
      });

    props.closeModal();
  };
  return (
    <div>
      <div className="field has-addons">
        <div className="control">
          <a className="button is-warning" onClick={() => setValue(value - 1)}>
            -
          </a>
        </div>
        <div className="control is-expanded">
          <input
            className="input"
            type="number"
            style={{ textAlign: 'center' }}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <div className="control">
          <a className="button is-warning" onClick={() => setValue(value + 1)}>
            +
          </a>
        </div>
      </div>
      <div className="buttons has-addons is-centered">
        <button className="button is-outlined" onClick={() => setValue(5)}>
          5
        </button>

        <button className="button is-outlined" onClick={() => setValue(10)}>
          10
        </button>

        <button className="button is-outlined" onClick={() => setValue(15)}>
          15
        </button>

        <button className="button is-outlined" onClick={() => setValue(20)}>
          20
        </button>
        <button className="button is-outlined" onClick={() => setValue(25)}>
          25
        </button>
        <button className="button is-outlined" onClick={() => setValue(30)}>
          30
        </button>
      </div>
      <div className="field is-grouped is-grouped-centered">
        <p className="control">
          <button className="button is-outlined" onClick={props.closeModal}>
            {cancel}
          </button>
        </p>
        <p className="control">
          <button className="button is-warning" onClick={onSubmit}>
            {save}
          </button>
        </p>
      </div>
    </div>
  );
};

export default FoodReadyTimePicker;
