import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import paths from '../paths';
import { toastr } from 'react-redux-toastr';

const PrivateRoute = ({
  path,
  component: Component,
  roles,
  requiresRestaurant,
}) => {
  const { id, isAdmin, role, restaurant } = useSelector(
    (state) => ({
      id: state.auth.userData.id,
      role: state.auth.userData.role,
      isAdmin: state.auth.userData.isAdmin,
      restaurant: state.restaurants.data[0],
    }),
    shallowEqual
  );

  return (
    <Layout>
      <Route
        exact
        path={path}
        render={() => {
          if (id) {
            if (isAdmin) {
              if (requiresRestaurant) {
                if (restaurant) {
                  return <Component />;
                } else {
                  toastr.warning(
                    '',
                    'This page requires you to choose an active restaurant first.'
                  );
                  return <Redirect to={paths.ROOT} />;
                }
              } else {
                return <Component />;
              }
            } else {
              if (roles === undefined) {
                return <Redirect to={paths.UNAUTHORIZED} />;
              } else if (roles.includes(role)) {
                return <Component />;
              }
              if (role==="Rewards" && path === '/') {
                return <Redirect to={paths.REWARDS} />;
              }
              return <Redirect to={paths.UNAUTHORIZED} />;
            }
          }
          return <Redirect to={paths.LOGIN} />;
        }}
      />
    </Layout>
  );
};

PrivateRoute.propType = {
  path: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired,
};

export default PrivateRoute;
