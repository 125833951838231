import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useFormatMessage } from 'hooks';
import paths from 'pages/Router/paths';
import NavLink from '../Link';
import classes from './Aside.module.scss';
import { logout } from '../../../state/actions/auth';
import logoWhite from '../../../assets/heydoor_word_white.png';
import heydoor_door_white from '../../../assets/heydoor_door_white.png';
import ConfirmationModal from 'components/ConfirmationModal';

export const SubMenu = ({ label, children, icon }) => {
  const [active, setActive] = useState(false);

  return (
    <li className={classNames({ 'is-active': active })}>
      <a
        exact-active-class="is-active"
        className="has-icon has-dropdown-icon"
        onClick={() => setActive(!active)}
      >
        <span className="icon">
          <i className={icon} />
        </span>
        <span className="menu-item-label">{label}</span>
        <div className="dropdown-icon">
          <span className="icon">
            <i
              className={classNames(
                'mdi',
                { 'mdi-minus': active },
                { 'mdi-plus': !active }
              )}
            />
          </span>
        </div>
      </a>
      <ul>{children}</ul>
    </li>
  );
};

SubMenu.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
};

const Aside = ({ handleMobileToggle }) => {
  const { isAdmin, role, restaurant } = useSelector(
    ({ auth, restaurants }) => ({
      isAdmin: auth.userData.isAdmin,
      role: auth.userData.role,
      restaurant:
        auth.userData.isAdmin !== null
          ? auth.userData.isAdmin
            ? restaurants.data[auth.userData.selectedRestaurant]
            : auth.userData.restaurants[auth.userData.selectedRestaurant]
          : {},
    }),
    shallowEqual
  );

  const [showLogout, setShowLogout] = useState(false);

  const usersMessage = useFormatMessage('Aside.users');

  const completedOrders = useFormatMessage('Aside.completedOrders');
  const declinedOrders = useFormatMessage('Aside.declinedOrders');
  const myPlace = useFormatMessage('Aside.restaurant');
  const rewards = useFormatMessage('Aside.rewards');
  const heydoorPayments = useFormatMessage('Aside.heydoorPayments');
  const analytics = useFormatMessage('Aside.analytics');
  const promotions = useFormatMessage('Aside.promotions');
  const accounting = useFormatMessage('Aside.accounting');
  const loyalty = useFormatMessage('Loyalty.loyalty');
  const pendingOrders = useFormatMessage('Aside.pendingOrders');
  const orderMgmt = useFormatMessage('Aside.orderManagement');
  const menu = useFormatMessage('Aside.menu');
  const home = useFormatMessage('Aside.home');
  const redeem = useFormatMessage('Aside.redeem');
  const redeemList = useFormatMessage('Aside.redeemList');
  const dispatch = useDispatch();

  const onClickLogoutHandler = () => {
    dispatch(logout());
  };

  return (
    <>
      <aside
        className="aside is-placed-left is-expanded"
        style={{
          backgroundColor: 'black',
          overflow: 'auto',
        }}
      >
        <div className="menu is-menu-main" style={{ height: '100%' }}>
          <ul
            className="menu-list"
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <li>
              <Link to={paths.ROOT}>
                <div>
                  <img
                    className="logo"
                    src={logoWhite}
                    alt="heydoor logo white"
                    style={{ padding: '8px', maxWidth: '90%' }}
                  />
                </div>
              </Link>
            </li>
            {(isAdmin || ['Owner', 'Manager', 'Staff'].includes(role)) && (
              <li>
                <NavLink
                  to={paths.ROOT}
                  className="has-icon"
                  onClick={handleMobileToggle}
                >
                  <span className="icon">
                    <i className="mdi mdi-home" />
                  </span>
                  <span className="menu-item-label">{home}</span>
                </NavLink>
              </li>
            )}
            {(isAdmin || ['Owner', 'Manager'].includes(role)) && (
              <li>
                <NavLink
                  to={paths.USERS}
                  className="has-icon"
                  onClick={handleMobileToggle}
                >
                  <span className="icon">
                    <i className="mdi mdi-account-supervisor" />
                  </span>
                  <span className="menu-item-label">{usersMessage}</span>
                </NavLink>
              </li>
            )}
            {(isAdmin || ['Owner', 'Manager', 'Staff'].includes(role)) && (
              <>
                <li>
                  <NavLink
                    to={paths.MENU}
                    className="has-icon"
                    onClick={handleMobileToggle}
                  >
                    <span className="icon">
                      <i className="mdi mdi-food" />
                    </span>
                    <span className="menu-item-label">{menu}</span>
                  </NavLink>
                </li>
                <SubMenu label={orderMgmt} icon="mdi mdi-view-list">
                  {['None', null, undefined, 'Provided a Tablet'].includes(
                    restaurant?.pos_provider || undefined
                  ) && (
                    <li>
                      <NavLink
                        className={classes.submenuLink}
                        to={paths.PENDING_ORDERS}
                        onClick={handleMobileToggle}
                      >
                        <span>{pendingOrders}</span>
                      </NavLink>
                    </li>
                  )}
                  {(isAdmin || ['Owner', 'Manager'].includes(role)) && (
                    <>
                      <li>
                        <NavLink
                          className={classes.submenuLink}
                          to={paths.COMPLETED_ORDERS}
                          onClick={handleMobileToggle}
                        >
                          <span>{completedOrders}</span>
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          className={classes.submenuLink}
                          to={paths.DECLINED_ORDERS}
                          onClick={handleMobileToggle}
                        >
                          <span>{declinedOrders}</span>
                        </NavLink>
                      </li>
                    </>
                  )}
                </SubMenu>
              </>
            )}
            {(isAdmin || ['Rewards'].includes(role)) && (
              <SubMenu label={rewards} icon="mdi mdi-gift-outline">
                <li>
                  <NavLink
                    to={paths.REWARDS}
                    className={classes.submenuLink}
                    onClick={handleMobileToggle}
                  >
                    <span>{rewards}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={paths.REDEEM}
                    className={classes.submenuLink}
                    onClick={handleMobileToggle}
                  >
                    <span>{redeem}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={paths.REDEEMLIST}
                    className={classes.submenuLink}
                    onClick={handleMobileToggle}
                  >
                    <span>{redeemList}</span>
                  </NavLink>
                </li>
              </SubMenu>
            )}
            {(isAdmin || ['Owner', 'Manager'].includes(role)) && (
              <>
                <li>
                  <NavLink
                    to={paths.LOYALTY}
                    className="has-icon"
                    onClick={handleMobileToggle}
                  >
                    <span className="icon">
                      <i className="mdi mdi-emoticon-happy-outline" />
                    </span>
                    <span className="menu-item-label">{loyalty}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={paths.PROMOTIONS}
                    className="has-icon"
                    onClick={handleMobileToggle}
                  >
                    <span className="icon">
                      <i className="mdi mdi-star" />
                    </span>
                    <span className="menu-item-label">{promotions}</span>
                  </NavLink>
                </li>

                <SubMenu label={accounting} icon="mdi mdi-cash-multiple">
                  <li>
                    <NavLink
                      className={classes.submenuLink}
                      to={paths.HEYDOOR_PAYMENTS}
                      onClick={handleMobileToggle}
                    >
                      <span>{heydoorPayments}</span>
                    </NavLink>
                  </li>
                </SubMenu>
                <li>
                  <NavLink
                    to={paths.ANALYTICS}
                    className="has-icon"
                    onClick={handleMobileToggle}
                  >
                    <span className="icon">
                      <i className="mdi mdi-poll" />
                    </span>
                    <span className="menu-item-label">{analytics}</span>
                  </NavLink>
                </li>
              </>
            )}
            {(isAdmin || ['Owner'].includes(role)) && (
              <li>
                <NavLink
                  to={paths.RESTAURANT}
                  className="has-icon"
                  onClick={handleMobileToggle}
                >
                  <span className="icon">
                    <i className="mdi mdi-storefront" />
                  </span>
                  <span className="menu-item-label">{myPlace}</span>
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                to={paths.PROFILE}
                className="has-icon"
                onClick={handleMobileToggle}
              >
                <span className="icon">
                  <i className="mdi mdi-settings" />
                </span>
                <span className="menu-item-label">
                  {useFormatMessage('Aside.settings')}
                </span>
              </NavLink>
            </li>
            <div style={{ flexGrow: 1 }} />
            <li>
              <div
                className={`${classes.submenuLink} has-icon navbar-item`}
                style={{ color: 'white', cursor: 'pointer' }}
                onClick={() => {
                  setShowLogout(true);
                  handleMobileToggle();
                }}
              >
                <span className="icon" style={{ width: '48px' }}>
                  <img
                    style={{
                      width: '16px',
                      height: '20px',
                      display: 'block',
                    }}
                    src={heydoor_door_white}
                  />
                </span>
                <span className="menu-item-label">
                  {useFormatMessage('Aside.logOut')}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </aside>
      <ConfirmationModal
        isActive={showLogout}
        isLoading={false}
        title={useFormatMessage('Aside.logOut')}
        body={useFormatMessage('Aside.confirmLogout')}
        confirmButtonMessage={useFormatMessage('Aside.logOut')}
        onConfirmation={onClickLogoutHandler}
        cancelButtonMessage={useFormatMessage('Aside.cancel')}
        onCancel={() => setShowLogout(false)}
      />
    </>
  );
};

Aside.propTypes = {
  handleMobileToggle: PropTypes.func.isRequired,
};

export default Aside;
