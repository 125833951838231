import React from 'react';

export const CustomModal = (props) => {
  return (
    <div>
      <div className={`modal ${props.isActive ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={props.closeModal}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{props.title}</p>
      
            <button
              className="delete"
              aria-label="close"
              onClick={props.closeModal}
            ></button>
          </header>
          <section className="modal-card-body">{props.children}</section>
        </div>
      </div>
    </div>
  );
};
