import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import './rewardsform.css';
import { DatePicker } from 'antd';
import axios from 'axios';
import firebase from 'firebase.js';
const { RangePicker } = DatePicker;

const RewardForm = (props) => {
  const { register, control, handleSubmit, errors, reset } = useForm({
    defaultValues: props.reward,
  });

  const errorDate = useFormatMessage('Rewards.errorDate');

  const tier = useFormatMessage('Rewards.tier');

  const dateRange = useFormatMessage('Rewards.dateRange');
  const description = useFormatMessage('Rewards.description');
  const submit = useFormatMessage('Rewards.submit');
  const errorRequired = useFormatMessage('Rewards.errorRequired');
  const descriptionPlaceholder = useFormatMessage(
    'Rewards.descriptionPlaceholder'
  );
  const title = useFormatMessage('Rewards.title');
  const titlePlaceholder = useFormatMessage('Rewards.titlePlaceholder');
  const companyName = useFormatMessage('Rewards.companyName');
  const bgLabel = useFormatMessage('Rewards.bg');
  const chooseFile = useFormatMessage('Rewards.chooseFile');
  const companyLabel = useFormatMessage('Rewards.companyImage');
  const moreDetailsLabel = useFormatMessage('Rewards.moreDetails');
  const voucherCode = useFormatMessage('Rewards.voucherCode');
  const filesRequired = useFormatMessage('Rewards.filesRequired');
  const [showError, setShowError] = useState(false);
  const bgRef = useRef(null);
  const companyImageRef = useRef(null);

  const [files, setFiles] = useState({});

  const handleBgSelect = () => {
    if (bgRef.current.files) {
      setFiles({
        ...files,
        bg: bgRef.current.files[0],
        previewBg: URL.createObjectURL(bgRef.current.files[0]),
      });
    }
  };

  const handleCompanyImageSelect = () => {
    if (companyImageRef.current.files) {
      setFiles({
        ...files,
        companyImage: companyImageRef.current.files[0],
        previewCompanyImage: URL.createObjectURL(
          companyImageRef.current.files[0]
        ),
      });
    }
  };

  const handleMoreDetailsChange = (event) => {
    setFiles({ ...files, moreDetails: { data: event.target.value } });
  };

  useEffect(() => {
    setShowError(false);
    reset({
      ...props.reward,
      date_range: [
        moment(props.reward.date_active_from_posix),
        moment(props.reward.date_active_to_posix),
      ],
    });

    console.log('reset');

    setFiles({});

    const getFiles = async () => {
      if (Object.keys(props.reward).length > 0) {
        setFiles({
          bg: 'loading',
          companyImage: 'loading',
          moreDetails: 'loading',
        });

        try {
          const companyImageMeta = await firebase.storage()
            .refFromURL(props.reward.company_image)
            .getMetadata();

          const bgMeta = await firebase.storage()
            .refFromURL(props.reward.bg_image)
            .getMetadata();

          const moreDetails = await axios.get(props.reward.more_details);
          const moreDetailsMeta = await firebase.storage()
            .refFromURL(props.reward.more_details)
            .getMetadata();

          setFiles({
            previewCompanyImage: props.reward.company_image,
            previewBg: props.reward.bg_image,
            companyImage: {
              meta: companyImageMeta.customMetadata,
              name:
                companyImageMeta.customMetadata &&
                companyImageMeta.customMetadata.name,
            },
            bg: {
              name: bgMeta.customMetadata && bgMeta.customMetadata.name,
              meta: bgMeta.customMetadata,
            },
            moreDetails: {
              data: moreDetails.data,
              meta: moreDetailsMeta.customMetadata,
            },
          });
        } catch (e) {
          console.log(e);
          setFiles({});
        }
      } else {
        setFiles({});
      }
    };

    getFiles();
  }, [props.reward, reset]);

  const onSubmit = (data) => {
    if (Object.keys(files).length < 3) {
      setShowError(true);
    } else {
      props.onSubmit({
        data: { ...props.reward, ...data },
        files,
      });
    }
  };

  return (
    <div>
      <div className={`modal ${props.isActive ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={props.closeModal}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{props.title}</p>
            <div className="field" style={{ marginRight: '10px' }}>
              <button
                className={`button ${props.submitting && 'is-loading'}`}
                onClick={handleSubmit(onSubmit)}
              >
                {submit}
              </button>
            </div>
            <button
              className="delete"
              aria-label="close"
              onClick={props.closeModal}
            ></button>
          </header>
          <section className="modal-card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="field">
                <label className="label">{dateRange}</label>
                <div className="control">
                  <Controller
                    name="date_range"
                    control={control}
                    rules={{ required: true }}
                    render={({ onChange, value }) => {
                      return <RangePicker value={value} onChange={onChange} />;
                    }}
                  />
                </div>
                <div className="help is-danger">
                  {errors.date_range && errorDate}
                </div>
              </div>

              <div className="field">
                <label className="label">{title}</label>
                <div className="control">
                  <input
                    className={`input ${errors.title ? 'is-danger' : ''}`}
                    ref={register({ required: true })}
                    name="title"
                    placeholder={titlePlaceholder}
                  />
                </div>
                <div className="help is-danger">
                  {errors.title && errorRequired}
                </div>
              </div>
              <div className="field">
                <label className="label">{companyName}</label>
                <div className="control">
                  <input
                    className={`input ${errors.companyName ? 'is-danger' : ''}`}
                    ref={register({ required: true })}
                    name="companyName"
                  />
                </div>
                <div className="help is-danger">
                  {errors.companyName && errorRequired}
                </div>
              </div>
              <div className="field">
                <label className="label">{voucherCode}</label>
                <div className="control">
                  <input
                    className={`input ${
                      errors.voucher_code ? 'is-danger' : ''
                    }`}
                    ref={register({ required: true })}
                    name="voucher_code"
                  />
                </div>
                <div className="help is-danger">
                  {errors.voucher_code && errorRequired}
                </div>
              </div>
              <div className="field">
                <label className="label">{tier}</label>
                <div className="control">
                  <div className="select">
                    <select ref={register({ required: true })} name="tier">
                      <option>Toy Poodle</option>
                      <option>Beagle</option>
                      <option>Rottweiler</option>
                      <option>Great Dane</option>
                      <option>All</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">{description}</label>
                <div className="control">
                  <input
                    className={`input ${errors.description ? 'is-danger' : ''}`}
                    ref={register({ required: true })}
                    name="description"
                    placeholder={descriptionPlaceholder}
                  />
                </div>
                <div className="help is-danger">
                  {errors.description && errorRequired}
                </div>
              </div>

              <div className="field">
                <label className="label">{companyLabel}</label>
                <div
                  className={`control ${
                    files && files.companyImage === 'loading'
                      ? 'is-loading'
                      : ''
                  }`}
                >
                  <div className="file has-name is-fullwidth">
                    <label className="file-label">
                      <input
                        className="file-input"
                        type="file"
                        accept="image/*"
                        ref={companyImageRef}
                        onChange={handleCompanyImageSelect}
                      />
                      {files.companyImage !== 'loading' &&
                        files.companyImage && (
                          <img
                            src={files.previewCompanyImage}
                            className="preview-img"
                            alt="preview"
                          />
                        )}
                      <span className="file-cta">
                        <span className="file-icon">
                          <i className="mdi mdi-file-upload"></i>
                        </span>
                        <span className="file-label">{chooseFile}</span>
                      </span>
                      <span className="file-name">
                        {files && files.companyImage && files.companyImage.name}
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">{bgLabel}</label>
                <div
                  className={`control ${
                    files && files.bg === 'loading' ? 'is-loading' : ''
                  }`}
                >
                  <div className="file has-name is-fullwidth">
                    <label className="file-label">
                      <input
                        className="file-input"
                        type="file"
                        ref={bgRef}
                        accept="image/*"
                        onChange={handleBgSelect}
                      />
                      {files.bg !== 'loading' && files.bg && (
                        <img
                          className="preview-img"
                          src={files.previewBg}
                          alt="preview"
                        />
                      )}
                      <span className="file-cta">
                        <span className="file-icon">
                          <i className="mdi mdi-file-upload"></i>
                        </span>
                        <span className="file-label">{chooseFile}</span>
                      </span>
                      <span className="file-name">
                        {files && files.bg && files.bg.name}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label">{moreDetailsLabel}</label>
                <div
                  className={`control  ${
                    files.moreDetails === 'loading' && 'is-loading'
                  }`}
                >
                  <textarea
                    className="textarea"
                    placeholder={useFormatMessage(
                      'Rewards.placeholderMoreDetails'
                    )}
                    value={files.moreDetails?.data || ''}
                    onChange={handleMoreDetailsChange}
                    rows="5"
                  />
                </div>
              </div>
              <div className="help is-danger">{showError && filesRequired}</div>
              <button
                className={`button ${
                  props.submitting && 'is-loading'
                } is-pulled-right`}
                onClick={handleSubmit(onSubmit)}
              >
                {submit}
              </button>
            </form>
          </section>
        </div>
      </div>
    </div>
  );
};

export default RewardForm;
