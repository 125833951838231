import React from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import { useFormatMessage } from 'hooks';

export default ({
  nestIndex,
  control,
  errors,
  removeParent,
  selectionName,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `Selections[${nestIndex}].Items`,
  });

  const name = useFormatMessage('Menu.name');
  const price = useFormatMessage('Menu.price');
  const addItem = useFormatMessage('Menu.addItem');
  const deleteItem = useFormatMessage('Menu.deleteItem');
  const removeSelection = useFormatMessage('Menu.removeSelection');

  const hasError = (nestIndex, k, type) => {
    if (errors && errors.Selections) {
      if (errors.Selections[nestIndex]) {
        if (errors.Selections[nestIndex].Items) {
          if (errors.Selections[nestIndex].Items[k]) {
            if (errors.Selections[nestIndex].Items[k][type]) {
              return true;
            }
          }
        }
      }
    }
    return false;
  };

  return (
    <div>
      {fields.map((item, k) => {
        const itemFieldName = `Selections[${nestIndex}].Items[${k}]`;
        return (
          <div key={item.id} className="card">
            <div className="card-content">
              <div className="media">
                <div className="media-left">
                  <div className="media-content">
                    <div className="title is-4">
                      {(selectionName || 'Item') + ' ' + (k + 1)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="field">
                  <label className="label">{name}</label>
                  <div className="control">
                    <Controller
                      as={
                        <input
                          className={`input ${
                            hasError(nestIndex, k, 'Name') && 'is-danger'
                          }`}
                          placeholder="Size Choice"
                        />
                      }
                      name={`${itemFieldName}.Name`}
                      control={control}
                      type="text"
                      rules={{ required: true, maxLength: 200 }}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">{price}</label>
                  <div className="control">
                    <Controller
                      rules={{ required: true, valueAsNumber: true }}
                      control={control}
                      name={`${itemFieldName}.Price`}
                      as={
                        <input
                          className={`input ${
                            hasError(nestIndex, k, 'Price') && 'is-danger'
                          }`}
                          placeholder={price}
                          step={0.5}
                          type="number"
                        />
                      }
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className="button is-danger"
                  onClick={() => remove(k)}
                >
                  {deleteItem}
                </button>
              </div>
            </div>
          </div>
        );
      })}
      <div className="field is-grouped">
        <div className="control">
          <button
            type="button"
            className="button"
            onClick={() =>
              append({
                Name: 'Name',
                Price: 'Price',
              })
            }
          >
            {addItem}
          </button>
        </div>
        <div className="control">
          <button
            type="button"
            className="button is-danger"
            onClick={() => removeParent(nestIndex)}
          >
            {removeSelection}
          </button>
        </div>
      </div>

      <hr />
    </div>
  );
};
