import React from 'react';
import './RadioButtonSelect.scss';

const RadioButtonSelect = ({ data, value, onChange, error }) => {
    const change = (item) => {
        let list = [];
        if (value && value.includes(item)) {
            list = value.filter((el) => el !== item);
        } else {
            if (value) {
                list = [...value];
            }
            list.push(item);
        }
        onChange(list);
    };

    return (
        <div
          style={{
            border: `1px solid ${error === undefined ? 'white' : 'red'}`,
            borderRadius: '4px',
            height: '160px',
            overflow: 'scroll',
          }}
        >
          {data.map((el) => (
            <div key={el} style={{ paddingLeft: '8px'}}>
              <input
                type="radio"
                name="radio-group"
                id={{ el }}
                checked={(value && value.includes(el)) || false}
                onChange={(e) => change(el)}
              />
              <label for={{ el }} style={{ paddingLeft: '8px', margin: '4em 1em'  }}>{el}</label>
            </div>
          ))}
        </div>
      );
};

export default RadioButtonSelect;
