import { createIntl, createIntlCache } from 'react-intl';

import english from 'languages/en';
import spanish from 'languages/es';
import en from 'assets/en.png';
import es from 'assets/es.png';

import axios from 'axios';

export const FIREBASE_RESPONSE = {
  EMAIL_IN_USE: 'auth/email-already-exists',
  EMAIL_INVALID: 'auth/invalid-email',
  EMAIL_NOT_FOUND: 'auth/user-not-found',
  PASSWORD_INVALID: 'auth/wrong-password',
  USER_DISABLED: 'auth/user-disabled',
  TOO_MANY_REQUESTS: 'auth/too-many-requests',
  EXPIRED_ACTION_CODE: 'auth/expired-action-code',
  INVALID_ACTION_CODE: 'auth/invalid-action-code',
  QUOTA_EXCEEDED_STORAGE: 'storage/quota-exceeded',
  UNAUTHENTICATED_STORAGE: 'storage/unauthenticated',
  UNAUTHORIZED_STORAGE: 'storage/unauthorized',
};

export const validateABN = async (name) => {
  const guid = '';

  try {
    let res = await axios.get(
      `https://abr.business.gov.au/json/MatchingNames.aspx?name=${name}&maxResults=10&guid=${guid}`
    );
    //if name in results return true...
  } catch (e) {
    console.log(e);
  }
};

export const fetchSuburb = async (postcode) => {
  let corsanywhere = 'https://cors-anywhere.herokuapp.com/';
  try {
    let res = await axios.get(
      `${corsanywhere}http://v0.postcodeapi.com.au/suburbs/${postcode}.json`
    );

    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const messages = {
  en: english,
  es: spanish,
};

const getIntlContext = (locale) => {
  const cache = createIntlCache();
  return createIntl(
    {
      locale,
      messages: messages[locale],
    },
    cache
  );
};

export const firebaseError = (error, locale) => {
  const intl = getIntlContext(locale);
  return intl.formatMessage({
    id: error,
    defaultMessage: messages[locale]['utils.default'],
  });
};

export const validateEmail = (email) => {
  return email.match(
    // eslint-disable-next-line no-useless-escape
    /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i
  );
};

export const inputValidations = (email, password, locale) => {
  let inputs = {
    email: {
      modifier: null,
      message: null,
    },
    password: {
      modifier: null,
      message: null,
    },
    canSubmit: null,
  };
  const intl = getIntlContext(locale);

  const setInputs = (key, value) => {
    inputs = { ...inputs, [`${key}`]: value };
  };

  const isValidEmail = email && validateEmail(email);

  if (email && !isValidEmail) {
    setInputs('email', {
      modifier: 'is-danger',
      message: intl.formatMessage({ id: 'utils.invalidEmail' }),
    });
  }

  const isValidPassword = password && password.length >= 6;

  if (isValidPassword) {
    setInputs('password', {
      modifier: 'is-success',
      message: intl.formatMessage({ id: 'utils.safePassword' }),
    });
  } else if (password) {
    setInputs('password', {
      modifier: 'is-danger',
      message: intl.formatMessage({ id: 'utils.unsafePassword' }),
    });
  }

  if (isValidEmail && isValidPassword) {
    setInputs('canSubmit', true);
  }

  return inputs;
};

export const availableLocales = Object.keys(messages);

export const browserLocale = navigator.language.split(/[-_]/)[0];

export const flags = {
  en,
  es,
};

export const timezones = [
  'Universal Coordinated Time',
  'European Central Time',
  'Eastern European Time',
  '(Arabic) Egypt Standard Time',
  'Eastern African Time',
  'Middle East Time',
  'Near East Time',
  'Pakistan Lahore Time',
  'India Standard Time',
  'Bangladesh Standard Time',
  'Vietnam Standard Time',
  'China Taiwan Time',
  'Japan Standard Time',
  'Australia Central Time',
  'Australia Eastern Time',
  'Solomon Standard Time',
  'New Zealand Standard Time',
  'Midway Islands Time',
  'Hawaii Standard Time',
  'Alaska Standard Time',
  'Pacific Standard Time',
  'Phoenix Standard Time',
  'Mountain Standard Time',
  'Central Standard Time',
  'Eastern Standard Time',
  'Indiana Eastern Standard Time',
  'Puerto Rico and US Virgin Islands Time',
  'Canada Newfoundland Time',
  'Argentina Standard Time',
  'Brazil Eastern Time',
  'Central African Time',
];

export const street_abbreviation = {
  ACCS: 'Access',
  ALLY: 'Alley',
  ALWY: 'Alleyway',
  AMBL: 'Amble',
  APP: 'Approach',
  ARC: 'Arcade',
  ARTL: 'Arterial',
  ARTY: 'Artery',
  AV: 'Avenue',
  BA: 'Banan',
  BEND: 'Bend',
  BWLK: 'Boardwalk',
  BVD: 'Boulevard',
  BR: 'Brace',
  BRAE: 'Brae',
  BRK: 'Break',
  BROW: 'Brow',
  BYPA: 'Bypass',
  BYWY: 'Byway',
  CSWY: 'Causeway',
  CTR: 'Centre',
  CH: 'Chase',
  CIR: 'Circle',
  CCT: 'Circuit',
  CRCS: 'Circus',
  CL: 'Close',
  CON: 'Concourse',
  CPS: 'Copse',
  CNR: 'Corner',
  CT: 'Court',
  CTYD: 'Courtyard',
  COVE: 'Cove',
  CR: 'Crescent',
  CRST: 'Crest',
  CRSS: 'Cross',
  CSAC: 'Cul-de-sac',
  CUTT: 'Cutting',
  DALE: 'Dale',
  DIP: 'Dip',
  DR: 'Drive',
  DVWY: 'Driveway',
  EDGE: 'Edge',
  ELB: 'Elbow',
  END: 'End',
  ENT: 'Entrance',
  ESP: 'Esplanade',
  EXP: 'Expressway',
  FAWY: 'Fairway',
  FOLW: 'Follow',
  FTWY: 'Footway',
  FORM: 'Formation',
  FWY: 'Freeway',
  FRTG: 'Frontage',
  GAP: 'Gap',
  GDNS: 'Gardens',
  GTE: 'Gate',
  GLDE: 'Glade',
  GLEN: 'Glen',
  GRA: 'Grange',
  GRN: 'Green',
  GR: 'Grove',
  HTS: 'Heights',
  HIRD: 'Highroad',
  HWY: 'Highway',
  HILL: 'Hill',
  INTG: 'Interchange',
  JNC: 'Junction',
  KEY: 'Key',
  LANE: 'Lane',
  LNWY: 'Laneway',
  LINE: 'Line',
  LINK: 'Link',
  LKT: 'Lookout',
  LOOP: 'Loop',
  MALL: 'Mall',
  MNDR: 'Meander',
  MEWS: 'Mews',
  MTWY: 'Motorway',
  NOOK: 'Nook',
  OTLK: 'Outlook',
  PDE: 'Parade',
  PWY: 'Parkway',
  PASS: 'Pass',
  PSGE: 'Passage',
  PATH: 'Path',
  PWAY: 'Pathway',
  PIAZ: 'Piazza',
  PLZA: 'Plaza',
  PKT: 'Pocket',
  PNT: 'Point',
  PORT: 'Port',
  PROM: 'Promenade',
  QDRT: 'Quadrant',
  QYS: 'Quays',
  RMBL: 'Ramble',
  REST: 'Rest',
  RTT: 'Retreat',
  RDGE: 'Ridge',
  RISE: 'Rise',
  RD: 'Road',
  RTY: 'Rotary',
  RTE: 'Route',
  ROW: 'Row',
  RUE: 'Rue',
  SVWY: 'Serviceway',
  SHUN: 'Shunt',
  SPUR: 'Spur',
  SQ: 'Square',
  ST: 'Street',
  SBWY: 'Subway',
  TARN: 'Tarn',
  TCE: 'Terrace',
  THFR: 'Thoroughfare',
  TLWY: 'Tollway',
  TOP: 'Top',
  TOR: 'Tor',
  TRK: 'Track',
  TRL: 'Trail',
  TURN: 'Turn',
  UPAS: 'Underpass',
  VALE: 'Vale',
  VIAD: 'Viaduct',
  VIEW: 'View',
  VSTA: 'Vista',
  WALK: 'Walk',
  WKWY: 'Walkway',
  WHRF: 'Wharf',
  WYND: 'Wynd',
};

export const foodAMPM = [
  'Avocado',
  'Bread',
  'Burger',
  'Chicken',
  'Chips',
  'Coffee',
  'Croissant',
  'Drinks',
  'Eggs',
  'Fish',
  'Healthy Bowls',
  'Kebabs',
  'Pancakes',
  'Pasta',
  'Pie',
  'Pies',
  'Pizza',
  'Salads',
  'Stir Fry',
  'Sushi',
  'Tacos',
  'Toasties',
  'Waffles',
];

export const dietaries = [
  'Dairy-Free',
  'Clean Eating',
  'Gluten-Free',
  'Vegan',
  'Vegetarian',
  'Kosher',
  'None',
];

export const subscriptions = [
  '30-days-initial',
  '30-days-additional',
  '3-months',
  '6-months',
  '12-months',
  '24-months'
];

export const foodTypes = [
  'BBQ',
  'Bagels',
  'Bakery',
  'Beef',
  'Biscuits',
  'Bread',
  'Bubble Tea',
  'Burgers',
  'Cafe',
  'Cake',
  'Chai',
  'Charcoal Chicken',
  'Chicken',
  'Chocolate',
  'Coffee',
  'Crepes',
  'Curry',
  'Diary',
  'Diary Free',
  'Doughnuts',
  'Drinks',
  'Eggs',
  'Fast Food',
  'Fish & Chips',
  'Fresh juice',
  'Fried',
  'Frozen Yoghurt',
  'Fruit',
  'Fruit Salad',
  'Gelato',
  'Gluten Free',
  'Greek Desserts',
  'Grilled',
  'Healthy salads',
  'Hot Chocolate',
  'Ice cream',
  'Kebab',
  'Kosher',
  'Lamb',
  'Meat pies',
  'Middle Eastern Desserts',
  'Milk Shakes',
  'Muffin',
  'Noodles',
  'Palm Oil Free',
  'Pasta',
  'Pasties',
  'Pastry',
  'Pies',
  'Pizza',
  'Pork',
  'Pretzels',
  'Pub',
  'RSL',
  'Restaurant',
  'Ribs',
  'Rice',
  'Roast',
  'Roasted nuts/chestnuts',
  'Rolls',
  'Salad',
  'Sausage Rolls',
  'Sauté',
  'Seafood',
  'Smoothies',
  'Snacks',
  'Soup',
  'Souvlaki',
  'Steamed',
  'Stir fry',
  'Street Food',
  'Sushi',
  'Sustainable Palm Oil',
  'Sweets',
  'Tea',
  'Vegan',
  'Vegetarian',
  'Wings',
  'Wraps',
  'kid friendly',
  'Breakfast',
  'Lunch',
  'Dinner',
  'Pan Cakes',
  'Milkshakes',
  'Ice Cream Shop',
  'Sandwiches',
  'Frappes',
  'Slices',
  'Dessert',
  'Fruit salad',
  'Hot Dog Van',
  'Hot Dog',
  'Kebab Shop',
  'Soup Bar',
  'Book Shop',
  'Beauty Salon',
  'Hair Dressing Salon',
  'Barber Shop',
  'Florist',
  'Chemist',
  'Body Shop',
  'Stationary',
  'Boutique',
  'Products',
];

export const restaurantFoodStyles = [
  'BBQ',
  'Bagels',
  'Bakery',
  'Beef',
  'Biscuits',
  'Bread',
  'Bubble Tea',
  'Burgers',
  'Cafe',
  'Cake',
  'Chai',
  'Charcoal Chicken',
  'Chicken',
  'Chocolate',
  'Coffee',
  'Crepes',
  'Curry',
  'Diary',
  'Diary Free',
  'Doughnuts',
  'Drinks',
  'Eggs',
  'Fast Food',
  'Fish & Chips',
  'Fresh juice',
  'Fried',
  'Frozen Yoghurt',
  'Fruit',
  'Fruit Salad',
  'Gelato',
  'Gluten Free',
  'Greek Desserts',
  'Grilled',
  'Healthy salads',
  'Hot Chocolate',
  'Ice cream',
  'Kebab',
  'Kosher',
  'Lamb',
  'Meat pies',
  'Middle Eastern Desserts',
  'Milk Shakes',
  'Muffin',
  'Noodles',
  'Palm Oil Free',
  'Pasta',
  'Pasties',
  'Pastry',
  'Pies',
  'Pizza',
  'Pork',
  'Pretzels',
  'Pub',
  'RSL',
  'Restaurant',
  'Ribs',
  'Rice',
  'Roast',
  'Roasted nuts/chestnuts',
  'Rolls',
  'Salad',
  'Sausage Rolls',
  'Sauté',
  'Seafood',
  'Smoothies',
  'Snacks',
  'Soup',
  'Souvlaki',
  'Steamed',
  'Stir fry',
  'Street Food',
  'Sushi',
  'Sustainable Palm Oil',
  'Sweets',
  'Tea',
  'Vegan',
  'Vegetarian',
  'Wings',
  'Wraps',
  'kid friendly',
  'Juice Bar',
  'Breakfast',
  'Lunch',
  'Dinner',
  'Pan Cakes',
  'Milkshakes',
  'Ice Cream Shop',
  'Sandwiches',
  'Frappes',
  'Slices',
  'Dessert',
  'Fruit salad',
  'Hot Dog Van',
  'Hot Dog',
  'Kebab Shop',
  'Soup Bar',
  'Barber Shop',
  'Beauty Salon',
  'Body Shop',
  'Boutique',
  'Chemist',
  'Florist',
  'Hair Dressing Salon',
  'Stationary',
  'Products'
];

export const restaurantCountries = [
  'Afghan',
  'African',
  'American',
  'Angolan',
  'Arabic',
  'Argentinian',
  'Asian',
  'Australian',
  'Bangladesh',
  'Brazilian',
  'Burmese',
  'Cambodian',
  'Chilean',
  'Chinese',
  'Croatian',
  'Cypriot',
  'Danish',
  'Dutch',
  'East African',
  'Ecuadorian',
  'Egyptian',
  'Eritrean',
  'Ethiopian',
  'Fijian',
  'Finnish',
  'French',
  'German',
  'Greek',
  'Hungarian',
  'Indian',
  'Indonesian',
  'Iraqian',
  'Israeli',
  'Italian',
  'Jamaican',
  'Japanese',
  'Korean',
  'Lebanese',
  'Malaysian',
  'Maltese',
  'Mauritian',
  'Medeterrian',
  'Mexican',
  'Middle Eastern',
  'Mongolian',
  'Moroccan',
  'Napalease',
  'Nigerian',
  'Pakistanian',
  'Persian',
  'Portuguese',
  'Russian',
  'Salvadoran',
  'Samoan',
  'Scandinavian',
  'Serbian',
  'Singaporean',
  'Somalian',
  'South Americian',
  'South Indian',
  'Spanish',
  'Sri Lankan',
  'Swedish',
  'Syrian',
  'Thai',
  'Tibetan',
  'Turkish',
  'Uzbek',
  'Vietnamese',
  'Yemeni',
  'Zimbabwean',
  'None',
];

export const countryList = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas (the)',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory (the)',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands (the)',
  'Central African Republic (the)',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands (the)',
  'Colombia',
  'Comoros (the)',
  'Congo (the Democratic Republic of the)',
  'Congo (the)',
  'Cook Islands (the)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic (the)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands (the) [Malvinas]',
  'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories (the)',
  'Gabon',
  'Gambia (the)',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See (the)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea (the Democratic People's Republic of)",
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic (the)",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands (the)',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands (the)',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger (the)',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines (the)',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation (the)',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan (the)',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands (the)',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates (the)',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United States Minor Outlying Islands (the)',
  'United States of America (the)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Åland Islands',
];
