import React, { useEffect, useRef, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';

import { Multiselect } from 'multiselect-react-dropdown';
import { useFormatMessage } from 'hooks';

import SelectionsForm from './selectionsForm';
import 'antd/dist/antd.css';
import { dietaries, foodAMPM, foodTypes } from 'utils';
import CheckboxSelect from '../CheckboxSelect';

const FoodForm = ({
  onSubmit,
  defaultValues,
  onFoodItemDelete,
  isActive,
  closeModal,
  title,
  menu,
}) => {
  const { control, register, handleSubmit, errors, watch, reset } = useForm({
    defaultValues: defaultValues,
  });
  const [selectedCategories, setSelectedCategories] = useState({});

  let renderCount = 0;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'Selections',
  });

  const prepForSubmit = (data) => {
    if (defaultValues.id) {
      data = { ...data, id: defaultValues.id };
    }
    data = { ...data, categories: multiSelectRef.current.getSelectedItems() };

    onSubmit(data);
  };

  const multiSelectRef = useRef(null);

  useEffect(() => {
    let newValues = {};

    if (defaultValues && defaultValues.Categories) {
      newValues = Object.assign({}, defaultValues);
      newValues.foodAMPM = newValues.Categories.filter((el) =>
        foodAMPM.includes(el)
      );
      newValues.foodTypes = newValues.Categories.filter((el) =>
        foodTypes.includes(el)
      );
    }

    //multiSelectRef.current.resetSelectedValues();
    if (defaultValues && defaultValues.id) {
      const categories = [];
      menu.Categories.forEach((category) => {
        const index = category.Foods.findIndex(
          (food) => food.id === defaultValues.id
        );
        if (index !== -1) {
          categories.push({ name: category.Name, id: category.Name });
        }
      });
      multiSelectRef.current.initialSetValue();
      multiSelectRef.current.renderNormalOption();

      setSelectedCategories(categories);
      //multiSelectRef.current.renderSelectedList();
    }
    else
    {
      setSelectedCategories([]);
      multiSelectRef.current.resetSelectedValues();
    }
    reset(newValues);
  }, [defaultValues]);

  const status = useFormatMessage('Menu.status');
  const available = useFormatMessage('Menu.available');
  const unavailable = useFormatMessage('Menu.unavailable');
  const soldOut = useFormatMessage('Menu.soldOut');
  const name = useFormatMessage('Menu.name');
  const errorRequired = useFormatMessage('Menu.errorRequired');
  const price = useFormatMessage('Menu.price');
  const required = useFormatMessage('Menu.required');
  const yes = useFormatMessage('Menu.yes');
  const deleteFood = useFormatMessage('Menu.deleteFood');
  const selection = useFormatMessage('Menu.selection');
  const prepTime = useFormatMessage('Menu.preparationTime');
  const AMPM = useFormatMessage('Menu.AMPM');
  const foodTypesMessage = useFormatMessage('Menu.foodTypes');
  const dietary = useFormatMessage('Menu.dietary');
  const submit = useFormatMessage('Restaurant.submit');
  const ingredients = useFormatMessage('Menu.ingredients');
  const categories = useFormatMessage('Menu.categories');

  return (
    <div>
      <div className={`modal ${isActive ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={closeModal}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{title}</p>
            <div className="field" style={{ marginRight: '10px' }}>
              <button className="button" onClick={handleSubmit(prepForSubmit)}>
                {submit}
              </button>
            </div>
            <button
              className="delete"
              aria-label="close"
              onClick={closeModal}
            ></button>
          </header>
          <section className="modal-card-body">
            <div className="container"></div>
            <form onSubmit={handleSubmit(prepForSubmit)}>
              <div className="field">
                <label className="label">{name}</label>
                <div className="control">
                  <input
                    className={`input ${errors.Name && 'is-danger'}`}
                    type="text"
                    placeholder={name}
                    name="Name"
                    ref={register({ required: true, maxLength: 200 })}
                  />
                  <div className={`help ${errors.Name ? 'is-danger' : ''}`}>
                    {errors.Name && errorRequired}
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label">{price}</label>
                <div className="control">
                  <input
                    className={`input ${errors.Price && 'is-danger'}`}
                    type="text"
                    placeholder={price}
                    type="number"
                    name="Price"
                    step={0.01}
                    ref={register({ required: true, valueAsNumber: true })}
                  />
                  <div className={`help ${errors.Price ? 'is-danger' : ''}`}>
                    {errors.Price && errorRequired}
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label">{prepTime}</label>
                <div className="control">
                  <div className="select">
                    <select
                      className="input"
                      name="preparation_time"
                      defaultValue={5}
                      ref={register({ required: true, valueAsNumber: true })}
                    >
                      {[5, 10, 15, 20, 25, 30].map((e) => (
                        <option key={e}>{e}</option>
                      ))}
                    </select>
                  </div>
                  <div
                    className={`help ${
                      errors.preparation_time ? 'is-danger' : ''
                    }`}
                  >
                    {errors.preparation_time && errorRequired}
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label">{status}</label>
                <div className="control">
                  <div className="select">
                    <select
                      className="input"
                      name="Status"
                      ref={register({ required: true })}
                    >
                      <option value="Available">{available}</option>
                      <option value="Item Not Available">{unavailable}</option>
                      <option value="Sold Out">{soldOut}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label">{categories}</label>
                <div className="control">
                  <Multiselect
                    ref={multiSelectRef}
                    id="selectCategories"
                    placeholder="Categories"
                    displayValue="name"
                    selectedValues={selectedCategories}
                    hidePlaceholder={true}
                    options={
                      menu &&
                      menu.Categories &&
                      menu.Categories.map((e, index) => ({
                        name: e.Name,
                        id: e.Name + index,
                      }))
                    }
                    closeIcon="cancel"
                    style={{
                      searchBox: { marginRight: '8px' },
                      chips: {
                        backgroundColor: '#CEAD29',
                        borderRadius: '20px',
                      },
                      option: { marginBottom: '0px' },
                    }}
                    closeOnSelect={false}
                  />
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <label className="label">{dietary}</label>
                    <div className="control">
                      <Controller
                        rules={{
                          required: true,
                          validate: (value) => value.length > 0 || 'Empty',
                        }}
                        control={control}
                        name="Dietary"
                        render={({ onChange, value }) => (
                          <CheckboxSelect
                            error={errors.Dietary}
                            data={dietaries}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">{AMPM}</label>
                    <div className="control">
                      <Controller
                        control={control}
                        name="foodAMPM"
                        render={({ onChange, value }) => (
                          <CheckboxSelect
                            error={errors.foodAMPM}
                            data={foodAMPM}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">{foodTypesMessage}</label>
                    <div className="control">
                      <Controller
                        rules={{
                          required: true,
                          validate: (value) => value.length > 0 || 'Empty',
                        }}
                        control={control}
                        name="foodTypes"
                        render={({ onChange, value }) => (
                          <CheckboxSelect
                            error={errors.foodTypes}
                            data={foodTypes}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label">{ingredients}</label>
                <div className="control">
                  <textarea
                    name="Ingredients"
                    className={`textarea ${errors.Ingredients && 'is-danger'}`}
                    placeholder="Chicken,Potato,beef"
                    ref={register({ required: true })}
                  />
                  <div
                    className={`help ${errors.Ingredients ? 'is-danger' : ''}`}
                  >
                    {errors.Ingredients && errorRequired}
                  </div>
                </div>
              </div>

              {fields.map((item, index) => {
                const fieldName = `Selections[${index}]`;
                return (
                  <div className="card" key={item.id}>
                    <div className="card-content">
                      <div className="media">
                        <div className="media-left">
                          <div className="media-content">
                            <div className="title is-4">
                              {selection + ' ' + ++renderCount}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="content">
                        <div className="field">
                          <label className="label">{name}</label>
                          <div className="control">
                            <Controller
                              as={
                                <input
                                  className={`input ${
                                    errors &&
                                    errors.Selections &&
                                    errors.Selections[index] &&
                                    errors.Selections[index].Name &&
                                    'is-danger'
                                  }`}
                                  placeholder="Size Choice"
                                />
                              }
                              name={`${fieldName}.Name`}
                              control={control}
                              type="text"
                              rules={{ required: true, maxLength: 200 }}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label className="label">{required}</label>
                          <div className="control">
                            <label className="checkbox">
                              <input
                                id={`${fieldName}.IsRequired`}
                                name={`${fieldName}.IsRequired`}
                                type="checkbox"
                                ref={register()}
                                className="switch is-rounded"
                                defaultChecked={
                                  defaultValues &&
                                  defaultValues.Selections &&
                                  defaultValues.Selections[index] &&
                                  defaultValues.Selections[index].IsRequired
                                }
                              />
                              <label htmlFor={`${fieldName}.IsRequired`} />

                              <span style={{ marginLeft: '6px' }}>{yes}</span>
                            </label>
                          </div>
                        </div>

                        <SelectionsForm
                          nestIndex={index}
                          removeParent={remove}
                          {...{ control, errors }}
                          selectionName={watch(`${fieldName}.Name`)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}

              <button
                type="button"
                className="button"
                onClick={() => {
                  append({ name: 'append' });
                }}
              >
                {useFormatMessage('Menu.addSelection')}
              </button>
              <div className="buttons is-right">
                {Object.keys(defaultValues || {}).length > 0 && (
                  <button
                    className="button is-danger is-pulled-right is-primary"
                    onClick={() => onFoodItemDelete(defaultValues.id)}
                  >
                    {deleteFood}
                  </button>
                )}

                <button className="button  is-pulled-right" type="submit">
                  {submit}
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  );
};

export default FoodForm;
